import { NotificationManager } from "react-notifications";

export const createNotification = (type, title, message, delay) => {
  NotificationManager.removeAll();
  switch (type) {
    case "info":
      NotificationManager.info(message, null, 3000); // issue mentioned by QC title sholdn't be there -13104
      // NotificationManager.info(message, title, 3000);
      break;
    case "success":
      NotificationManager.success(message, null, 3000); // issue mentioned by QC title sholdn't be there -13104
      // NotificationManager.success(message, title, 3000);
      break;
    case "warning":
      NotificationManager.warning(message, null, 3000); // issue mentioned by QC title sholdn't be there -13104
      // NotificationManager.warning(message, title, 3000);
      break;
    case "error":
      NotificationManager.error(message, null, delay || 3000); // issue mentioned by QC title sholdn't be there -13104
      // NotificationManager.error(message, title, delay || 3000);
      break;
    default:
  }

  return <></>;
};
