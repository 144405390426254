import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";

export const SwitchComponent = ({ data, handlePublished, id, isOnClickTrigger, column }) => {
  const [publish, setPublish] = useState();
  useEffect(() => {
    setPublish(data);
  }, [data]);
  return (
    <div>
      {isOnClickTrigger ?
        <Switch
          checked={publish}
          value={publish}
          onClick={(e) => {
            handlePublished(!publish, id, column);
            setPublish(!publish);
          }}
          color={!publish ? "default" : "secondary"}
        />
        : <Switch
          checked={publish}
          value={publish}
          // onClick={(e) => {
          //   handlePublished(!publish, id);
          //   setPublish(!publish);
          // }}
          color={!publish ? "default" : "secondary"}
        />}

    </div>
  );
};
