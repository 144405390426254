export function formatOrderDate(createdDate) {
  const _date = new Date(createdDate);
  return _date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
}

export function formatDate(dateString) {
  // Ensure dateString is 8 characters long
  if (dateString.length !== 8) {
    throw new Error("Invalid date format. Expected YYYYMMDD.");
  }

  // Extract year, month, and day
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  // Return formatted date
  return `${day}-${month}-${year}`;
}

export function commaSeparated(_param) {
  return _param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function validateEmail(email) {
  var regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}

export function checkTokenExpiry(error) {
  if (error === "Session Expired!") {
    localStorage.removeItem("user");
    window.location.reload();
  }
}

export function get_thumbnail(url, quality = "medium") {
  if (url) {
    var video_id, thumbnail, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }
    if (video_id) {
      if (typeof quality == "undefined") {
        quality = "high";
      }

      var quality_key = "maxresdefault"; // Max quality
      // eslint-disable-next-line eqeqeq

      if (quality == "low") {
        quality_key = "sddefault";
        // eslint-disable-next-line eqeqeq
      } else if (quality == "medium") {
        quality_key = "mqdefault";
        // eslint-disable-next-line eqeqeq
      } else if (quality == "high") {
        quality_key = "hqdefault";
      }
      thumbnail =
        "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
      return thumbnail;
    }
  }
  return false;
}

export function getThumbnailLink(url) {
  const match = url.match(/(?:watch\?v=)(?:[a-zA-Z0-9]{0,})/gi)[0].split("=");
  console.log("match: ", match);
  const videoId = match[match.length - 1];
  const thumbnailLink = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  return thumbnailLink;
}

export function matchYoutubeUrl(url) {
  var p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  var matches = url?.match(p);
  if (matches) return true;
  return false;
}

export const wordsCapping = (_STRING, _LENGTH = 100) => {
  var TEXT = document.createElement("textarea");
  TEXT.innerHTML = _STRING;
  return `${TEXT.value.slice(0, _LENGTH)}${
    TEXT.value.length > _LENGTH ? `...` : ``
  }`;
};

export const FilterDate = (data) => {
  return data.split("T")[0];
};

export const SeoFriendlySlug = (__slug) => {
  let slug;
  slug = __slug.toLowerCase();
  // slug =  allReplace(slug, {' ':'-', '.':'-', '--':'-'});
  return allReplace(slug, { " ": "-", "/": "", "--": "-" });
};

export function allReplace(string, object) {
  for (const index in object) {
    string = string.replace(new RegExp(index, "g"), object[index]);
  }
  return string;
}

export function formatNumber(value) {
  if (value >= 1e6) {
    return (value / 1e6).toFixed(1) + "M"; // Convert to millions
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(1) + "K"; // Convert to thousands
  } else {
    return value?.toString(); // Return as-is if below 1,000
  }
}

// Helper function to format the hour
export function formatHour(hour) {
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return `${formattedHour}:00 ${period}`;
}

export function formatTime(seconds) {
  if (seconds < 60) {
    return `${Math.floor(seconds)}s`;
  } else {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}m ${remainingSeconds?.toFixed(0)}s`;
  }
}

export function convertToPercentage(fraction) {
  // Multiply by 100 and fix to one decimal place without rounding
  const percentage = (fraction * 1000) / 10;

  // Check if the percentage is a whole number
  if (percentage % 1 === 0) {
    return `${percentage}%`; // Return without decimal if it's an integer
  }

  return `${percentage.toFixed(2)}%`; // Return with one decimal if not
}
