import axios from "axios";
import {port} from '../constants/api'
import { checkTokenExpiry } from "../utils/helpers/functions";


const user = JSON.parse(localStorage.getItem("user"));

export const getNewsBulletin = async () => {
  return await axios.get(`${port}/newsBulletinList`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
 
export const getNewsBulletinSubCategory = async () => {
  return await axios.get(`${port}/subCategoryList`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
 
export const getNewsBulletinById = async (id) => {
  try {
    return await axios.get(`${port}/newsBulletinListById/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `${user.token}`,
      },
    });
  } catch (error) { checkTokenExpiry(error.response.data.msg) 
    return error.response.data.msg;
  }
};
export const createNewsBulletin = async (data) => {
  try {
    return await axios.post(`${port}/createNewsBulletin`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `${user.token}`,
      },
    });
  } catch (error) { checkTokenExpiry(error.response.data.msg) 
    return error.response.data.msg;
  }
};
export const updateNewsBulletin = async (data) => {
  try {
    return await axios.post(`${port}/updateNewsBulletin`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `${user.token}`,
      },
    });
  } catch (error) { checkTokenExpiry(error.response.data.msg) 
    return error.response.data.msg;
  }
};
export const deleteNewsBulletin = async (id) => {
  try {
    return await axios.delete(`${port}/deleteNewsBulletin`, {
      data: {
        id: id,
      },
      headers: {
        authorization: `${user.token}`,
      },
    });
  } catch (error) { checkTokenExpiry(error.response.data.msg) 
    return error.response.data.msg;
  }
};
