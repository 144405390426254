import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";
import { getRequest } from "src/app/httpClient/axiosClient";
import { GENERAL_SETTINGS } from "src/app/config/endpoints";

const dimensionOptions = [
  { id: 1, name: "City", value: "city" },
  { id: 2, name: "Country", value: "country" },
];

const typeOptions = [
  { id: 1, name: "Active Users", value: "activeUsers" },
  { id: 6, name: "New Users", value: "newUsers" },
];

// function aggregateDataByHourAndCountry(data = []) {
//   const aggregatedByHour = {};
//   const aggregatedByCountry = {};

//   data.forEach((item) => {
//     // Aggregate by hour
//     if (!aggregatedByHour[item.hour]) {
//       aggregatedByHour[item.hour] = {
//         hour: formatHour(item.hour),
//         users: 0,
//       };
//     }
//     aggregatedByHour[item.hour].users += item.option_1;

//     // Aggregate by country (name)
//     if (!aggregatedByCountry[item.name]) {
//       aggregatedByCountry[item.name] = {
//         name: item.name,
//         option_1: 0,
//       };
//     }
//     aggregatedByCountry[item.name].option_1 += item.option_1;
//   });

//   return {
//     hour: Object.values(aggregatedByHour),
//     country: Object.values(aggregatedByCountry),
//   };
// }

const AnalyticsChartB = () => {
  const [selectedDimension, setSelectedDimention] = useState({
    name: "Country",
    value: "country",
  });
  const [selectedType, setSelectedType] = useState({
    name: "Active Users",
    value: "activeUsers",
  });
  const [analyticsData, setAnalyticsData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleDimensionChange = (event) => {
    const selectedData = dimensionOptions?.find(
      (dm) => dm.value === event.target.value
    );
    setSelectedDimention(selectedData);
  };

  // const handleTypeChange = (event) => {
  //   const selectedData = typeOptions.find(
  //     (tp) => tp.value === event.target.value
  //   );
  //   setSelectedType(selectedData);
  // };

  // function getOptionCounts(data = []) {
  //   // Initialize counts for each option
  //   const optionCounts = {
  //     option_1: 0,
  //     option_2: 0,
  //     option_3: 0,
  //     option_4: 0,
  //   };

  //   // Iterate over each item in the array and sum the values for each option
  //   data.forEach((item) => {
  //     optionCounts.option_1 += item.option_1 || 0;
  //     optionCounts.option_2 += item.option_2 || 0;
  //     optionCounts.option_3 += item.option_3 || 0;
  //     optionCounts.option_4 += item.option_4 || 0;
  //   });

  //   return optionCounts;
  // }

  const getAnalytics = async () => {
    const url = `${GENERAL_SETTINGS.GET_ANALYTICS}?metrics=${selectedType?.value}&dimensions=${selectedDimension?.value}`;
    setLoading(true); // Set loading to true before API call
    try {
      const response = await getRequest(url);
      if (
        response.data.data &&
        response.data.total &&
        response.data.data_minutes
      ) {
        setAnalyticsData(
          response.data.data_minutes.sort(
            (a, b) => parseInt(a.minutesAgo) - parseInt(b.minutesAgo)
          )
        );
        setCountryData(response.data.data);
        setTotalCount(response.data.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  };

  useEffect(() => {
    getAnalytics();
  }, [selectedDimension, selectedType]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <div>{`Minute: ${payload[0]?.payload?.minutesAgo}`}</div>
          <div>{`Active Users: ${payload[0]?.payload?.activeUsers}`}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        padding: "15px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        width: "100%",
        height: "100%",
      }}
    >
      {/* Title and total active users */}
      <Typography variant="subtitle1" gutterBottom>
        Active users in last 30 minutes
      </Typography>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", marginBottom: "10px" }}
      >
        {totalCount}
      </Typography>
      {/* Active Users Per Minute Chart */}
      <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
        Active users per minute
      </Typography>
      {loading ? ( // Show loader if loading is true
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 250,
          }}
        >
          <CircularProgress /> {/* Spinner */}
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={analyticsData} barSize={10}>
            <XAxis
              dataKey="minutesAgo"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis
              // dataKey="activeUsers"
              allowDecimals={false}
              // tickCount={5} // Adjust tick density if needed
              // domain={[1, "auto"]}
            />
            <Tooltip content={<CustomTooltip />} />
            {/* <Legend /> */}
            <CartesianGrid strokeDasharray="3 3" />
            <Bar
              dataKey="activeUsers"
              fill="#8884d8"
              background={{ fill: "#eee" }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}

      {/* Dropdowns */}
      <Grid container direction="row" spacing={2} marginBottom={"10px"}>
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <Select
              value={selectedDimension?.value}
              onChange={handleDimensionChange}
            >
              {dimensionOptions.map((dimension) => (
                <MenuItem key={dimension.id} value={dimension.value}>
                  {dimension.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle2"
        sx={{ marginTop: "15px", marginBottom: "5px" }}
      >
        {selectedDimension?.name?.toUpperCase()}
      </Typography>
      <List
        dense
        sx={{
          maxHeight: 100,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none", // For Internet Explorer
          scrollbarWidth: "none", // For Firefox
        }}
      >
        {countryData?.map((item, index) => {
          const label = item?.country ? item?.country : item?.city;
          if (label && label !== "(other)") {
            return (
              <ListItem key={index} sx={{ padding: 0 }}>
                <ListItemText
                  primary={item?.country ? item?.country : item?.city}
                />
                <Typography variant="body2">{item?.activeUsers}</Typography>
              </ListItem>
            );
          }
        })}
      </List>
    </Box>
  );
};

export default AnalyticsChartB;
