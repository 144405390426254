import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import {
  Button,
  Grid,
  Typography,
  FormLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useNavigate } from "react-router-dom";
import "./style.css";
import Scrollbar from "../components/scrollbar/Scrollbar";
import { image } from "src/constants/api";
import { useSelector } from "react-redux";

const ProfileAdmin = () => {
  const navigate = useNavigate();
  const { id } = JSON.parse(localStorage.getItem("user"));
  const [fields, setFields] = useState({
    name: "",
    email: "",
    roleId: "",
    profile_image: "",
  });
  const [loading, setloading] = useState(false);

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setloading(true);
    getUser();
  }, [user]);

  const getUser = () => {
    if (user) {
      setFields({ ...user });
      setloading(false);
    }
  };

  return (
    <>
      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "100%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",

            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Scrollbar>
        {/* <ToastContainer/> */}
        <Card style={{ padding: 20, margin: 30 }} sx={{ minWidth: 500 }}>
          <Grid container spacing={3} alignItems="start">
            <Grid item xs={12} mt={2} className="d-flex justify-content-end ">
              <Button
                onClick={() => navigate(`/dashboard/editProfile/${id}`)}
                style={{ textAlign: "center" }}
                size="medium"
                type="button"
                variant="contained"
              >
                Update Profile
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" component="h1" mb={2}>
                Admin Profile
              </Typography>
            </Grid>

            <Grid item xs={12} className="img-upload">
            <FormLabel>Profile Picture</FormLabel>
              <div className="profileimage_edit">
                {
                  fields?.profile_image 
                  ? <img
                    style={{ marginTop:'5px', height: "180px", width: "180px", borderRadius: '50%', objectFit: 'cover',  }}
                      onError={(e) =>
                      (e.target.src =
                        "./assets/images/user-default.png")
                      }
                      src={`${image}/${fields.profile_image}`}
                    alt="profile"
                />
                  :<Avatar sx={{
                    mt:1,
                    // bgcolor:'#181c208c',
                    width:'120px',
                    height:'120px',
                  }}
                  >
                  <AccountCircleIcon sx={{fontSize:'8.5rem !important'}} />
                </Avatar>
                }
                {/* <img
                  style={{ marginTop:'5px', height: "180px", width: "180px", borderRadius: '50%', objectFit: 'cover',  }}
                  onError={(e) =>
                  (e.target.src =
                    "./assets/images/user-default.png")
                  }
                  src={
                    fields?.profile_image
                      ? `${image}/${fields.profile_image}`
                      : "./assets/images/user-default.png"
                  }
                  alt="profile"
                /> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Name</FormLabel>
              <p>
                {user?.name || fields.name}{" "}
                {user?.last_name || fields.last_name}
              </p>

              <FormLabel>Email</FormLabel>
              <p className="d-flex">{user?.email || fields.email}</p>
            </Grid>

            <Grid item xs={6} mt={2}>
              <Button
                onClick={() => {
                  navigate(`/dashboard/changePassword/${id}`);
                }}
                size="medium"
                type="button"
                variant="contained"
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Scrollbar>
    </>
  );
};
export default ProfileAdmin;
