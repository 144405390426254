import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Button, Grid, Container, Typography, CircularProgress, OutlinedInput, MenuItem, FormControl, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { Icon } from "@iconify/react";

import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';

import {
    getMainCategory
} from "src/api/e-commerce/category";

function AddCategory() {
    //STATES
    const [itemData, setitemData] = useState("");
    const [loading, setloading] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const [mainCategory, setMainCategory] = useState();
    const [mainCategoryDataList, setMainCategoryDataList] = useState([]);

    const [mediaErrors, setmediaErrors] = useState({});

    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const pathName = window.location.pathname.split("/")[2];
    const pathName = /[^/]*$/.exec(window.location.pathname)[0];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        parent_id: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),

        //Commented by Narendra
        // short_content: Yup.string().required("This field is required."),
        // meta_title: Yup.string().required("This field is required."),
        // meta_key: Yup.string().required("This field is required."),
        // meta_description: Yup.string().required("This field is required."),

        categoryImage: Yup.mixed()
        //Commented by Narendra
            // .test("required", "This field is required.", (value) => value.length > 0)
            // .test("fileSize", "Image size can not be more than 5 MB", (value) => {
            //     return value.length > 0 && value[0].size <= 5242880;
            // })
            .test("fileSize", "Image size cannot be more than 5 MB", (value) => {
                if (value?.length > 0) {
                    return value[0].size <= 5242880; // 5MB in bytes
                }
                return true; // No file selected, skip this check
            })
            .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (value) => {
                if (value?.length > 0) {
                    return (
                        value.length &&
                        ["image/jpeg", "image/png", "image/jpg"].includes(value[0].type)
                    );
                }
                return true  // No file selected, skip this check
            }),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState, trigger } =
        useForm(formOptions);
    const { errors } = formState;
    const [selectedImage, setSelectedImage] = useState(null);
    const { onChange, ...params } = register("categoryImage");


    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {

        dispatch(setRequestInProgress());

        const formData = new FormData();

        if (data.categoryImage.length > 0) {
            formData.append("file", data.categoryImage[0]);
        }
        formData.append("name", data.name);
        formData.append("short_content", data.short_content);
        formData.append("parent_id", data.parent_id == undefined ? 0 : data.parent_id);
        formData.append("status", data.status ? 1 : 0);
        //Commented by Narendra
        // formData.append("meta_title", data.meta_title);
        // formData.append("meta_key", data.meta_key);
        // formData.append("meta_description", data.meta_description);
        // formData.append("featured", data.featured ? 1 : 0);
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.ADD_CATEGORY, formData);

            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Category created successfully.");
                handleRouteTo("/e-commerce/category");
            } else {
                dispatch(setRequestFailure("Getting error while creating Category."));
                createNotification("error", "", "Getting error while creating Category.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Category."));
            createNotification("error", "", "Getting error while creating Category.");
        }
    };

    const handleFile = (e) => {
        const image = e.target.files[0];
        if (!image)
            return false;

        if (!image.name.match(/\.(jpg|jpeg|png)$/)) {
            setitemData("");
            return false;
        }

        setitemData(URL.createObjectURL(e.target.files[0]));
        return e.target.files[0];
    };

    const handleChangeMainCategory = (e) => {
        setMainCategory(e.target.value);
    };

    const getCategory = async () => {
        try {
            const data = await getMainCategory();
            if (data.data.data !== null) {
                setMainCategoryDataList(data.data.data);
            }
        } catch (error) {
            checkTokenExpiry(error);
        }
    };

    useEffect(() => {
        getCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> {path} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container maxWidth="xl" ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        {path}
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/category`}
                    >
                        Category
                    </Link>
                    <Typography color="text.primary">Create Category</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Category`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            <Grid item md={12}>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Parent Category
                                            </InputLabel>
                                            <Select
                                                id="parent_id"
                                                name="parent_id"
                                                error={errors?.parent_id}
                                                value={mainCategory}
                                                {...register("parent_id", {
                                                    onChange: handleChangeMainCategory,
                                                })}
                                                input={<OutlinedInput label="Parent Category" />}
                                                label="Parent Category"
                                            >
                                                {mainCategoryDataList.map((name) => (
                                                    <MenuItem key={name.name} value={name.id}>
                                                        {name.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Stack>
                                    </FormControl>
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="news-title"
                                        label="Name*"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("short_content")}
                                        disabled={loader}
                                        error={errors.short_content}
                                        helperText={
                                            errors.short_content ? errors.short_content.message : false
                                        }
                                        id="short-content"
                                        label="Description"
                                        name="short_content"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                {/* Commented by Narendra */}
                                {/* <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_title", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_title}
                                        helperText={
                                            errors.meta_title ? errors.meta_title.message : false
                                        }
                                        id="meta-title"
                                        label="Meta Title*"
                                        name="meta_title"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_key", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_key}
                                        helperText={
                                            errors.meta_key ? errors.meta_key.message : false
                                        }
                                        id="meta-key"
                                        label="Meta Key*"
                                        name="meta_key"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("meta_description", { required: true })}
                                        disabled={loader}
                                        error={errors.meta_description}
                                        helperText={
                                            errors.meta_description ? errors.meta_description.message : false
                                        }
                                        id="meta-description"
                                        label="Meta Description*"
                                        name="meta_description"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid> */}

                                <Grid item mb={3} xs={12} md={12}>
                                    <div
                                        className={
                                            errors.categoryImage || mediaErrors.image
                                                ? "errorCkEditor"
                                                : "borderInput"
                                        }
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            padding: "20px",
                                            postition: "relative",
                                        }}
                                    >
                                        {itemData ? (
                                            <>
                                                <img
                                                    src={itemData}
                                                    style={{
                                                        height: "190px",
                                                        marginBottom: "10px",
                                                        width: "190px",
                                                    }}
                                                    alt="something"
                                                    loading="lazy"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Icon
                                                    fontSize="60px"
                                                    color={errors.categoryImage ? "#ff5c56" : "#8d9299"}
                                                    icon="material-symbols:image-rounded"
                                                />
                                                <span
                                                    style={{
                                                        color: errors.categoryImage ? "#ff5c56" : "#8d9299",
                                                        fontSize: "14px",
                                                        marginBottom: "20px",
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).
                                                </span>
                                            </>
                                        )}

                                        <Button
                                            disabled={loader}
                                            variant="contained" component="label">
                                            Upload image
                                            <input
                                                {...params}
                                                type="file"
                                                id="uploadFileInput"
                                                accept="image/*"
                                                name="categoryImage"
                                                onChange={(event) => {
                                                    setSelectedImage(event.target.files[0]);
                                                    onChange(event);
                                                    trigger('categoryImage');
                                                    handleFile(event)
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Button>
                                    </div>
                                    {errors.categoryImage &&
                                        <p className="errorForm">{errors.categoryImage.message}</p>
                                    }
                                </Grid>
                                
                                {/* Commented by Narendra */}
                                {/* <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="featured"
                                                        disabled={loader}
                                                        {...register("featured")}
                                                        color="success"
                                                    />
                                                }
                                                label="Featured"
                                            />

                                        </Stack>
                                    </FormGroup>
                                </Grid> */}
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="status"
                                                        disabled={loader}
                                                        {...register("status")}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />

                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default AddCategory;