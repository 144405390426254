import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  navOpen: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setNavOpen(state, action) {
      state.navOpen = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setNavOpen, setUser } = userSlice.actions;

export default userSlice.reducer;
