import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { createNotification } from "../../common/createNotification";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Iconify from "../../components/iconify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getNewsBulletinById,
  updateNewsBulletin,
} from "src/api/newsBulletinApi";

function EditBulletin() {
  //CONSTANTS-------------------------------------
  const formTop = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    bulletin: Yup.string().trim().required("This field is required.")
    .max(50, `Bulletin can't be longer than 50 characters.`)
    .matches(/^[a-zA-Z\s]*$/, 'Bulletin must contain only letters.')
  });
  
  

  //STATES----------------------------------------
  const [bulletinInput, setbulletinInput] = useState({
    bulletin: "",
    status: "",
  });
  const [loading, setloading] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const formOptions = { 
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues:{
      bulletin:bulletinInput.bulletin
    }
  };
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  //USE EFFECTS----------------------------------
  useEffect(() => {
    getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //FUNCTIONS----------------------------------
  const getData = async () => {
    setloading(true);
    try {
      const data = await getNewsBulletinById(id);
      setbulletinInput({
        bulletin: data?.data.name,
        status: data?.data.status,
      });
      setValue('bulletin', data.data.name)
      setloading(false);
    } catch(error) { checkTokenExpiry(error.response.data.msg) 
      setloading(false);
      createNotification("error", "Error", "Error loading news-bulletin");
    }
  };
  const onSubmitForm = async (data) => {
    setloading(true);
    const handleRouteTo = (path) => {
      navigate(path);
    };
    const item = {
      id: id,
      name: data.bulletin,
      // status: +bulletinInput.status,
      update_by_id: user.id,
    };
    try {
      await updateNewsBulletin(item);
      createNotification(
        "success",
        "Created",
        "News Bulletin updated Successfully"
      );
      handleRouteTo("/dashboard/news-bulletin");
    } catch (error) { checkTokenExpiry(error.response.data.msg) 
      setloading(false);
      createNotification(
        "error",
        "Error",
        `error while updating News bulletin`
      );
    }
  };

  return (
    <>
      <Helmet>
        <title> News </title>
      </Helmet>
      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
           
            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Container  maxWidth="xl" ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard/news-bulletin/"
          >
            Bulletin
          </Link>
          <Typography color="text.primary">Edit News bulletin</Typography>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Edit News bulletin" />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <Grid item xs={12} md={8} sx={{ pt: 5, pl: 10, pr: 10, pb: 20 }}>
              <Stack pb={4}>
                <div>
                  <TextField
                  InputLabelProps={{ shrink: true }}
                  { ... register("bulletin") }
                    name="bulletin"
                    // value={bulletinInput.bulletin}
                    // {...register("bulletin", {
                    //   required: true,
                    //   onChange: (e) =>
                    //     setbulletinInput({
                    //       ...bulletinInput,
                    //       bulletin: e.target.value,
                    //     }),
                    //   })}
                    error={errors.bulletin}
                    helperText={
                      errors.bulletin ? errors.bulletin.message : false
                    }
                    id="news-title"
                    label="News Bulletin"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </Stack>
              {/* <Stack pb={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!+bulletinInput.status}
                      name="status"
                      {...register("status", {
                        onChange: (e) =>
                          setbulletinInput({
                            ...bulletinInput,
                            status: e.target.checked,
                          }),
                      })}
                      color="secondary"
                    />
                  }
                  label="Status"
                />
              </Stack> */}
              <Stack pb={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Iconify icon="iconoir:submit-document" />}
                >
                  SUBMIT
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default EditBulletin;
