import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, CircularProgress, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Chip, Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from '@mui/material';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import {
    getAttributes
} from "src/api/e-commerce/product";
import AttributeValue from "./attributevalue";

function AddProductParent() {
    //STATES
    const [loading, setloading] = useState(false);
    const { loader } = useSelector(state => state.serviceRequest);
    const [attributeList, setAttributeList] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState([]);
    const [selectedHtmlData, setSelectedHtmlData] = useState([]);
    const [selecteValueData, setSelecteValueData] = useState({});
    const [variationSelected, setVariationSelected] = useState([]);
    const [optionChecked, setOptionChecked] = useState([]);
    const [open, setOpen] = useState(false);
    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
    const validationSchema = Yup.object().shape({
        sku: Yup.string().required("This field is required."),
        variation: Yup.string().required("This field is required."),
    });




    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    // const data = watch();

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const handleSelectChange = (event) => {
        handleChange(event); // Call the parent change handler
        setOpen(false); // Close the dropdown
    };

    const onSubmitForm = async (data) => {

        // Check if the variation is selected and no options are checked
        if (data.variation === 'variation' && Object.values(selecteValueData).every(arr => arr.length === 0)) {
            createNotification("error", "", "At least one option must be selected for variations.");
            return;
        }
        dispatch(setRequestInProgress());
        const payloadData = {
            sku: data.sku,
            variation: data.variation,
            variants: selecteValueData
        }


        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.ADD_PRODUCT_PARENT, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Product SKU created successfully.");
                handleRouteTo("/e-commerce/product/add_product/" + data.product.id);
            } else {
                dispatch(setRequestFailure("Getting error while creating Attribute."));
                createNotification("error", "", "Getting error while creating Attribute.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Attribute."));
            createNotification("error", "", "Getting error while creating Attribute.");
        }
    };

    const getAttribute = async () => {
        try {
            const data = await getAttributes();
            if (data.data.data !== null) {
                let dataAccess = data.data.data;
                let NewData = [];
                dataAccess.forEach(function (data) {
                    NewData[data.id] = data.name;
                });
                //console.log(NewData);
                setAttributeList(NewData);
            }
        } catch (error) {
            checkTokenExpiry(error);
        }
    };

    const getAttributeValues = async (aAttributeId) => {
        setSelectedHtmlData([]);
        try {
            let payload = { ids: aAttributeId };
            const data = await postRequest(GENERAL_SETTINGS.GET_ATTRIBUTE_WITH_VALUES, payload);

            setSelectedHtmlData(data.data.data);
            //console.log(data.data.data);

        } catch (error) {
            checkTokenExpiry(error);
        }
    }

    const handleChange = (e) => {
        //console.log(e.target.value)
        let difference = selectedAttribute.filter(number => !e.target.value.includes(number));
        if (difference.length > 0) {
            let indexToRemove = difference[0].toString();
            const keyexists = Object.keys(selecteValueData).includes(indexToRemove);
            if (keyexists) {
                delete selecteValueData[indexToRemove];
                setSelecteValueData(selecteValueData);
            }
        }
        setSelectedAttribute(e.target.value);
        getAttributeValues(e.target.value);
    }

    const handleValueChange = (e) => {
        const { value, checked } = e.target;  // Destructure the target value and checked state
        const arraySplit = value.split("-"); // Split the value into [attributeId, valueId]
        const attributeId = arraySplit[0];
        const valueId = arraySplit[1];

        // Clone the current state to avoid mutation
        const updatedOptionChecked = [...optionChecked];
        const updatedSelectedData = { ...selecteValueData };

        if (checked) {
            // If the checkbox is checked, add the value to optionChecked and the corresponding data in selecteValueData
            updatedOptionChecked.push(valueId);

            // Ensure the attribute is in the selecteValueData object
            if (!updatedSelectedData[attributeId]) {
                updatedSelectedData[attributeId] = [];
            }

            // Add the valueId to the correct attribute
            if (!updatedSelectedData[attributeId].includes(valueId)) {
                updatedSelectedData[attributeId].push(valueId);
            }
        } else {
            // If the checkbox is unchecked, remove the value from optionChecked and selecteValueData
            const optionCheckedIndex = updatedOptionChecked.indexOf(valueId);
            if (optionCheckedIndex > -1) {
                updatedOptionChecked.splice(optionCheckedIndex, 1);
            }

            // Remove the valueId from the corresponding attribute
            const attributeIndex = updatedSelectedData[attributeId]?.indexOf(valueId);
            if (attributeIndex > -1) {
                updatedSelectedData[attributeId].splice(attributeIndex, 1);
            }

            // If the attribute has no more values, delete it from selecteValueData
            if (updatedSelectedData[attributeId]?.length === 0) {
                delete updatedSelectedData[attributeId];
            }
        }

        // Update the state
        setOptionChecked(updatedOptionChecked);
        setSelecteValueData(updatedSelectedData);
    };



    // const handleCheckboxChange = (event) => {
    //     const { value, checked } = event.target;

    //     if (checked) {
    //         // Add to selected attributes
    //         setSelectedAttribute((prevSelected) => [...prevSelected, value]);
    //     } else {
    //         // Remove from selected attributes
    //         setSelectedAttribute((prevSelected) =>
    //             prevSelected.filter((item) => item !== value)
    //         );
    //     }
    // };


    const handleChangeVariation = (e) => {
        setVariationSelected(e.target.value);
        if (e.target.value === 'simple') {
            setSelecteValueData({});
            setSelectedAttribute([]);
            setSelectedHtmlData([]);
        }
    };

    useEffect(() => {
        getAttribute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <>
            <Helmet>
                <title> Add Product </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container maxWidth="xl" ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Add Product
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/product/listing`}
                    >
                        Product
                    </Link>
                    <Typography color="text.primary">Create Product</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Product`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)} // Trigger validation and submit on form submit
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item md={12}>
                                {/* SKU Input */}
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("sku", { required: "Product SKU Code is required" })}
                                        error={!!errors.sku} // Check if there is an error
                                        helperText={errors.sku ? errors.sku.message : ""}
                                        required
                                        disabled={loader}
                                        id="news-title"
                                        label="Product SKU Code*"
                                        name="sku"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                {/* Product Type (Variation or Simple) */}
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth>
                                        <Stack>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Select Product Type
                                            </InputLabel>
                                            <Select
                                                id="variation"
                                                name="variation"
                                                value={variationSelected}
                                                {...register("variation", {
                                                    required: "Product type is required", // Ensure product type is selected
                                                    onChange: handleChangeVariation,
                                                })}
                                                error={!!errors.variation} // Check if there is an error
                                                input={<OutlinedInput label="Select Product Type" />}
                                                label="Select Product Type"
                                            >
                                                <MenuItem key="simple" value="simple">
                                                    Simple
                                                </MenuItem>
                                                <MenuItem key="variation" value="variation">
                                                    Variation
                                                </MenuItem>
                                            </Select>
                                        </Stack>
                                    </FormControl>
                                    {/* Display error message for variation if there is an error */}
                                    {errors.variation && <FormHelperText error>{errors.variation.message}</FormHelperText>}
                                </Grid>

                                {/* Attributes for Variations (if variation is selected) */}
                                {variationSelected === 'variation' && (
                                    <>
                                        <Grid item mb={3} xs={12} md={12}>
                                            <FormControl fullWidth>
                                                <Stack>
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Select Product Attributes
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        id="parent_id"
                                                        name="parent_id"

                                                        onClose={() => setOpen(false)} // Ensure dropdown closes after selection
                                                        onOpen={() => setOpen(true)}  // Open the dropdown when clicked
                                                        open={open}
                                                        value={selectedAttribute}
                                                        onChange={handleSelectChange}
                                                        error={!!errors?.parent_id} // Check if there is an error
                                                        input={<OutlinedInput label="Select Product Attributes" />}
                                                        label="Select Product Attributes"
                                                        required={variationSelected === 'variation'}
                                                    >
                                                        {attributeList.map((value, key) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Stack>
                                            </FormControl>
                                            {/* Display error message for parent_id if there is an error */}
                                            {errors?.parent_id && (
                                                <FormHelperText error>{errors?.parent_id.message}</FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item mb={3} xs={12} md={12}>
                                            <AttributeValue
                                                loading={loader}
                                                attributeList={selectedHtmlData}
                                                checkedArray={optionChecked}
                                                handleValueChange={handleValueChange}
                                            />
                                            {/* {data.variation === 'variation' && Object.values(selecteValueData).every(arr => arr.length === 0) && (
                                                <FormHelperText error>
                                                    At least one option must be selected for variations.
                                                </FormHelperText>
                                            )} */}
                                        </Grid>
                                    </>
                                )}

                                {/* Submit Button */}
                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                </Card>
            </Container>
        </>
    );
}

export default AddProductParent;