import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS} from 'src/app/config/endpoints';

function AddAttribute() {
    //STATES
    const [loading, setloading] = useState(false);
    const { loader } = useSelector(state => state.serviceRequest);
    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const pathName = window.location.pathname.split("/")[2];
    const pathName = /[^/]*$/.exec(window.location.pathname)[0];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        dispatch(setRequestInProgress());
        const payloadData = {
            name: data.name,
            status: data.status ? 1 : 0,
        }
        /*for (var key of formData.entries()) {
            //for printing form appended entries
            console.log(key[0] + ', ' + key[1]);
        }*/
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.ADD_ATTRIBUTE, payloadData);

            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Attribute created successfully.");
                handleRouteTo("/e-commerce/attribute");
            } else {
                dispatch(setRequestFailure("Getting error while creating Attribute."));
                createNotification("error", "", "Getting error while creating Attribute.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Attribute."));
            createNotification("error", "", "Getting error while creating Attribute.");
        }
    };

    useEffect( () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title> {path} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container maxWidth="xl" ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Attribute
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/attribute`}
                    >
                        Attribute
                    </Link>
                    <Typography color="text.primary">Create Attribute</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Attribute`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            
                            <Grid item md={12}>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="news-title"
                                        label="Name*"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="status"
                                                        disabled={loader}
                                                        {...register("status")}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />

                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default AddAttribute;