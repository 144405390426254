import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar } from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import CollapsedNavSection from "src/components/nav-section/CollapsedNavSection";
//
import navConfig from "./config";
import { image } from "src/constants/api";
import { useDispatch, useSelector } from "react-redux";
import { setNAV_WIDTH } from "src/redux/Slices/NewsSlice";

// ----------------------------------------------------------------------

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, setOpen, NAV_WIDTH }) {
  const [open, setopen] = useState(true);

  const userDetials = useSelector((state) => state.user.user);

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleRouteTo = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!isDesktop) {
      setopen(true);
      dispatch(setNAV_WIDTH(280));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          backgroundColor: "black",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <div
          onClick={() => handleRouteTo("/dashboard")}
          style={{ cursor: "pointer" }}
        >
          <Logo open={open} />
        </div>
        {isDesktop && (
          <div
            style={{
              position: "absolute",
              right: open ? -1 : -2,
              top: open ? 40 : 35,
              color: "white",
              fontSize: 1,
              padding: 0,
              cursor: "pointer",
            }}
            onClick={() => {
              setopen(!open);
              if (open) {
                dispatch(setNAV_WIDTH(80));
              } else {
                dispatch(setNAV_WIDTH(280));
              }
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </div>
        )}
      </Box>
      {open ? (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <div
            onClick={() => handleRouteTo("/dashboard/profile")}
            style={{ cursor: "pointer" }}
          >
            <StyledAccount>
              <Avatar
                src={
                  userDetials.profile_image &&
                  `${image}/${userDetials.profile_image}`
                }
                sx={{ background: "#c92129" }}
              />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "white" }}>
                  {account.displayName}
                </Typography>
                <Typography variant="body2" sx={{ color: "white" }}>
                  {account.role}
                </Typography>
              </Box>
            </StyledAccount>
          </div>
        </Box>
      ) : (
        <Box>
          <Link>
            <Avatar
              className="collapsed-logo"
              src={
                userDetials.profile_image &&
                `${image}/${userDetials.profile_image}`
              }
              sx={{ background: "#c92129", m: "0 auto", width: 30, height: 30 }}
            />

            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "white" }}
              ></Typography>
            </Box>
          </Link>
        </Box>
      )}

      {open ? (
        <NavSection data={navConfig} />
      ) : (
        <CollapsedNavSection data={navConfig} />
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      cl={true.toString()}
      sx={{
        background: "#000",
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
        transition: "width 0.25s",
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "#000",
              borderRightStyle: "dashed",
              transition: "width 0.25s",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              backgroundColor: "#000",
              transition: "width 0.2s",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
