import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import { useEffect } from "react";

// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, CircularProgress, OutlinedInput, MenuItem, FormControl, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";

import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { getRequest, postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { getMainCategory } from "src/api/e-commerce/category";

function AddDiscount() {
    //STATES
    const [productAndCategoryData, setProductAndCategoryData] = useState({ eligible_categories: [], eligible_products: [] });
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([])
    const [loading, setloading] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const pathName = /[^/]*$/.exec(window.location.pathname)[0];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    // Validation schema for each field
    const validationSchema = Yup.object().shape({
        code: Yup.string().required("Code is required."),
        description: Yup.string().required("Description is required."),
        discount_type: Yup.string().required("Discount Type is required."),
        discount_value: Yup.string().required("Discount Value is required."),
        limit_usage: Yup.string().required("Total Limit Usage is required."),
        user_limit_usage: Yup.string().required("User Limit Usage is required."),
        eligible_products: Yup.array().typeError("Select at least one eligible product.").min(1, "Select at least one eligible product."),
        eligible_categories: Yup.array().typeError("Select at least one eligible category.").min(1, "Select at least one eligible category."),
        expiration_date: Yup.date()
            .nullable()
            .transform((value, originalValue) => (originalValue === "" ? null : value))
            .notRequired()
            .min(new Date(new Date().setHours(0, 0, 0, 0)), "Expiration Date cannot be in the past."),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState, setValue } =
        useForm(formOptions);
    const { errors } = formState;


    const handleRouteTo = (path) => {
        navigate(path);
    };

    const handleMultiDrop = (e) => {
        setProductAndCategoryData((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }

    const onSubmitForm = async (data) => {
        dispatch(setRequestInProgress());
        const finalPayload =
        {
            limit_usage: data.limit_usage,
            user_limit_usage: data.user_limit_usage,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            description: data.description,
            code: data.code,
            expiration_date: data.expiration_date,
            status: data.status,
            product_ids: data.eligible_products,
            category_ids: data.eligible_categories
        }

        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.POST_DISCOUNT, finalPayload);

            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Discount created successfully.");
                handleRouteTo("/e-commerce/Discount");
            } else {
                dispatch(setRequestFailure("Getting error while creating Discount."));
                createNotification("error", "", "Getting error while creating Discount.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Discount."));
            createNotification("error", "", error.response.data.message ? error.response.data.message : "Getting error while creating Discount.");
        }
    };

    const getProducts = async () => {
        try {
            const { data } = await getRequest(GENERAL_SETTINGS.GET_PRODUCT_LIST);
            setProducts(data.data);
        } catch (error) {
            checkTokenExpiry(error);
        }
    }

    const getCategories = async () => {
        try {
            const data = await getMainCategory(null);
            setCategories(data.data.data)
        } catch (error) {
            checkTokenExpiry(error);
        }
    }

    useEffect(() => {
        getProducts();
        getCategories()
    }, []);

    return (
        <>
            <Helmet>
                <title> {path} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop} maxWidth="xl">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        {path}
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/discount`}
                    >
                        Discount
                    </Link>
                    <Typography color="text.primary">Create Discount</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Create Discount`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            <Grid item md={12}>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("code", { required: true })}
                                        error={errors.code}
                                        helperText={
                                            errors.code ? errors.code.message : false
                                        }
                                        disabled={loader}
                                        id="discount_code"
                                        label="Code*"
                                        name="code"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("description", { required: true })}
                                        disabled={loader}
                                        error={errors.description}
                                        helperText={
                                            errors.description ? errors.description.message : false
                                        }
                                        id="description"
                                        label="Description*"
                                        name="description"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth error={!!errors.discount_type}>
                                        <InputLabel>Discount Type*</InputLabel>
                                        <Select
                                            id="discount_type"
                                            name="discount_type"
                                            {...register("discount_type")}
                                            label="Discount Type*"
                                        >
                                            <MenuItem value="P">Percentage</MenuItem>
                                            <MenuItem value="A">Amount</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {errors.discount_type && <Typography color="error">{errors.discount_type.message}</Typography>}
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("discount_value", { required: true })}
                                        disabled={loader}
                                        error={errors.discount_value}
                                        helperText={
                                            errors.discount_value ? errors.discount_value.message : false
                                        }
                                        inputProps={{ min: "0" }}
                                        id="discount_value"
                                        label="Discount Value*"
                                        name="discount_value"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("expiration_date")}
                                        disabled={loader}
                                        error={errors.expiration_date}
                                        helperText={
                                            errors.expiration_date ? errors.expiration_date.message : false
                                        }
                                        id="expiration_date"
                                        label="Expiration Date"
                                        name="expiration_date"
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("limit_usage", { required: true })}
                                        disabled={loader}
                                        error={errors.limit_usage}
                                        helperText={
                                            errors.limit_usage ? errors.limit_usage.message : false
                                        }
                                        inputProps={{ min: "0" }}
                                        id="limit_usage"
                                        label="Total Limit Usage*"
                                        name="limit_usage"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        {...register("user_limit_usage", { required: true })}
                                        disabled={loader}
                                        error={errors.user_limit_usage}
                                        helperText={
                                            errors.user_limit_usage ? errors.user_limit_usage.message : false
                                        }
                                        inputProps={{ min: "0" }}
                                        id="user_limit_usage"
                                        label="User Limit Usage*"
                                        name="user_limit_usage"
                                        variant="outlined"
                                        fullWidth
                                        type="number"
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth error={!!errors.eligible_categories}>
                                        <InputLabel>Eligible Categories*</InputLabel>
                                        <Select
                                            {...register("eligible_categories")}
                                            multiple
                                            input={<OutlinedInput label="Eligible Categories" />}
                                            value={productAndCategoryData.eligible_categories}
                                            id="eligible_categories"
                                            name="eligible_categories"
                                            onChange={handleMultiDrop}
                                        >
                                            {categories?.map((cat) => (
                                                <MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors.eligible_categories && <Typography color="error">{errors.eligible_categories.message}</Typography>}
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormControl fullWidth error={!!errors.eligible_products}>
                                        <InputLabel>Eligible Products*</InputLabel>
                                        <Select
                                            {...register("eligible_products")}
                                            multiple
                                            input={<OutlinedInput label="Eligible Products" />}
                                            value={productAndCategoryData.eligible_products}
                                            onChange={handleMultiDrop}
                                            id="eligible_products"
                                            name="eligible_products"
                                        >
                                            {products?.map((prod) => (
                                                <MenuItem key={prod.id} value={prod.id}>{prod.title}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors.eligible_products && <Typography color="error">{errors.eligible_products.message}</Typography>}

                                </Grid>


                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        name="status"
                                                        disabled={loader}
                                                        {...register("status")}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />

                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    textAlign="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default AddDiscount;