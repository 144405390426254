import { image } from "src/constants/api";
import {Stack,TableRow,TableCell,Paper,CircularProgress, Typography, IconButton} from "@mui/material";
import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";
import { FilterDate } from "src/utils/helpers/functions";

const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric" };

function NewsItems(props) {
    const { loading, handlePublished, handleOpenMenu, handleIsTop, errorMessage, newsList, searchTextDebounce, isDesktop, navWidth, srNo } = props;

    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (newsList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                    {
                        searchTextDebounce &&
                        <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{searchTextDebounce}&quot;</strong>.
                        </Typography>
                    }
                </Paper>
            </TableCell>
        </TableRow>
    }

    return <>
        {
            newsList.map((news, index) => {
                const { id, title, news_bulletin, image_high, sub_categories, createdAt, updatedAt, published_date, publish_status, is_top } = news;
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <img
                                    src={
                                        image_high
                                            ? `${image}/${image_high}`
                                            : "./assets/images/web-logo.png"
                                    }
                                    style={{ height: "80px", width: "80px" }}
                                    alt=""
                                />

                                <Typography>
                                    {title?.slice(0, 130)}{" "}
                                    {title?.length > 130 && "..."}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left">
                            {news_bulletin?.name}
                        </TableCell>
                        <TableCell align="left">
                            {sub_categories.map((e, i, arr) => {
                                return arr[i + 1] ? e.name + " , " : e.name;
                            })}
                        </TableCell>
                        <TableCell align="left">
                            Uploaded:{" "}
                            {new Date( FilterDate(createdAt)).toLocaleDateString(
                                "en-US",
                                options
                            )}{" "}
                            <br />
                            Published:{" "}
                            {new Date( FilterDate(published_date)).toLocaleDateString(
                                "en-US",
                                options
                            )}{" "}
                            <br />
                            Updated:{" "}
                            {new Date( FilterDate(updatedAt)).toLocaleDateString(
                                "en-US",
                                options
                            )}
                        </TableCell>
                        <TableCell align="left">
                            <SwitchComponent
                                data={publish_status}
                                handlePublished={handlePublished}
                                id={id}
                                isOnClickTrigger={true}
                            />
                            {/* <AntSwitch
                                data={publish_status}
                                id={id}
                                onChange={() => handlePublished(publish_status ? 0:1, id)}
                                checked={publish_status}
                                sx={{ variant: loading ? 'default':'error' }}
                            /> */}
                        </TableCell>
                        <TableCell align="left">
                            <SwitchComponent
                                data={is_top}
                                handlePublished={handleIsTop}
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </>
}

export default NewsItems;