import axios from "axios";
import { port } from "../constants/api";
import { user } from "../constants/api";

export const getSubscriberList = async (page, no_of_rows=5) => {
  return await axios.get(`${port}/getSubscribeUserList?page=${page}&no_of_rows=${no_of_rows}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const removeSubscriber = async (id) => {
  return await axios.delete (`${port}/removeSubscribeById/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
