import React from 'react'
// import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import Iconify from "../../components/iconify";
import OrderListing from "./orderList/OrderListing";

// import SponsersListing from "./categorylists";
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";


function Orders() {

  const [loading, setloading] = useState(false);

  //CONSTANTS------------------------------------------------------------
  let navigate = useNavigate();

  const isDesktop = useResponsive("up", "lg");
  //const pathName = window.location.pathname.split("/")[2];
  const pathName = /[^/]*$/.exec(window.location.pathname)[0];

  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

  const handleRouteTo = (path) => {
    navigate(path);
  };

  return (
    <>
      <Container maxWidth="xl">
        {loading && (
          <Box
            onClick={() => setloading(false)}
            sx={{
              display: "flex",
              position: "fixed",
              height: "100%",

              bottom: "0",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",

              zIndex: "1000",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {path}
          </Typography>

        </Stack>
        <OrderListing />
      </Container>
    </>
  )
}

export default Orders