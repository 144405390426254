import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useResponsive from "../../hooks/useResponsive";
import { checkTokenExpiry } from "../../utils/helpers/functions";

import PageContainer from 'src/components/admin-ui/container';

import Grid from "@mui/material/Grid";
import { CircularProgress, Typography } from "@mui/material";

import TabSections from './tabSection';
import { getRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";

function GeneralSettings() {
    // const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    let navigate = useNavigate();
    // const dispatch = useDispatch();
    const isDesktop = useResponsive("up", "lg");
    const pathName = window.location.pathname.split("/")[2];
    const { NAV_WIDTH } = useSelector((state) => state.news);
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
    const [isLoading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [socialLinks, setSocialLinks] = useState();
    const [headerContent, setHeaderContent] = useState();
    const [footerContent, setFooterContent] = useState();
    
    async function getHeaderDetails(){
        try{
            const {status, data:{data}} = await getRequest(GENERAL_SETTINGS.GET_SITE_DATA);
            console.log("response ==> ", status, data);
            if( status === 200 && Object.keys(data).length > 0 ){
                const {
                    SOCIAL_LINKS:{value:socialLinkData},
                    HEADER_CONTENT:{value:headerContentData},
                    FOOTER_CONTENT:{value:footerContentData}
                } = data;
                setSocialLinks(socialLinkData);
                setHeaderContent(headerContentData);
                setFooterContent(footerContentData);
                setLoading(false);
            } else {
                console.warn("no data found!");
            }
            setLoading(false);
        } catch (error){
            console.log("error ===> ", error)
            setLoading(false);
            setErrorMessage("Unable to process your request. Please try again.")
            createNotification("error", "", "Unable to process your request. Please try again.");
        }
    }

    useEffect(() => {
        getHeaderDetails();
    }, []);
    

    return (
        <>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="assets/icons/sas.png" />
                <title> {path.replace('-', ' ')} </title>
            </Helmet>
            
            <PageContainer className="page-container users-page" heading={path.replace('-', ' ')}>
                { isLoading && 
                    <div className="loading_main">
                        <div className="loading_inner">
                            <CircularProgress />
                        </div>
                    </div>
                }
                { errorMessage !== '' &&
                    <Typography variant="h6" sx={{ py: 5, px:2, color:'#c92129' }}>
                        {errorMessage}
                    </Typography>
                }
                { !isLoading && errorMessage === '' && 
                    <TabSections 
                        headerContent={headerContent} 
                        socialLinks={socialLinks} 
                        footerContent={footerContent}
                    /> 
                }
            </PageContainer>
        </>
    )
}

export default GeneralSettings;