const PopsmokeLogo = (props) => {
  // const _height = (props.open !== undefined && props.open) ? '70px':'40px'
  // const _width = (props.open !== undefined && props.open) ? 'auto':'30px'
  const _height = (props.open !== undefined && props.open) ? '70px':'auto'
  const _width = (props.open !== undefined && props.open) ? 'auto':'auto'
  return (
    <img
      src="./assets/images/logo/popsmoke-logo.png"
      style={{
        height: _height,
        width: _width,
      }}
      alt="Logo"
    />
  );
};

export default PopsmokeLogo;
