import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText } from "@mui/material";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import Collapse from "@material-ui/core/Collapse";
import { hasChildren } from "src/utils/helpers";
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [] }) {
  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    const { title, path, icon } = item;
    return (
      <>
        <StyledNavItem
          component={RouterLink}
          className="sideBar"
          to={path}
          sx={{
            "&.active": {
              color: "#fff",
              bgcolor: "#000",
              fontWeight: "fontWeightBold",
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
        </StyledNavItem>
      </>
    );
  };

  const MultiLevel = ({ item }) => {
    const { title, icon, items: children } = item;
    let _tempVar = false;

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const location = useLocation();
    let _path = location.pathname.split("/");
    var routeToMatch = title.toLocaleLowerCase();
    
    if (routeToMatch === 'news' && _path[2]?.toLocaleLowerCase().indexOf(routeToMatch) !== -1) {
      _tempVar = true;
    }else if (routeToMatch === 'e-commerce' && _path[1]?.toLocaleLowerCase().indexOf(routeToMatch) !== -1) {
      _tempVar = true;
    }


    const [open, setOpen] = useState(_tempVar);
    return (
      <>
        <StyledNavItem
          onClick={handleClick}
          sx={{
            "&.active": {
              color: "text.primary",
              bgcolor: "action.selected",
              fontWeight: "fontWeightBold",
              position: "relative",
             
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledNavItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ pl: 2 }}>
            {children.map((child, key) => (
              <MenuItem key={key}  item={child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, key) => (
          <MenuItem key={key} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------
