import { useState, useRef } from 'react';
import { Grid, Card, Stack, Typography, TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";
// react hook forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { port } from "src/constants/api";
import $ from "jquery";


//------- validation schema ------
const validationSchema = yup.object().shape({
  description: yup.string().required("This field is required."),
});


export default function PrivacyPolicy(props) {
  const dispatch = useDispatch();
  const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
  const { privacyData } = props;
  const [privacyContent, setPrivacyContent] = useState(privacyData);
  const { register, setValue, handleSubmit,trigger,  formState: { errors } } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
        description: privacyContent?privacyContent.description:'',
    }
  });

  const submitPrivacyContent = async (data) => {
    dispatch(setRequestInProgress());
    const payloadData = {
        key: GENERAL_SETTINGS_KEYS.PRIVACY_POLICY,
        value: JSON.stringify(data),
        status: GENERAL_SETTINGS_KEYS.DEFAULT_STATUS,
    }
    try {
        const { status, data } = await postRequest(GENERAL_SETTINGS.SET_PAGE_FEATURE, payloadData);
        if (status === 200 && data) {
            dispatch(setRequestInit());
            createNotification("success", "", "Privacy Policy Content updated successfully.");

        } else {
            dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
            createNotification("error", "", "Getting error while updating the your request. Please try  again.");
        }
    } catch (error) {
        dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
        createNotification("error", "", "Getting error while updating the your request. Please try  again.");
    }
    return false;
}

  return (
    <>
      <Card className='profile-left-section' sx={{ px: 2, py: 3 }}>
        <form onSubmit={handleSubmit(submitPrivacyContent)} noValidate>
          <Grid
            className="user-input-section"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item md={12}>
              <Typography><h5>Update Privacy Policy</h5></Typography>
            </Grid>


            <Grid item xs={12} md={12}>
                <div className={errors?.description ? "errorCkEditor" : ""}>
                  <CKEditor
                    name="description"
                    style={{
                      position: "relative",
                      zIndex: "20",
                    }}
                    config={{
                      placeholder: "Description*",
                      ckfinder: {
                        uploadUrl: `${port}/upload`,
                      },
                    }}
                    editor={ClassicEditor}
                    data={privacyContent?.description || ""}
                    onChange={(event, editor) => {
                      setValue("description", editor.getData());
                      trigger("description");
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "100%",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {
                      if (errors?.description?.message) {
                        $(".ck-content").removeClass("ck-focused");
                        $(".ck-content").addClass("errorCkEditor");
                      }
                    }}
                  />
                </div>
                {errors.description ? (
                  <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained bulletin-error">
                    {errors.description.message}
                  </p>
                ) : (
                  ""
                )}
              </Grid>



          </Grid>
          <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              my={5}
            >
              <LoadingButton
                loading={loader ? true : false}
                size="medium"
                type="submit"
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
              >
                {loader ? 'Please Wait...' : 'save changes'}
              </LoadingButton>
            </Stack>
        </form>
      </Card>
    </>
  )
}
