import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from "react-redux";

import {
    Card, Table, Stack, Button, Popover, MenuItem, TableBody, TableContainer, TablePagination, OutlinedInput, InputAdornment, Tooltip, FormControl
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import AlertDialog from "../../../common/AlertDialog";
import Paper from "@mui/material/Paper";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import useSearchDebounce from 'src/utils/hooks/textDebounce';
import { checkTokenExpiry } from "../../../utils/helpers/functions";
import { createNotification } from "src/common/createNotification";

import { getNews, updatePublishedStatus, deleteNews } from "src/api/newsList";
import { getArchievedList, getArchievedNews, updateArchievedStatus } from "src/api/archievedList";

import NewsItems from "./newsitem";

function NewsListing(props) {

    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const { setloading } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const order = "asc";
    const orderBy = "name";

    const [pageNo, setPageNo] = useState(0);
    const [progressLoading, setProgressLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();
    const [newsList, setNewsList] = useState([]);
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    const [open, setOpen] = useState(null);
    const [hoveredNewsId, setHoveredNewsId] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    const [srNo, setSrNo] = useState(1);

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setHoveredNewsId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        // setHoveredNewsId(null);
    };

    const handleRouteTo = (path) => {
        navigate(path);
    };

    const handleClickOpen = () => {
        setModalShow(true);
    };


    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
        // setSrNo(rowsPerPage * newPage + 1)
    };

    const handleChangeRowsPerPage = (event) => {
        setPageNo(0);
        setRowsPerPage(parseInt(event.target.value, 10));
        // setSrNo(1);
    };

    const handlePublished = async (e, id) => {
        const payloadData = {
            id,
            publish_status: e,
        };
        try {
            setloading(true)
            const { status, data: { data } } = await updatePublishedStatus(payloadData);
            setloading(false);
            createNotification("success", "Update", "Status updated successfully.");
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while updating status. Please try  again.");
        }
    };

    const handleArchieve = async (id) => {
        const data = {
            id: id,
            archived: false,
          };
        try {
            setloading(true);
            await updateArchievedStatus(data);
            handleCloseMenu();
            setloading(false);
            createNotification("success", "Success", `News was successfully Unarchived.`);
            getNewsList();
        } catch (error) {
            createNotification("error", "error", `Getting error while Unarchiving news. Please try again.`);
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
        }
    };

    const deleteNewsById = async () => {
        try {
            setOpen(null);
            setloading(true);
            await deleteNews(hoveredNewsId);
            setloading(false);
            createNotification("success", "Success", `News was successfully deleted.`);
            getNewsList();
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Error", "Error deleting news");
        }
    };
    
    async function getNewsList() {
        const _pageNo = pageNo + 1;
        try {
            dispatch(setRequestInProgress());
            const response = await getArchievedNews(_pageNo, searchText, rowsPerPage);
            const { status, data: { data, metaData } } = response;

            if (status === 200) {
                setNewsList(data);
                setTotalNewsCount(metaData.count)
                dispatch(setRequestInit());
            } else {}
        } catch (error) {
            dispatch(setRequestFailure("Getting error while processing your request. Please try  again."));
        }
    }

    const handleSerchInputChange = (event) => {
        const value = event.target.value === undefined ? '' : event.target.value;
        setSearchText(value);
        setSearchTextDebounce(value);
    };

    const handleClearSearchInput = (event) => {
        const value = event.target.value === undefined ? '' : event.target.value;
        setSearchText(value);
        setSearchTextDebounce(value);
    };

    useEffect(() => {
        getNewsList();
    }, [pageNo, rowsPerPage, searchTextDebounce]);

    return <>
        <Card>
                <Stack
                    container
                    p={3}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                <FormControl item fullWidth variant="outlined">
                    <OutlinedInput
                    onChange={handleSerchInputChange}
                    value={searchText}
                    sx={{ maxHeight: '40px' }}
                    placeholder="Search news..."
                    id="outlined-adornment-weight"
                    startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                    endAdornment={searchText.length > 0 ?
                        <InputAdornment position="end">
                        <Button onClick={handleClearSearchInput} sx={{ color: "#bf2b3a" }} startIcon={<DeleteRoundedIcon />}>clear</Button>
                        </InputAdornment>
                        : false
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                    }} />
                </FormControl>
                </Stack>
            <Scrollbar>
                <TableContainer
                    component={Paper}
                    sx={{ minWidth: 800, borderRadius: "0px" }}
                >
                    <Table>
                        <NewsListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={10}
                            numSelected={1}
                        />
                        <TableBody>
                            <NewsItems
                                loading={loader}
                                errorMessage={errorMessage}
                                successMessage={successMessage}
                                newsList={newsList}
                                srNo={srNo}
                                handleRouteTo={handleRouteTo}
                                handlePublished={handlePublished}
                                handleOpenMenu={handleOpenMenu}
                                searchTextDebounce={searchTextDebounce}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                {!errorMessage && newsList.length > 0 &&
                    <TablePagination
                        className="tbl-pagination"
                        rowsPerPageOptions={ newsList.length > 0 ? [5, 10, 25, 50, 100]:false}
                        component="div"
                        count={totalNewsCount}
                        rowsPerPage={rowsPerPage}
                        page={pageNo}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        progressLoading={progressLoading}
                    />
                }
            </Scrollbar>
        </Card>
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
        >
            <MenuItem onClick={() => handleArchieve(hoveredNewsId)}>
                <Iconify icon={"ic:round-archive"} sx={{ mr: 2 }} />
                Unarchive
            </MenuItem>
        </Popover>

        <AlertDialog
            open={modalShow}
            setOpen={setModalShow}
            deletefunction={deleteNewsById}
        />
    </>
}

export default NewsListing;
// const TABLE_HEAD = [
//     { id: "title", className:'arhieved_head_title', label: "Title", align: "left" },
//     { id: "newsBulletin", className:'arhieved_head_newsBulletin', label: "Category", align: 'left' },
//     { id: "date", className:'arhieved_head_date', label: "Date", align: 'center' },
//     { id: "options", className:'arhieved_head_options', label: "Options", align: 'right' },
// ];

const TABLE_HEAD = [
    { id: "title", className:'arhieved_head_title', label: "Title", alignRight: false },
    { id: "category", className:'arhieved_head_newsBulletin', label: "Category", alignRight: false },
    { id: "newsBulletin", className:'arhieved_head_newsBulletin', label: "Sub-Category", alignRight: false },
    { id: "date", className:'arhieved_head_date', label: "Date", alignRight: false },
    { id: "options", label: "Options" },
];