import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { getRequest } from "src/app/httpClient/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_URL } from "src/app/config/endpoints";
import './invoice.css'
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
const Invoice = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [OrderDetails, SetOrderDetail] = useState([])

    async function getOrderPrint() {
        const _ENDPONIT = `${GENERAL_SETTINGS.GET_ORDERS_DETAILS}${id}`;
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT, id);
            const { status, data: { data } } = response;
            if (status === 200) {
                console.log("first Invoice", data)
                SetOrderDetail(data)
                dispatch(setRequestInit());
            } else { }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }
    useEffect(() => {
        getOrderPrint()
    }, []);

    function handlePrint() {
        window.print();
    }


    return (
        <div id="section-to-print">
            <div id="invoice-container" style={{
                width: 800,
                margin: "0 auto",
                padding: "15px 10px",
                marginTop: "10px",
                border: "1px solid #c7c7c7",
                fontFamily: '"Roboto", sans-serif'
            }}>
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{
                        width: "100%",
                        margin: "0 auto",
                        padding: "15px 10px",
                        fontFamily: '"Roboto", sans-serif'
                    }}
                >
                    <tbody>
                        <tr>
                            <td style={{ width: "50%", verticalAlign: "top" }}>
                                <img
                                    width="230px"
                                    src="./assets/images/logo/popsmoke-logo.png"
                                />
                                <h2
                                    // style={{ margin: 0, marginTop: 10, marginBottom: 8, fontSize: 16 }}
                                    style={{
                                        color: "#000",
                                        fontSize: 18,
                                        fontWeight: 700,
                                        WebkitPrintColorAdjust: "exact",
                                        width: "50%"
                                    }}
                                >

                                    To   {OrderDetails?.orders?.[0]?.shipping?.billing_first_name} {OrderDetails?.orders?.[0]?.shipping?.billing_last_name}
                                    {/* {OrderDetails.invoice_no}  To Daniel Sharp */}
                                </h2>
                                <p
                                    style={{
                                        fontSize: 12,
                                        lineHeight: 2,
                                        color: "#747474",
                                        margin: 0,
                                        width: "60%"
                                    }}
                                >
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_address_line_1},{OrderDetails?.orders?.[0]?.shipping?.billing_address_line_2} ,{OrderDetails?.orders?.[0]?.shipping?.billing_city},{OrderDetails?.orders?.[0]?.shipping?.billing_state} <br /> {OrderDetails?.orders?.[0]?.shipping?.billing_zip}
                                    ,
                                    <span style={{ display: "block" }}>Phone:{OrderDetails?.orders?.[0]?.shipping?.billing_phone}</span>
                                    <span style={{ display: "block" }}>Email: {OrderDetails?.orders?.[0]?.shipping?.billing_email}</span>
                                </p>
                            </td>
                            <td>
                                <h2
                                    style={{
                                        textTransform: "uppercase",
                                        fontWeight: 900,
                                        color: "#ca2132",
                                        WebkitPrintColorAdjust: "exact",
                                        fontSize: 20,
                                        margin: 0,
                                        marginBottom: 8,
                                        lineHeight: 1
                                    }}
                                >
                                    Invoice
                                </h2>
                                <h6 style={{ margin: 0, fontSize: 13 }}>Invoice No. #{OrderDetails.invoice_no}</h6>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                    fontSize: 12,
                                                    padding: "8px 0px"
                                                }}
                                            >
                                                <p style={{ margin: 0, marginBottom: 5 }}>Order date:</p>
                                                <p style={{ margin: 0, marginBottom: 5 }}>Invoice date:</p>
                                            </td>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                    fontSize: 13,
                                                    padding: "8px 0px"
                                                }}
                                            >
                                                <p style={{ margin: 0, marginBottom: 5 }}>{new Date(OrderDetails.createdAt).toDateString()}</p>
                                                <p style={{ margin: 0, marginBottom: 5 }}>{new Date().toDateString()}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span
                                                    style={{
                                                        color: "#000",
                                                        fontSize: 18,
                                                        fontWeight: 900,
                                                        WebkitPrintColorAdjust: "exact",
                                                        width: "50%"
                                                    }}
                                                >
                                                    Total : ${OrderDetails?.orders?.[0]?.order_total}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height={10} />
                        </tr>
                        <tr>
                            <td style={{ width: "100%" }} colSpan={2}>
                                <table
                                    style={{
                                        borderCollapse: "collapse",
                                        width: "100%",
                                        fontWeight: 300,
                                        fontSize: 15
                                    }}
                                    className="head-bg"
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                backgroundColor: "#ca2132",
                                                WebkitPrintColorAdjust: "exact",
                                                color: "#fff"
                                            }}
                                        >
                                            <td style={{ padding: 5 }}>
                                                <b>Item Description </b>
                                            </td>
                                            <td style={{ padding: 5 }}>
                                                <b>Price</b>
                                            </td>
                                            <td style={{ padding: 5, textAlign: "center" }}>
                                                <b> Qty </b>
                                            </td>
                                            <td style={{ padding: 5, textAlign: "right" }}>
                                                <b> Total</b>
                                            </td>
                                        </tr>


                                        {
                                            OrderDetails &&
                                            OrderDetails?.orders?.map((item, index) => {
                                                return (
                                                    <tr
                                                        style={{
                                                            background: "#f7f7f7",
                                                            WebkitPrintColorAdjust: "exact",
                                                            borderBottom: "solid 1px #d2d2d2",
                                                            fontSize: 14
                                                        }}
                                                    >
                                                        <td style={{ padding: "8px 14px" }}>
                                                            {item?.product_name}
                                                        </td>
                                                        <td style={{ padding: "8px 14px" }}>${item?.product_amt}</td>
                                                        <td style={{ padding: "8px 14px", textAlign: "center" }}>
                                                            {item?.quantity}
                                                        </td>
                                                        <td style={{ padding: "8px 14px", textAlign: "right" }}>${item?.total_price}</td>

                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr style={{ verticalAlign: "top" }}>
                            <td style={{ width: "60%", padding: "10px 10px" }}>
                                <h6
                                    style={{
                                        color: "#ca2132",
                                        fontSize: 14,
                                        WebkitPrintColorAdjust: "exact",
                                        fontWeight: 400,
                                        margin: "5px 0px"
                                    }}
                                >
                                    From
                                </h6>
                                <p style={{ margin: "0px 0px 5px 0px", fontSize: 12 }}>
                                    Main Office: 123456 sateic city Static country name
                                    <br /> staiczip 75225
                                </p>
                            </td>
                            <td style={{ padding: "10px 10px" }}>
                                <table
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        fontWeight: 600
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                    fontSize: 13,
                                                    padding: "0px 10px"
                                                }}
                                            >
                                                <p style={{ margin: 0, marginBottom: 8 }}>Sub Total:</p>
                                                <p style={{ margin: 0, marginBottom: 8 }}>Shipping: </p>
                                            </td>
                                            <td
                                                style={{
                                                    textTransform: "capitalize",
                                                    fontSize: 13,
                                                    textAlign: "right",
                                                    padding: "0px 10px"
                                                }}
                                            >
                                                <p style={{ margin: 0, marginBottom: 8 }}>${OrderDetails && OrderDetails?.orders?.reduce((total, order) => total + order?.product_amt, 0)}</p>
                                                <p style={{ margin: 0, marginBottom: 8 }}>${OrderDetails?.orders?.[0]?.order_total}</p>
                                            </td>
                                        </tr>
                                        <tr
                                            style={{
                                                // background: "#bfbfbf",

                                                color: "#000",
                                                WebkitPrintColorAdjust: "exact",
                                                fontSize: 15
                                            }}
                                        >
                                            <td
                                                style={{ padding: "5px  10px", textTransform: "capitalize" }}
                                            >
                                                Grand total
                                            </td>
                                            <td style={{ textAlign: "right", padding: "5px 10px" }}>$ 2</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ width: "100%" }} colSpan={2}>
                                <table
                                    style={{
                                        borderCollapse: "collapse",
                                        width: "100%",
                                        fontWeight: 300
                                    }}
                                    className="head-bg"
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                backgroundColor: "#ca2132",
                                                WebkitPrintColorAdjust: "exact",
                                                color: "#fff",
                                                fontSize: 15
                                            }}
                                        >
                                            <td style={{ padding: 5 }}>
                                                <b>Popsmoke</b>
                                            </td>
                                            <td style={{ padding: 5, textAlign: "right" }}>
                                                <b>Contact Email: info@popsmokemedia.com </b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tfoot>
                </table>

            </div>
            <button
                className="no-print"
                style={{
                    margin: "10px auto",
                    display: "block",
                    borderRadius: "5px",
                    padding: "5px 12px",
                    cursor: "pointer"
                }}
                onClick={handlePrint}
            >
                Print
            </button>

        </div>

    )
}

export default Invoice