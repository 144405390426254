import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Slices/UserSlice";
import newsReducer from "./Slices/NewsSlice";
import requestSlice from "./Slices/RequestSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    news: newsReducer,
    serviceRequest: requestSlice,
  },
});

export default store;
