import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";


// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  MenuItem,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getContactById, updateContact } from "src/api/contactList";
import { countries } from "src/constants/countries";
import { createNotification } from "src/common/createNotification";

function EditContact() {
  //useful constants
  const formTop = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const pathName = window.location.pathname.split("/")[2];
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required").when({
      is: (val) => val.length > 0,
      then: Yup.string().matches(/^[a-zA-Z ,.'-]+$/, "First Name can contain only letters"),
    }),
    last_name: Yup.string().required("Last Name is required").when({
      is: (val) => val.length > 0,
      then: Yup.string().matches(/^[a-zA-Z ,.'-]+$/, "Last Name can contain only letters"),
    }),

    message: Yup.string().required("Message is required"),
    phone: Yup.number().typeError("Phone Number is required").positive('Must be a positive number.'),
    role: Yup.string().required("Role is required"),
    email: Yup.string().required("Email is required"),
    // country: Yup.string().required("Country is required"),
    country: Yup.number().typeError("Country is required").positive('Must be a positive number.'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  //states
  const [roles, setroles] = useState("");
  const [country, setcountry] = useState("");
  const [loading, setloading] = useState(false);
  const [fields, setfields] = useState({
    first_name: " ",
    last_name: " ",
    country: " ",
    phone: " ",
    role: " ",
    email: " ",
    message: " ",
  });
  const [rolesData] = useState([
    {
      name: "Sales",
    },
    {
      name: "Media Relations",
    },
    {
      name: "Support",
    },
  ]);

  //useEffects
  useEffect(() => {
    getContactData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  //functions
  const getContactData = async () => {
    setloading(true);
    try {
      const data = await getContactById(id);
      setroles(data.data.data.role);
      setfields(data.data.data);
      setloading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg)
      setloading(false);
      createNotification("error", "Error", "Error loading contact");
    }
  };

  const handleRouteTo = (path) => {
    navigate(path);
  };

  const onSubmitForm = async (data) => {
    setloading(true);
    try {
      data = { ...data, country: data.country.toString().replace(/(?:)(?:[a-zA-Z() ]{0,})/gi, "") }
      await updateContact({ ...data, id });
      setloading(false);
      handleRouteTo("/dashboard/contacts");
      createNotification("success", "Updated", "Updated Successfully");
    } catch (error) {
      checkTokenExpiry(error.response.data.msg)
      setloading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> {path} </title>
      </Helmet>

      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Container ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            {path}
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/dashboard/${pathName}`}
          >
            {path}
          </Link>
          <Typography color="text.primary">Edit {path}</Typography>
        </Breadcrumbs>

        <Card>
          <CardHeader title={`Edit ${path}`} />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
            sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
          >
            <Grid container spacing={4} alignItems="start">
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("first_name", { required: true })}
                  error={errors.first_name}
                  helperText={
                    errors.first_name ? errors.first_name.message : false
                  }
                  label="First Name"
                  name="first_name"
                  variant="outlined"
                  fullWidth

                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("last_name", { required: true })}
                  error={errors.last_name}
                  helperText={
                    errors.last_name ? errors.last_name.message : false
                  }
                  id="last_name-name"
                  label="Last Name"
                  name="last_name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("email", { required: true })}
                  error={errors.email}
                  helperText={errors.email ? errors.email.message : false}
                  label="Email"
                  name="email"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={3} direction="column">
                  <FormControl
                    error={errors.role?.message}
                    name="bulletin"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Roles
                    </InputLabel>
                    <Select
                      labelId="contact-label"
                      name="role"
                      error={errors.role}
                      value={roles}
                      {...register("role", {
                        required: true,
                        onChange: (e) => setroles(e.target.value),
                      })}
                      label="Roles"
                    >
                      {rolesData.map((index, i) => {
                        const { name } = index;
                        return (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors.role?.message ? errors.role?.message : false}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  autoHighlight
                  disableClearable
                  getOptionLabel={(option) => `+${option.phone} ( ${option.label} )`}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...register("country", {
                        onChange: (e) => setcountry(e.target.value),
                      })}
                      label="Choose a country"
                      error={errors.country}
                      helperText={errors.country?.message}
                      InputLabelProps={{ shrink: true }}
                      value={country}
                      name="country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  {...register("phone", { required: true })}
                  error={errors.phone}
                  helperText={errors.phone ? errors.phone.message : false}
                  label="Phone Number"
                  name="phone"
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  {...register("message", { required: true })}
                  label="Message "
                  error={errors.message}
                  helperText={errors.message ? errors.message.message : false}
                  name="message"
                  variant="outlined"
                  rows={3}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Button type="submit" variant="contained">
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default EditContact;
