import axios from "axios";
import { port } from "../constants/api";
import { user } from "../constants/api";

export const getContactList = async (number, no_of_rows=5) => {
  return await axios.get(`${port}/fetchContactUsList?page=${number}&no_of_rows=${no_of_rows}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};



export const getContactById = async (id) => {
  return await axios.get(`${port}/getcontactById?contactId=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const createContact = async (data) => {
  return await axios.post(
    `${port}/addContactUs`,
    data
  );
};
export const deleteContact = async (id) => {
  return await axios.delete(`${port}/deleteContact/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const updateContact = async (data) => {
  return await axios.post(`${port}/updateContact`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const updatePublishedStatus = async (data) => {
  return await axios.post(`${port}/publishNewsById`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
