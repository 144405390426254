// import { Grid } from "@mui/material";
// import AnalyticsChartB from "./AnalyticsChartB";
// import AnalyticsChartA from "./AnalyticsChartA";
// import PageAnalytics from "./PageAnalytics";
// import LocationAnalytics from "./LocationAnalytics"


// const GoogleAnalytics = () => {
//   return (
//     <Grid container direction="row" spacing={2} columns={12}>
//       <Grid item xs={7}>
//         <AnalyticsChartA />
//       </Grid>
//       <Grid item xs={5}>
//         <AnalyticsChartB />
//       </Grid>
//     </Grid>
//   );
// };

// export default GoogleAnalytics;
import { Grid } from "@mui/material";
import AnalyticsChartB from "./AnalyticsChartB";
import AnalyticsChartA from "./AnalyticsChartA";

const GoogleAnalytics = () => {
  return (
    <Grid container spacing={2}>
      {/* AnalyticsChartA */}
      <Grid item xs={12} md={7}>
        <AnalyticsChartA />
      </Grid>

      {/* AnalyticsChartB */}
      <Grid item xs={12} md={5}>
        <AnalyticsChartB />
      </Grid>
    </Grid>
  );
};

export default GoogleAnalytics;
