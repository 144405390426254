import { image } from "src/constants/api";
import { Stack, TableRow, TableCell, Paper, CircularProgress, Typography, IconButton } from "@mui/material";

import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";
import { ASSETS_URL } from "src/app/config/endpoints";

const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric" };

function SponserItem(props) {
    const { loading, handlePublished, handleOpenMenu, errorMessage, sponsersList, searchTextDebounce, isDesktop, navWidth, srNo } = props;

    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (sponsersList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                </Paper>
            </TableCell>
        </TableRow>
    }

    return <>
        {
            sponsersList.map((news, index) => {
                const { id, name, thumb_nail, url, status } = news;
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <img
                                    src={
                                        thumb_nail
                                            ? `${ASSETS_URL.IMAGE}/${thumb_nail}`
                                            : "./assets/images/web-logo.png"
                                    }
                                    style={{ height: "80px", width: "80px" }}
                                    alt=""
                                />
                                <Typography className="news_sponsors word-wrap">
                                    {name}
                                </Typography>
                            </Stack>
                        </TableCell>
                        <TableCell align="left">
                            <Typography className="news_sponsors word-wrap">
                                {url}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <SwitchComponent
                                data={status}
                                handlePublished={handlePublished}
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </>
}

export default SponserItem;