import { useState, useRef } from 'react';
import { Grid, Box, Card, Stack, InputAdornment, IconButton, Typography, Avatar, CircularProgress, TextField, FormHelperText, FormLabel } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { format } from "date-fns";

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import ImageListItem from '@mui/material/ImageListItem';

import AntSwitch from "src/components/switch/antSwitch";

// react hook forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";


//------- validation schema ------
const validationSchema = yup.object().shape({
    email: yup.string()
        .required("This field is required.")
        .email("Please enter valid email."),
    phone_no: yup.string().required("This field is required.").max(15, 'Phone numbe can not be longer than 15 digits'),
    address: yup.string().required("This field is required.").max(50, 'Address is can not be more than 50 charachters.'),
    description: yup.string().required("This field is required."),
});

function FoterContent(props) {
    const dispatch = useDispatch();
    const logoImgRef = useRef();
    let date = format(new Date(), "eeee, MMMM d, yyyy");
    // const [loader, setLoader] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    const { item } = props;
    const [footerContent, setFooterContent] = useState(item);
    const [logoValidation, setLogoValidation] = useState({});

    const [logo, setLogo ] = useState(`${ASSETS_URL.IMAGE}/${footerContent.logo.image_high}`);

    // console.log(footerContent);
    // console.log(footerContent.logo.image_high);
    
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            email: footerContent.contact_info.email,
            address: footerContent.contact_info.address,
            phone_no: footerContent.contact_info.phone_no,
            description: footerContent.text.main,
            copyright: footerContent.contact_info.copyright,
        }
    });

    const submitFooterContent = async (data) => {
        console.log(data);
        dispatch(setRequestInProgress());
        const payloadData = {
            key: GENERAL_SETTINGS_KEYS.FOOTER_CONTENT,
            value: JSON.stringify(footerContent),
            status: GENERAL_SETTINGS_KEYS.DEFAULT_STATUS,
        }
        // return false
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.SET_GENERAL_SETTINGS, payloadData);

            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Footer content updated successfully.");
                setLogoValidation({invalid:false,message:''})

            } else {
                dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
                createNotification("error", "", "Getting error while updating the your request. Please try  again.");
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
            createNotification("error", "", "Getting error while updating the your request. Please try  again.");
        }

        return false;
    }

    const handleTimeStatus = ( _status ) => {
        const existingState = { ...footerContent };
        
        const updatedState = {
            ...existingState,
            time: {
                ...existingState.time,
                status: _status ? 0 : 1,
            },
        }
        setFooterContent(updatedState);
    }

    const handleChangeInput = (event, parentProp) => {
        const {name, value} = event.target;
        let _name = name === 'description'?'main':name;
        console.log(_name);
        const existingState = { ...footerContent };

        const updatedState = { 
            ...existingState,
            [parentProp]: {
                ...existingState[parentProp],
                [_name]: value
            },
        }
        setFooterContent( updatedState );
    }

    const uploadImage = async(e) => {
        let formData = new FormData();
        const _FILE = e.target.files[0];

        if( !["image/jpeg", "image/png", "image/jpg"].includes(_FILE.type) ){
            console.warn("inside");
            setLogoValidation({invalid:true, message:'Only *.jpeg, *.jpg, *.png type of images are allowed'})
            return false;
        } else if( _FILE.size >= 5242880 ){
            setLogoValidation({invalid:true,message:'Image size can not be more than 5 MB'})
            return false;
        } else {
            setLogoValidation({invalid:false,message:''})
        }
        // ["image/jpeg", "image/png", "image/jpg"].includes(file[0].type)
        setLogo(URL.createObjectURL(_FILE));

        formData.append('file', _FILE);
       
        try {
            dispatch(setRequestInProgress());
            const {status, data} = await postRequest(GENERAL_SETTINGS.UPLOAD_IMAGE, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                const {data:{filename}} = data;
                console.log(filename);

                const existingState = { ...footerContent };
                const updatedState = { 
                    ...existingState,
                    logo: {
                        ...existingState.logo,
                        image_high: filename
                    },
                }
                setFooterContent( updatedState );

                //setLogo(`${ASSETS_URL.IMAGE}/${filename}`);
            } else {
                dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
            }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
        }
    }
    return <>
        <Card className='profile-left-section' sx={{ px: 2, py: 3 }}>
            {/* <pre>
                {JSON.stringify( footerContent, undefined, 2)}
                </pre> */}
            <form onSubmit={handleSubmit(submitFooterContent)} noValidate>
                <Grid
                    className="user-input-section"
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item md={7}>
                        <Grid item py={2}>
                                <input
                                    ref={logoImgRef}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    onChange={uploadImage}
                                    name="profile_image"
                                    label="Profile Image"
                                    type="file"
                                />
                                <div
                                    className={ logoValidation.invalid  ? "errorCkEditor": "borderInput"}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        padding: "20px",
                                        postition: "relative",
                                    }}>
                                    <img
                                        src={logo}
                                        alt={'logo'}
                                        loading="lazy"
                                        style={{
                                            width:'250px',
                                            height:'auto',
                                            objectFit:'contain'
                                        }}
                                    />
                                    <span className="mt-3" style={{color: logoValidation.invalid ? "#ff5c56" : "#8d9299",
                                                fontSize: "14px",
                                                marginBottom: "20px",
                                                textAlign:'center'
                                            }}>
                                        Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).
                                    </span>
                                    <LoadingButton 
                                        disabled={loader}
                                        loading={loader ? true : false}
                                        variant="contained"
                                        loadingPosition="end"
                                        component="label"
                                        onClick={() => loader ? false : logoImgRef.current.click()}>
                                            {loader ? 'Uploading...' :'Upload logo'}
                                    </LoadingButton>
                                </div>
                                {logoValidation.invalid && <p className="errorForm">{logoValidation.message}</p>}
                        </Grid>
                        <Grid item mt={4}>
                            <TextField fullWidth name="address" disabled={loader}
                                {...register('address')}
                                error={!!errors.address}
                                helperText={errors.address ? errors.address.message : ''}
                                placeholder="Enter address"
                                onChange={(event)=> handleChangeInput(event, 'contact_info')}
                                InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                            }} />
                        </Grid>
                        <Grid item mt={4}>
                            <TextField fullWidth name="phone_no" disabled={loader}
                                {...register('phone_no')}
                                error={!!errors.phone_no}
                                helperText={errors.phone_no ? errors.phone_no.message : ''}
                                placeholder="Enter phone number"
                                onChange={(event)=> handleChangeInput(event, 'contact_info')}
                                InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocalPhoneRoundedIcon />
                                            </InputAdornment>
                                        ),
                            }} />
                        </Grid>
                        <Grid item mt={4}>
                            <TextField fullWidth name="email" disabled={loader}
                                {...register('email')}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ''}
                                placeholder="Enter email address"
                                onChange={(event)=> handleChangeInput(event, 'contact_info')}
                                InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailRoundedIcon />
                                            </InputAdornment>
                                        ),
                            }} />
                        </Grid>
                        <Grid item mt={4}>
                            <TextField fullWidth name="copyright" disabled={loader}
                                {...register('copyright')}
                                error={!!errors.copyright}
                                helperText={errors.copyright ? errors.copyright.message : ''}
                                placeholder="Enter copyright"
                                onChange={(event)=> handleChangeInput(event, 'contact_info')}
                                InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailRoundedIcon />
                                            </InputAdornment>
                                        ),
                            }} />
                        </Grid>
                        <Grid item mt={4}>
                            <TextField
                                {...register('description')}
                                name="description"
                                disabled={loader}
                                error={!!errors.description}
                                helperText={errors.description ? errors.description.message : ''}
                                multiline
                                rows={6}
                                label="Description"
                                // value={user.bio}
                                onChange={(event)=> handleChangeInput(event, 'text')}
                                fullWidth
                            />
                        </Grid>
                        

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            my={2}
                        >
                            <LoadingButton
                                loading={loader ? true : false}
                                size="medium"
                                type="submit"
                                loadingPosition="end"
                                endIcon={<SendIcon />}
                                variant="contained"
                            >
                                {loader ? 'Please Wait...' : 'save changes'}
                            </LoadingButton>
                        </Stack>
                    </Grid>

                </Grid>

            </form>
        </Card>
    </>
}

export default FoterContent;