import { useState, useRef } from 'react';
import { Grid, Card, Stack, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";
// react hook forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";


//------- validation schema ------
const validationSchema = yup.object().shape({
  section_one_title: yup.string().required("This field is required."),
  section_one_description: yup.string().required("This field is required."),
  section_one_facebook: yup.string(),
  section_one_twitter: yup.string(),
  section_one_instagram: yup.string(),
  section_one_linkedin: yup.string(),
  section_two_title: yup.string().required("This field is required."),
  section_two_description: yup.string().required("This field is required."),
  section_two_facebook: yup.string(),
  section_two_twitter: yup.string(),
  section_two_instagram: yup.string(),
  section_two_linkedin: yup.string(),
});


export default function AboutUs(props) {
  //const [fileOne, setFileOne] = useState('file-one');
  //const [fileTwo, setFileTwo] = useState('file-two');
  const dispatch = useDispatch();
  const logoImgRefOne = useRef();
  const logoImgRefTwo = useRef();
  const [logoValidation, setLogoValidation] = useState({});
  const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
  const { aboutData } = props;
  const [aboutContent, setAboutContent] = useState(aboutData);
  const [imageSectionOne, setImageSectionOne] = useState(`${ASSETS_URL.IMAGE}/${aboutContent.main?.section_one_image}`);
  const [imageSectionTwo, setImageSectionTwo] = useState(`${ASSETS_URL.IMAGE}/${aboutContent.main?.section_two_image}`);
  console.log(aboutContent)


  const { register, setValue, handleSubmit, trigger, formState: { errors } } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      section_one_title: aboutContent.main ? aboutContent.main.section_one_title : '',
      section_one_description: aboutContent.main ? aboutContent.main.section_one_description : '',
      section_one_facebook: aboutContent.main ? aboutContent.main.section_one_facebook : '',
      section_one_twitter: aboutContent.main ? aboutContent.main.section_one_twitter : '',
      section_one_instagram: aboutContent.main ? aboutContent.main.section_one_instagram : '',
      section_one_linkedin: aboutContent.main ? aboutContent.main.section_one_linkedin : '',
      section_two_title: aboutContent.main ? aboutContent.main.section_two_title : '',
      section_two_description: aboutContent.main ? aboutContent.main.section_two_description : '',
      section_two_facebook: aboutContent.main ? aboutContent.main.section_two_facebook : '',
      section_two_twitter: aboutContent.main ? aboutContent.main.section_two_twitter : '',
      section_two_instagram: aboutContent.main ? aboutContent.main.section_two_instagram : '',
      section_two_linkedin: aboutContent.main ? aboutContent.main.section_two_linkedin : '',
    }
  });

  const submitAboutContent = async (data) => {
    dispatch(setRequestInProgress());
    const payloadData = {
      key: GENERAL_SETTINGS_KEYS.ABOUT_US,
      value: JSON.stringify(aboutContent),
      status: GENERAL_SETTINGS_KEYS.DEFAULT_STATUS,
    }
    try {
      const { status, data } = await postRequest(GENERAL_SETTINGS.SET_PAGE_FEATURE, payloadData);

      if (status === 200 && data) {
        dispatch(setRequestInit());
        createNotification("success", "", "About Us Content updated successfully.");
        setLogoValidation({ invalid: false, message: '' })

      } else {
        dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
        createNotification("error", "", "Getting error while updating the your request. Please try  again.");
      }
    } catch (error) {
      dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
      createNotification("error", "", "Getting error while updating the your request. Please try  again.");
    }

    return false;
  }

  const handleChangeInput = (event, parentProp) => {
    const { name, value } = event.target;
    const existingState = { ...aboutContent };

    const updatedState = {
      ...existingState,
      [parentProp]: {
        ...existingState[parentProp],
        [name]: value
      },
    }
    setAboutContent(updatedState);
  }
  const handleFileOne = (e) => {
    uploadImage('section_one_image', e.target.files[0]);
  };

  const handleFileTwo = (e) => {
    uploadImage('section_two_image', e.target.files[0]);
  };

  const uploadImage = async (field, file) => {
    let formData = new FormData();
    const _FILE = file;
    if (!["image/jpeg", "image/png", "image/jpg"].includes(_FILE.type)) {
      console.warn("inside");
      setLogoValidation({ invalid: true, message: 'Only *.jpeg, *.jpg, *.png type of images are allowed' })
      return false;
    } else if (_FILE.size >= 5242880) {
      setLogoValidation({ invalid: true, message: 'Image size can not be more than 5 MB' })
      return false;
    } else {
      setLogoValidation({ invalid: false, message: '' })
    }

    formData.append('file', _FILE);
    try {
      dispatch(setRequestInProgress());
      const { status, data } = await postRequest(GENERAL_SETTINGS.UPLOAD_IMAGE, formData);
      if (status === 200 && data) {
        dispatch(setRequestInit());
        const { data: { filename } } = data;

        const existingState = { ...aboutContent };
        
        if (field === 'section_one_image') {
          setImageSectionOne(URL.createObjectURL(_FILE));
        } else {
          setImageSectionTwo(URL.createObjectURL(_FILE));
        }
        const updatedState = {
          ...existingState,
          ['main']: {
            ...existingState['main'],
            [field]: filename
          },
        }
        setAboutContent(updatedState);
      } else {
        dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
      }
    } catch (error) {
      console.log(error);
      dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
    }
  };

  return (
    <>
      <Card className='profile-left-section' sx={{ px: 2, py: 3 }}>
        <form onSubmit={handleSubmit(submitAboutContent)} noValidate encType='multipart/form-data'>
          <Grid
            className="user-input-section"
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
          >
            <Grid item md={6}>
              <Typography><h5>Team Section 1</h5></Typography>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_title')}
                  name="section_one_title"
                  label="Title"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_description')}
                  name="section_one_description"
                  multiline
                  rows={6}
                  label="Description"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item py={2}>
                <input
                  ref={logoImgRefOne}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  name="section_one_image"
                  label="Image1"
                  type="file"
                  onChange={handleFileOne}
                />
                <div
                  className={logoValidation.invalid ? "errorCkEditor" : "borderInput"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    postition: "relative",
                  }}>
                  <img
                    src={imageSectionOne}
                    alt={'logo'}
                    loading="lazy"
                    style={{
                        width:'250px',
                        height:'auto',
                        objectFit:'contain'
                    }}
                  />
                  <span className="mt-3" style={{
                    color: logoValidation.invalid ? "#ff5c56" : "#8d9299",
                    fontSize: "14px",
                    marginBottom: "20px",
                    textAlign: 'center'
                  }}>
                    Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).
                  </span>
                  <LoadingButton
                    disabled={loader}
                    loading={loader ? true : false}
                    variant="contained"
                    loadingPosition="end"
                    component="label"
                    onClick={() => loader ? false : logoImgRefOne.current.click()}>
                    {loader ? 'Uploading...' : 'Upload Image'}
                  </LoadingButton>
                </div>
                {logoValidation.invalid && <p className="errorForm">{logoValidation.message}</p>}
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_facebook')}
                  name="section_one_facebook"
                  label="Facebook"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_twitter')}
                  name="section_one_twitter"
                  label="Twitter"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_instagram')}
                  name="section_one_instagram"
                  label="Instagram"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_one_linkedin')}
                  name="section_one_linkedin"
                  label="Linkedin"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography><h5>Team Section 2</h5></Typography>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_title')}
                  name="section_two_title"
                  label="Title"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_description')}
                  name="section_two_description"
                  multiline
                  rows={6}
                  label="Description"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item py={2}>
                <input
                  ref={logoImgRefTwo}
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file2"
                  name="section_two_image"
                  label="Image2"
                  type="file"
                  onChange={handleFileTwo}
                />
                <div
                  className={logoValidation.invalid ? "errorCkEditor" : "borderInput"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    postition: "relative",
                  }}>
                  <img
                          src={imageSectionTwo}
                          alt={'logo'}
                          loading="lazy"
                          style={{
                              width:'250px',
                              height:'auto',
                              objectFit:'contain'
                          }}
                        />
                  <span className="mt-3" style={{
                    color: logoValidation.invalid ? "#ff5c56" : "#8d9299",
                    fontSize: "14px",
                    marginBottom: "20px",
                    textAlign: 'center'
                  }}>
                    Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).
                  </span>
                  <LoadingButton
                    disabled={loader}
                    loading={loader ? true : false}
                    variant="contained"
                    loadingPosition="end"
                    component="label"
                    onClick={() => loader ? false : logoImgRefTwo.current.click()}>
                    {loader ? 'Uploading...' : 'Upload Image'}
                  </LoadingButton>
                </div>
                {logoValidation.invalid && <p className="errorForm">{logoValidation.message}</p>}
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_facebook')}
                  name="section_two_facebook"
                  label="Facebook"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_twitter')}
                  name="section_two_twitter"
                  label="Twitter"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_instagram')}
                  name="section_two_instagram"
                  label="Instagram"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
              <Grid item mt={4}>
                <TextField
                  {...register('section_two_linkedin')}
                  name="section_two_linkedin"
                  label="Linkedin"
                  fullWidth
                  onChange={(event) => handleChangeInput(event, 'main')}
                />
              </Grid>
            </Grid>

          </Grid>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            my={5}
          >
            <LoadingButton
              loading={loader ? true : false}
              size="medium"
              type="submit"
              loadingPosition="end"
              endIcon={<SendIcon />}
              variant="contained"
            >
              {loader ? 'Please Wait...' : 'save changes'}
            </LoadingButton>
          </Stack>
        </form>
      </Card>
    </>
  )
}
