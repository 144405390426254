import React from 'react';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageContainer from 'src/components/admin-ui/container';
import { CircularProgress, Typography } from "@mui/material";
//import { STATIC_PAGES } from 'src/app/config/endpoints';
import TabSections from './tabSection';
import { getRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";


function StaticPages() {
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
    const [isLoading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [aboutContent, setAboutContent] = useState();
    const [privacyContent, setPrivacyContent] = useState();
    const [resourcesContent, setResourcesContent] = useState();

    async function getDetails() {
        try {
            const { status, data: { data } } = await getRequest(GENERAL_SETTINGS.GET_PAGE_FEATURE);
            console.log("response ==> ", status, data);
            if (status === 200 && Object.keys(data).length > 0) {
                const {
                    ABOUT_US:{value:aboutUsData},
                    PRIVACY_POLICY:{value:privacyPolicyData},
                    RESOURCES:{value:resourcesData},
                } = data;
                setAboutContent(aboutUsData);
                setPrivacyContent(privacyPolicyData);
                setResourcesContent(resourcesData)
                setLoading(false);
            } else {
                console.warn("no data found!");
            }
            setLoading(false);
        } catch (error) {
            console.log("error ===> ", error)
            setLoading(false);
            setErrorMessage("Unable to process your request. Please try again.")
            createNotification("error", "", "Unable to process your request. Please try again.");
        }
    }

    useEffect(() => {
        getDetails();
    }, []);


    return (
        <>
            <Helmet>
                <link rel="apple-touch-icon" sizes="180x180" href="assets/icons/sas.png" />
                <title> {path.replace('-', ' ')} </title>
            </Helmet>
            <PageContainer className="page-container users-page" heading={path.replace('-', ' ')}>
                {isLoading &&
                    <div className="loading_main">
                        <div className="loading_inner">
                            <CircularProgress />
                        </div>
                    </div>
                }
                {errorMessage !== '' &&
                    <Typography variant="h6" sx={{ py: 5, px: 2, color: '#c92129' }}>
                        {errorMessage}
                    </Typography>
                }
                {!isLoading && errorMessage === '' &&
                    <>
                    <TabSections aboutContent={aboutContent} privacyContent={privacyContent} resourcesContent={resourcesContent} />
                    </>
                }
            </PageContainer>
        </>
    )
}

export default StaticPages
