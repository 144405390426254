import { Helmet } from "react-helmet-async";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { createNotification } from "../../common/createNotification";
import { checkTokenExpiry } from "../../utils/helpers/functions";

// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Iconify from "../../components/iconify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewsBulletin } from "src/redux/Slices/NewsSlice";
import { useNavigate } from "react-router-dom";
import { createNewsBulletin } from "src/api/newsBulletinApi";

function CreateBulletin() {
  //CONSTANTS
  const formTop = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    bulletin: Yup.string().required("News Bulletin is required"),
  });
  const { id } = JSON.parse(localStorage.getItem("user"));
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  //STATES
  const [loading, setloading] = useState(false);

  //FUNCTIONS
  const onSubmitForm = async (data) => {
    setloading(true);
    const handleRouteTo = (path) => {
      navigate(path);
    };
    const item = {
      name: data.bulletin,
      status: +data.status,
      updated_by_id: id,
    };
    try {
      const bulletin = await createNewsBulletin(item);
      dispatch(addNewsBulletin(bulletin.data));
      handleRouteTo("/dashboard/news-bulletin");
      createNotification(
        "success",
        "Created",
        "News Bulletin created Successfully"
      );
    } catch (error) { checkTokenExpiry(error.response.data.msg) 
      setloading(false);
      createNotification(
        "error",
        "Error",
        `error while creating news bulletin`
      );
    }
  };
  return (
    <>
      <Helmet>
        <title> News </title>
      </Helmet>

      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
           
            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Container  maxWidth="xl" ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard/news-bulletin/"
          >
            Bulletin
          </Link>
          <Typography color="text.primary">Create News bulletin</Typography>
        </Breadcrumbs>

        <Card>
          <CardHeader title="Create News bulletin" />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <Grid item xs={12} md={8} sx={{ pt: 5, pl: 10, pr: 10, pb: 20 }}>
              <Stack pb={4}>
                <div>
                  <TextField
                    {...register("bulletin", { required: true })}
                    error={errors.bulletin}
                    helperText={
                      errors.bulletin ? errors.bulletin.message : null
                    }
                    id="news-title"
                    label="News Bulletin"
                    name="bulletin"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </Stack>
              <Stack pb={4}>
                <FormControlLabel
                  control={
                    <Switch
                      name="status"
                      {...register("status")}
                      color="success"
                    />
                  }
                  label="Status"
                />
              </Stack>

              <Stack pb={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Iconify icon="iconoir:submit-document" />}
                >
                  SUBMIT
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default CreateBulletin;
