import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { checkTokenExpiry } from "../utils/helpers/functions";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Box,
  Input,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Avatar from '@mui/material/Avatar';

import SendIcon from '@mui/icons-material/Send';

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Form } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./style.css";
import { deleteProfileImage, getUser, updateUser } from "src/api/api";
import { image } from "src/constants/api";
import { createNotification } from "src/common/createNotification";
import { setUser } from "src/redux/Slices/UserSlice";
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object().shape({
  name: yup.string().trim()
      .required("Name is required")
      .matches(
        /^[a-zA-Z ]*$/,
          'Name must contain only letters.'
      )
      .max(30, `Name can't be more then 30 charachters.`)
});

const EditProfile = () => {
  //STATES
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState();
  const [data, setData] = useState({});
  const [fields, setFields] = useState({
    name: "",
    email: "",
    profile_image: "",
  });
  const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
  const {user:stateUser} = useSelector(state=>state.user);
  //CONSTANTS----------------------------------------------------------
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  //FUNCTIONS--------------------------------------------------------------
  const fetchSingleUser = async () => {
    setLoading(true);
    try {
      const user = await getUser(id);
      setData(user.data.data);
      setLoading(false);
      setValue('name', user.data.data.name);

    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      createNotification("error", "Update", error.response.data.msg);
    }
  };
  const formSubmit = async (e) => {
    try {
      // setLoading(true);
      dispatch(setRequestInProgress());
      const formData = new FormData();
      formData.append("user_id", id);
      formData.append("name", e.name);
      if (file) {
        formData.append("image", file);
      }
        
      const { status, data: { data, msg } } = await updateUser(formData);
      
      if (status === 200) {
        createNotification("success", "Update", "Profile update successfully" );
        dispatch(setRequestSuccess("Profile update successfully"));
        dispatch(setUser({
            ...stateUser, 
            name:data.name,
            profile_image:data.profile_image
        }));
        navigate(`/dashboard/profile`);
      } else {
        dispatch(setRequestFailure("Getting error while updating profile. Please try  again."));
        createNotification("error", "Update", "Getting error while updating profile. Please try  again");
      }
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      createNotification("error", "Update", error.response.data.msg);
      dispatch(setRequestFailure(error.response.data.msg));
    }
  };
  const handleFileChange = (e) => {
    setLoading(true);
    setFile(e.target.files[0]);

    setLoading(false);
  };

  const removeProfilePic = async () => {
    try {
      dispatch(setRequestInProgress());
      const { status, data: { msg } } = await deleteProfileImage(id);
      if (status === 200) {
        setFields({ ...fields, profile_image: "" });
        dispatch(setRequestSuccess(msg));
        createNotification("success", "Update", msg);
        dispatch(setUser({...stateUser, profile_image:null}));
      } else {
        dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        createNotification("error", "Update", "Getting error while removing profile picture. Please try  again.");
      }
    } catch (error) {
      const { response: { data: { msg } } } = error;
      dispatch(setRequestFailure(msg));
      createNotification("error", "Update", msg);
    }
  }

  //USE EFFECTS---------------------------------------------------------
  useEffect(() => {
    fetchSingleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      data.profile_image
        ? setFields({
          ...data,
          profile_image: `${image}/${data.profile_image}`,
        })
        : setFields({
          ...data,
        });
    }
  }, [data]);

  return (
    <>
      {loading && (
        <Box
          onClick={() => setLoading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "100%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",

            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Card style={{ padding: 20, margin: 30 }} sx={{ minWidth: 500 }}>
        <form onSubmit={handleSubmit(formSubmit)} noValidate>
          <Grid container spacing={3} alignItems="start">
            <Grid item xs={6} mb={1}>
              <Button
                type="button"
                variant="contained"
                size="medium"
                startIcon={<NavigateBeforeIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" component="h1" mb={2}>
                Admin Profile
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* <FormLabel>Name</FormLabel> */}
              <TextField
                fullWidth
                {...register("name")}
                name="name"
                // label="Name"
                // onChange={handleInputChange}
                defaultValue={stateUser.name}
                error={!!errors.name}
              />
              {errors.name && (
                <p className="text-danger">{errors.name.message}</p>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                value={fields.email}
                disabled
              />
            </Grid>

            <Grid item xs={12} className="img-upload">
              {fields?.profile_image !== null && fields?.profile_image !== "" ? (
                <div className="profileimage_edit sada">
                   <Grid 
                        container
                        alignItems="flex-start"
                        sx={{position:'relative'}}
                    >
                      <img
                          src={fields.profile_image}
                          style={{ 
                            height: "180px", 
                            width: "180px" ,
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          alt="profile"
                        />
                         <IconButton
                          aria-label="delete"
                          size="small"
                          color="primary"
                          onClick={removeProfilePic}
                          disabled={loader}
                        >
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                  </Grid>
                </div>
              ) : (
                <>
                  {file ? (
                    <>
                      <Grid 
                        container
                        alignItems="flex-start"
                        sx={{position:'relative'}}>
                        <img
                          src={URL.createObjectURL(file)}
                          style={{ 
                            height: "180px", 
                            width: "180px" ,
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          alt="profile"
                        />
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="primary"
                          // sx={{position:'absolute'}}
                        >
                          <DeleteIcon onClick={() => setFile("")} fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </>
                  ) : (
                    <>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      onChange={handleFileChange}
                      name="profile_image"
                      label="Profile Image"
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Avatar sx={{
                            mb:1,
                            cursor:'pointer',
                            bgcolor:'#181c208c',
                            width:'120px',
                            height:'120px',
                            '&:hover': {
                              bgcolor:'#181c20',
                            }
                          }}
                          >
                          <AddPhotoAlternateIcon sx={{fontSize:'3.5rem !important'}} />
                        </Avatar>
                        <FormLabel>Profile Picture</FormLabel>
                    </label>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={6} mt={2}>
              <LoadingButton
                loading={loader ? true : false}
                size="small"
                variant="contained"
                onClick={() => {
                  navigate(`/dashboard/changePassword/${user?.id}`);
                }}
              >
                Change Password
              </LoadingButton>
            </Grid>

            <Grid item xs={6} mt={2} className="d-flex justify-content-end">
              <LoadingButton
                loading={loader ? true : false}
                size="small"
                type="submit"
                loadingPosition="end"
                endIcon={<SendIcon />}
                variant="contained"
              >
                {loader ? 'Updating...' : 'Update'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Card>
    </>
  );
};
export default EditProfile;