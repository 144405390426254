import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { getRequest } from "src/app/httpClient/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import FormComponent from './FormComponent';
import { DEFAULT_URL } from "src/app/config/endpoints"
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import {
    Stack,
    Button,
    Container,
    Typography,
    Box,
    CircularProgress,
    Grid,
} from "@mui/material";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import { TableContainer, TableBody, Table, TableRow, TableHead, TableCell, Paper, IconButton } from "@mui/material";



const OrderDetail = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const [OrderDetails, SetOrderDetail] = useState([])

    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    async function getOrderList() {
        const _ENDPONIT = `${GENERAL_SETTINGS.GET_ORDERS_DETAILS}${id}`;
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT, id);
            const { status, data: { data } } = response;
            if (status === 200) {
                console.log("first", data)
                SetOrderDetail(data)
                dispatch(setRequestInit());
            } else { }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }

    // const orderAddress = OrderDetails && OrderDetails.orders[0].order_address;

    useEffect(() => {
        getOrderList()
    }, []);



    if (loader) {
        return <TableRow hover >
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover >
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (OrderDetails.length === 0) {
        return <TableRow hover >
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                </Paper>
            </TableCell>
        </TableRow>
    }



    return (
        <>{OrderDetails &&
            <Grid container spacing={2}>
                <Grid item xs={8} sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order #{OrderDetails.invoice_no} details
                    </Typography>

                    <Grid container spacing={2}  >

                        <Grid item xs={4}>
                            <Box component="section" sx={{ p: 2 }}>

                                <Typography variant="subtitle2" component="h2">
                                    Order Place Date
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {new Date(OrderDetails.createdAt).toDateString()}
                                </Typography>


                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={1}
                                >
                                    <Typography variant="subtitle2" component="h2">
                                        Status
                                    </Typography>

                                    <Typography variant="body1" component="h2">
                                        {OrderDetails.status === 0 ? <span style={{ backgroundColor: '#ff851b', padding: "4px", fontSize: '8px', color: "#fff" }}>Pending</span> :
                                            OrderDetails.status === -1 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px", fontSize: '8px', color: "#fff" }} > Cancelled</span> :
                                                OrderDetails.status === 1 ? <span style={{ backgroundColor: '#d9edf7', padding: "4px", fontSize: '8px', color: "#fff" }}> Processing</span> :
                                                    OrderDetails.status === 2 ? <span style={{ backgroundColor: '#00a65a ', padding: "4px", fontSize: '8px', color: "#fff" }}> Dispatched</span> :
                                                        <span style={{ backgroundColor: '#00a65a ', padding: "4px", fontSize: '8px', color: "#fff" }}>Completed</span>}
                                    </Typography>

                                </Stack>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={1}
                                >
                                    <Typography variant="subtitle2" component="h2">
                                        Payment Status
                                    </Typography>

                                    <Typography variant="body1" component="h2">
                                        {OrderDetails.payment_status === 0 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>failed</span> : <span style={{ backgroundColor: '#00a65a ', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>success</span>}
                                    </Typography>

                                </Stack>

                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box component="section" sx={{ p: 2 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Billing Address
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_first_name}
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_last_name}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_address_line_1}
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_address_line_2}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_city} ,
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_state}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_country},
                                    {OrderDetails?.orders?.[0]?.shipping?.billing_zip}
                                </Typography>
                            </Box>
                            <Box component="section" sx={{ paddingX: 2, paddingY: 0 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Email Address :
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.user?.email}
                                </Typography>
                            </Box>
                            <Box component="section" sx={{ paddingX: 2, paddingY: 0 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Phone :
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.user?.phone}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box component="section" sx={{ p: 2 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Shipping Address
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_first_name}
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_last_name}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_address_line_1}
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_address_line_2}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_city} ,
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_state}, <br />
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_country},
                                    {OrderDetails?.orders?.[0]?.shipping?.shipping_zip}
                                </Typography>
                            </Box>

                            <Box component="section" sx={{ paddingX: 2, paddingY: 0 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Email Address :
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.user?.email}
                                </Typography>
                            </Box>
                            <Box component="section" sx={{ paddingX: 2, paddingY: 0 }}>
                                <Typography variant="subtitle2" component="h2">
                                    Phone :
                                </Typography>
                                <Typography variant="body2" component="h2">
                                    {OrderDetails?.orders?.[0]?.user?.phone}
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>


                </Grid>
                {/*<Grid item xs={4}>
                    <Box sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', paddingX: 2, paddingY: 0 }}>
                        <FormComponent />
                    </Box>
                </Grid> */}


                <Grid item xs={12} sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', p: 2 }}>

                    <Typography variant="subtitle1" gutterBottom>
                        Product
                    </Typography>

                    <Scrollbar>
                        <TableContainer
                            component={Paper}

                        >
                            <Table>
                                <NewsListHead

                                    headLabel={TABLE_HEAD}
                                // rowCount={10}
                                // numSelected={1}
                                />
                                <TableBody >






                                    {OrderDetails &&

                                        OrderDetails?.orders?.map((item, index) => {

                                            return (
                                                <TableRow hover tabIndex={-1} role="checkbox">
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>

                                                            <img
                                                                src={
                                                                    item?.product_img
                                                                        ? `${DEFAULT_URL.IMAGE}/product/${item.product_img}`
                                                                        : "./assets/images/web-logo.png"
                                                                }
                                                                style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                                                alt=""
                                                            />

                                                            <Typography className="category word-wrap">

                                                                {item?.product_name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography className="category word-wrap">
                                                                {item?.quantity}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography className="category word-wrap">
                                                                $ {item?.product_amt}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography className="category word-wrap">
                                                                $ {item?.total_price}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Typography className="category word-wrap" style={{ color: 'white', fontSize: '8px', width: 'max-content' }} >
                                                                {item.status === 0 ? <span style={{ backgroundColor: '#ff851b', padding: "4px" }}>Pending</span> :
                                                                    item.status === -1 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px" }} > Cancelled</span> :
                                                                        item.status === 1 ? <span style={{ backgroundColor: '#d9edf7', padding: "4px" }}> Processing</span> :
                                                                            item.status === 2 ? <span style={{ backgroundColor: '#00a65a ', padding: "4px" }}> Dispatched</span> :
                                                                                <span style={{ backgroundColor: '#00a65a ', padding: "4px" }}>Completed</span>}

                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>

                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ minWidth: '800px' }}></TableCell>
                                        <TableCell colSpan={2} ></TableCell>
                                        <TableCell >Shipping</TableCell>
                                        <TableCell >Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ minWidth: '800px' }}></TableCell>
                                        <TableCell colSpan={2} ></TableCell>
                                        <TableCell>$ {OrderDetails?.orders?.[0]?.ship_amt}</TableCell>
                                        <TableCell>$ {OrderDetails?.orders?.[0]?.order_total}</TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>


                        </TableContainer>




                    </Scrollbar>

                </Grid>

            </Grid>
        }




        </>
    );
};

export default OrderDetail;


const TABLE_HEAD = [
    { id: "Item", className: '', label: "Item", align: '' },
    { id: "Quantity", className: '', label: "Quantity", align: '' },
    { id: "Cost", className: '', label: "Cost", align: '' },
    { id: "Total Price", className: '', label: "Total Price", align: '' },
    { id: "Status", className: '', label: "Status", align: '' },
];