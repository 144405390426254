import { React, useState } from "react";
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AboutUs from './AboutUs';
import Privacypolicy from "./PrivacyPolicy";
import Resources from "./Resources";


function TabSection(props) {
    const {aboutContent,privacyContent, resourcesContent} = props;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //const [value, setValue] = useState('headerTab');
    const [value, setValue] = useState('headerTab')
    return (
        <>
            <Box sx={{ width: '100%', my: 3 }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                        <TabList className='tablist' onChange={handleChange} sx={{ minHeight: '0px' }} aria-label="user details">
                            <Tab className='min-hight-0 tablist-option' icon={<RecentActorsIcon />} iconPosition="start" label="About Us" value="headerTab" />

                            <Tab className='min-hight-0 tablist-option' icon={<RecentActorsIcon />} iconPosition="start" label="Privacy Policy" value="privacyTab" />

                            <Tab className='min-hight-0 tablist-option' icon={<RecentActorsIcon />} iconPosition="start" label="Resources" value="resourcesTab" />
                        </TabList>
                    </Box>
                    <TabPanel sx={{ px: 0, py: 4 }} value="headerTab">
                        <AboutUs aboutData={aboutContent} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py: 4 }} value="privacyTab">
                        <Privacypolicy privacyData={privacyContent} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py: 4 }} value="resourcesTab">
                        <Resources resourcesData={resourcesContent} />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    )
}

export default TabSection;