import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import AlertDialog from "../../common/AlertDialog";
import { createNotification } from "../../common/createNotification";
import { SwitchComponent } from "../../components/Switch";
import { checkTokenExpiry } from "../../utils/helpers/functions";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { getRequest } from "src/app/httpClient/axiosClient";

// @mui
import {
  Box,
  Card,
  Paper,
  Stack,
  Table,
  Button,
  TableRow,
  TableCell,
  Container,
  TableBody,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
// components
import Iconify from "../../components/iconify";
import Scrollbar from "../../components/scrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// sections
import { NewsListHead, NewsListToolbar } from "../../sections/@dashboard/news";
// mock
// seed
import { NEWSBULLETINLIST } from "src/utils/seeds/newsList";
import { useDispatch, useSelector } from "react-redux";
import { setNewsBulletin } from "src/redux/Slices/NewsSlice";
import { useEffect } from "react";
import {
  deleteNewsBulletin,
  getNewsBulletin,
  updateNewsBulletin,
} from "src/api/newsBulletinApi";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "no", className: 'no news_bulletin_srno', label: "No", align: 'left' },
  { id: "name", className: 'cu_first_name', label: "Name", align: 'left' },
  { id: "show_menu", className:'show_menu', label: "Show Menu",  align:'center'},
  { id: "options", className: 'options', label: "Options", align: 'center' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function NewsBulletin() {
  //STATES-----------------------------------------------------------------
  const [errors, seterrors] = useState("");
  const [modalShow, setmodalShow] = useState(false);
  const [page, setPage] = useState(0);
  const [order] = useState("asc");
  const [selected] = useState([]);
  const [orderBy] = useState("name");
  const [newsId, setnewsId] = useState("");
  const [filterName, setFilterName] = useState("");
  const [loading, setloading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredNewsBulletinLists, setfilteredNewsBulletinLists] = useState(
    []
  );

  //CONSTANTS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "lg");
  const user = JSON.parse(localStorage.getItem("user"));
  const { NAV_WIDTH } = useSelector((state) => state.news);
  const { newsBulletin } = useSelector((state) => state.news);
  const isNotFound = !filteredNewsBulletinLists.length && !!filterName;
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - NEWSBULLETINLIST.length)
      : 0;

  //USE EFFECTS
  useEffect(() => {
    getBulletin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setfilteredNewsBulletinLists(
      applySortFilter(newsBulletin, getComparator(order, orderBy), filterName)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsBulletin, filterName]);

  //FUNCTIONS---------------------------------------------------------
  const getBulletin = async () => {
    setloading(true);
    try {
      const data = await getNewsBulletin();
      dispatch(setNewsBulletin(data.data));
      seterrors("");
      setloading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      seterrors(error.response.data.msg);
      setloading(false);
    }
  };
  const handleDelete = async (id) => {
    // eslint-disable-next-line no-unused-vars
    setloading(true);
    try {
      await deleteNewsBulletin(id).then((res) => {
        if (res.status == 204) {
          createNotification(
            "error",
            "cannot delete this bulletin"
          );
        } else {
          createNotification(
            "success",
            "Deleted  ",
            "News-Bulletin deleted successfully"
          );
        }
        getBulletin();
      })

      setloading(false);

    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      createNotification("error", "Error  ", "Error deleting News-Bulletin ");
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleRouteTo = (path) => {
    navigate(path);
  };
  const handlePublished = async (e, id) => {
    setloading(true);
    const item = {
      id: id,
      status: +e,
      update_by_id: user.id,
    };
    try {
      await updateNewsBulletin(item);
      createNotification(
        "success",
        "Updated ",
        "News-Bulletin updated successfully"
      );
      getBulletin();
      setloading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      setloading(false);
      createNotification(
        "error",
        "Error",
        "Error publisjishing news-bulleting "
      );
    }
  };

  const handleMenuPublished = async (STATUS, ID) => {
    const _STATUS = STATUS ? 1 : 0
    const _ENDPONIT = `${GENERAL_SETTINGS.UPDATE_NEWS_BULLETIN_MENU}?id=${ID}&show_menu=${_STATUS}`;
    try {
      setloading(true)
      const { status, data: { data } } = await getRequest(_ENDPONIT);
      console.log({ status, data });
      setloading(false);
      createNotification("success", "Update", "Menu updated successfully.");
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      setloading(false);
      createNotification("error", "Update", "Getting error while updating status. Please try  again.");
    }
  };


  return (
    <>
      <Helmet>
        <title> News Bulletin </title>
      </Helmet>

      <Container  maxWidth="xl">
        {loading && (
          <Box
            onClick={() => setloading(false)}
            sx={{
              display: "flex",
              position: "fixed",
              height: "100%",
              width: isDesktop ? `calc(100% - ${NAV_WIDTH + 1}px)` : "100%",
              bottom: "0",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",

              zIndex: "1000",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            News
          </Typography>
          {/* <Button
            onClick={() => handleRouteTo("create-bulletin")}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            News bulletin
          </Button> */}
        </Stack>

        <Card>
          <NewsListToolbar
            errors={errors}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <NewsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={NEWSBULLETINLIST.length}
                  numSelected={selected.length}
                  sx={{
                    color: "#fff",
                  }}
                />
                <TableBody>
                  {filteredNewsBulletinLists
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, id) => {
                      const { name, status, show_menu } = row;
                      const publish = !!+status;
                      const publish_menu = !!+show_menu;
                      const selectedUser = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell component="th" scope="row" align="left">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              justifyContent="left"
                            >
                              <Typography
                                className="news_bulletin_srno"
                                variant="subtitle2"
                                sx={{
                                  textAlign: "left",
                                }}
                              >
                                {page !== 0
                                  ? id + 1 * page * rowsPerPage + 1
                                  : id + 1}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="word-wrap cu_first_name" align="left" component="th">
                            {name}
                          </TableCell>
                          {/* <TableCell align="center">
                            <SwitchComponent
                              data={publish}
                              handlePublished={handlePublished}
                              id={row.id}
                              isOnClickTrigger={false}
                            />
                          </TableCell> */}

                          <TableCell align="center">
                            <SwitchComponent
                              data={show_menu}
                              handlePublished={handleMenuPublished}
                              id={row.id}
                              isOnClickTrigger={true}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Button
                                size="small"
                                variant="contained"
                                startIcon={<EditIcon />}
                                onClick={() => handleRouteTo(`${row.id}`)}
                              >
                                Edit
                              </Button>
                              {/* <Button
                                size="small"
                                variant="contained"
                                className="alertStyle"
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  setnewsId(row.id);
                                  setmodalShow(true);
                                }}
                              >
                                Delete
                              </Button> */}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{ textAlign: "center" }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            className="tbl-pagination"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredNewsBulletinLists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <AlertDialog
        open={modalShow}
        setOpen={setmodalShow}
        deletefunction={() => handleDelete(newsId)}
      />
    </>
  );
}

export default NewsBulletin;
