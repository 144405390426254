import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Popover, MenuItem, TableBody, TableContainer, Typography } from "@mui/material";
import AlertDialog from "../../../common/AlertDialog";
import Paper from "@mui/material/Paper";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import useSearchDebounce from 'src/utils/hooks/textDebounce';
import { checkTokenExpiry } from "../../../utils/helpers/functions";
import { createNotification } from "src/common/createNotification";

import { deleteRequest, getRequest, postRequest } from "src/app/httpClient/axiosClient";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';


import AttributeValueItem from "./attributevalueitem";

function AttributeValueListing(props) {
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const { attribute_id } = useParams();
    const { setloading } = props;
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const order = "asc";
    const orderBy = "name";

    const [pageNo, setPageNo] = useState(0);
    const [progressLoading, setProgressLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();
    const [attributeValueList, setAttributeValueList] = useState([]);
    const [attribute, setAttribute] = useState([]);
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    const [open, setOpen] = useState(null);
    const [hoveredNewsId, setHoveredNewsId] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [srNo, setSrNo] = useState(1);

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setHoveredNewsId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        // setHoveredNewsId(null);
    };

    const handleRouteTo = (path) => {
        navigate(`/e-commerce/attributevalue/${path}`);
    };

    const handleClickOpen = () => {
        setModalShow(true);
    };

    const handlePublished = async (STATUS, ID) => {
        const _STATUS = STATUS ? 1 : 0
        const _ENDPONIT = `${GENERAL_SETTINGS.UPDATE_ATTRIBUTE_VALUE_STATUS}?id=${ID}&status=${_STATUS}`;
        try {
            setloading(true)
            const { status, data: { data } } = await getRequest(_ENDPONIT);
            console.log({ status, data });
            setloading(false);
            createNotification("success", "Update", "Status updated successfully.");
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while updating status. Please try  again.");
        }
    };

    const deleteAttributeValueById = async () => {
        try {
            setOpen(null);
            setloading(true);
            const { status, data: { data } } = await deleteRequest(GENERAL_SETTINGS.DELETE_ATTRIBUTE_VALUE.replace('{ID}', hoveredNewsId));
            //console.log({ status, data });
            setloading(false);
            createNotification("success", "Success", `Attribute Value was successfully deleted.`);
            getAttributeValueList();
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            createNotification("error", "Update", "Getting error while deleting attribute value. Please try  again.");
        }
    };

    async function getAttributeValueList() {
        const _ENDPONIT = `${GENERAL_SETTINGS.GET_ATTRIBUTE_VALUE}?attribute_id=${attribute_id}`;
        const _pageNo = pageNo + 1;
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT);
            //console.log(response);
            const { status, data: { data, attributeData } } = response;
            //console.log(attributeData);
            if (status === 200) {
                setAttributeValueList(data);
                setAttribute(attributeData);
                console.log("response ==> ", data);
                dispatch(setRequestInit());
            } else { }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try again."));
        }
    }

    useEffect(() => {
        getAttributeValueList();
    }, []);

    return <>
        <Typography variant="h4" gutterBottom>
            {attribute.name}
        </Typography>
        <Card>
            <Scrollbar>
                <TableContainer
                    component={Paper}
                    sx={{ minWidth: 800, borderRadius: "0px" }}
                >
                    <Table>
                        <NewsListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={10}
                            numSelected={1}
                        />
                        <TableBody>
                            <AttributeValueItem
                                loading={loader}
                                errorMessage={errorMessage}
                                successMessage={successMessage}
                                attributeValueList={attributeValueList}
                                srNo={srNo}
                                handleRouteTo={handleRouteTo}
                                handlePublished={handlePublished}
                                handleOpenMenu={handleOpenMenu}
                                searchTextDebounce={searchTextDebounce}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
            <MenuItem onClick={() => hoveredNewsId ? handleRouteTo(`${attribute_id}/${hoveredNewsId}`) : false}>
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />Edit
            </MenuItem>
            <MenuItem
                sx={{ color: "error.main" }}
                onClick={() => {
                    handleClickOpen();
                    handleCloseMenu();
                }}
            >
                <Iconify
                    icon={"eva:trash-2-outline"}
                    sx={{ mr: 2 }}
                />
                Delete
            </MenuItem>
        </Popover>

        <AlertDialog
            open={modalShow}
            setOpen={setModalShow}
            deletefunction={deleteAttributeValueById}
            moduleType={'attribute'}
        />
    </>
}

export default AttributeValueListing;
const TABLE_HEAD = [
    { id: "Name", className: '', label: "Name", align: '' },
    { id: "status", className: '', label: "Status", align: '' },
    { id: "options", className: '', label: "Options", align: 'right' },
];