import { useState } from "react";
import Card from "@mui/material/Card";
import { checkTokenExpiry } from "../utils/helpers/functions";

import {
  Button,
  Grid,
  Typography,
  Input,
  FormLabel,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Iconify from 'src/components/iconify';

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Form } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./style.css";
import { changePassword } from "src/api/api";
import { createNotification } from "../common/createNotification";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showOldPassword, setOldShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  //----------------------------------------------------------------
  const [fields, setFields] = useState({
    password: "",
    newPassword: "",
  });
  const [loading, setLoading] = useState();

  //----------------------------------------------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const formSubmit = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("user_id", id);
    formData.append("password", fields.password);
    formData.append("newPassword", fields.newPassword);
    try {
      await changePassword(formData);
      createNotification("success", "Update", "Password Changed Successfully");
      navigate(`/dashboard/profile`);
    } catch (error) { 
      checkTokenExpiry(error.response.data.message) 
      createNotification("error", "Update", error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Card style={{ padding: 20, margin: 30 }}>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <Grid container spacing={3} alignItems="start">
            <Grid item xs={12} mb={1}>
              <Button
                type="button"
                variant="contained"
                size="medium"
                startIcon={<NavigateBeforeIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h1" mb={2}>
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Old Password</FormLabel>
              <TextField
                fullWidth
                variant="standard"
                {...register("password")}
                name="password"
                type={showOldPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{paddingRight:'6px'}}>
                      <IconButton onClick={() => setOldShowPassword(!showOldPassword)} edge="end">
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className="text-danger">{errors?.password?.message}</p>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>New Password</FormLabel>
              <TextField
                variant="standard"
                fullWidth
                {...register("newPassword")}
                type={showNewPassword ? 'text' : 'password'}
                name="newPassword"
                onChange={handleInputChange}
                error={!!errors.newPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{paddingRight:'6px'}}>
                      <IconButton onClick={() => setNewShowPassword(!showNewPassword)} edge="end">
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className="text-danger">{errors?.newPassword?.message}</p>
            </Grid>

            <Grid item xs={12} mt={2}>
              {loading === true ? (
                <Button size="medium" disabled variant="contained">
                  <CircularProgress />
                </Button>
              ) : (
                <Button size="samll" type="submit" variant="contained">
                  Update Password
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      </Card>
    </>
  );
};
export default ChangePassword;

const schema = yup.object({
  password: yup.string().required("Old password is required"),
  newPassword: yup
  .string()
  .required("New password is required")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, `Password must contain at least 6 characters, one uppercase letter, one lowercase letter, one number and one special character.`)
});
