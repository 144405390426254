import React, { useEffect, useState } from 'react'
import { getUserList } from "src/api/api";
import UserListHead from "../../app/user/UserListHead";
import {
    Card,
    Table,
    Stack,
    Button,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    CircularProgress,
    FormControl,
    OutlinedInput,
    InputAdornment
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import useSearchDebounce from 'src/utils/hooks/textDebounce';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';






function UserListing() {


    const TABLE_HEAD = [
        { id: "name", className: 'cu_first_name', label: "Name", alignRight: false, },
        { id: "email", className: 'cu_email', label: "Email", alignRight: false },
        { id: "provider", className: 'cu_last_name', label: "Provider", alignRight: false }
    ];

    const [UserList, setUserList] = useState([]);
    const [counts, setCounts] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();


    const handleSerchInputChange = (event) => {
        const value = event.target.value === undefined ? '' : event.target.value;
        setSearchText(value);
        setSearchTextDebounce(value);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };

    const handleClearSearchInput = (event) => {
        const value = event.target.value === undefined ? '' : event.target.value;
        setSearchText(value);
        setSearchTextDebounce(value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

console.log("search",searchText)

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const res = await getUserList(rowsPerPage, page + 1,searchText);
            console.log("data user", res.data.data.pagination);
            setCounts(res.data.data.pagination.totalUsers);
            setUserList(res.data.data.result);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchUsers();
    }, [page, rowsPerPage,searchText]);


    return (


        <Container maxWidth="xl">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
            >
                <Typography variant="h4" gutterBottom>
                    Users
                </Typography>
            </Stack>
            <Card>
                <Stack
                 p={3}
                 direction="row"
                 >
                    <FormControl item fullWidth variant="outlined" md={4} xs={12}>
                        <OutlinedInput
                            onChange={handleSerchInputChange}
                            value={searchText}
                            sx={{ maxHeight: '40px' }}
                            placeholder="Search by Name, Email..."
                            id="outlined-adornment-weight"
                            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                            endAdornment={searchText.length > 0 ?
                                <InputAdornment position="end">
                                    <Button onClick={handleClearSearchInput} sx={{ color: "#bf2b3a" }} startIcon={<DeleteRoundedIcon />}>clear</Button>
                                </InputAdornment>
                                : false
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight',
                            }} />
                    </FormControl>
                </Stack>
                <Scrollbar><TableContainer sx={{ minWidth: 800 }}>
                    <Table id="myTable">
                        <UserListHead
                            headLabel={TABLE_HEAD}
                        />
                        <TableBody className="table-spacer">
                        {
    UserList.length > 0 ? (
        UserList.map((item, index) => (
            <TableRow hover key={index} tabIndex={-1} spacing={3}>
                <TableCell padding="checkbox">
                    <Typography className="word-wrap cu_first_name">
                        {item.name}
                    </Typography>
                </TableCell>
                <TableCell className="word-wrap cu_last_name" align="left">
                    <Typography className="word-wrap">
                        {item.email}
                    </Typography>
                </TableCell>
                <TableCell className="word-wrap cu_last_name" align="left">
                    <Typography className="word-wrap">
                        {item.provider === "W" ? "Website" :
                            item.provider === "X" ? "Twitter" :
                            item.provider === "G" ? "Google" :
                            item.provider === "F" ? "Facebook" :
                            null}
                    </Typography>
                </TableCell>
            </TableRow>
        ))
    ) : (
        <TableRow>
            <TableCell colSpan={3} align="center">
                <Typography>No data found</Typography>
            </TableCell>
        </TableRow>
    )
}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Scrollbar>
                {UserList.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={counts} // Total number of users
                        rowsPerPage={rowsPerPage} // Users per page
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Card>
        </Container>
    )
}

export default UserListing