import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
// mocks_
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { image } from "src/constants/api";
import { createNotification } from "src/common/createNotification";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const userDetail = useSelector((state) => state.user.user);

  const navigate = useNavigate();

  const handleRouteTo = (path) => {
    navigate(path);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem("user");
    window.location.reload();
    setOpen(null);
    createNotification("success", "Logout", "Logout Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>

      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={
            userDetail.profile_image && `${image}/${userDetail.profile_image}`
          }
          sx={{ background: "#c92129" }}
        ></Avatar>
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box
          sx={{ my: 1.5, px: 2.5 }}
          onClick={() => {
            handleRouteTo("profile");
            setOpen(false);
          }}
        >
          <Typography variant="subtitle2" sx={{ cursor: "pointer" }} noWrap>
            Profile
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
