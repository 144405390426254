import { image } from "src/constants/api";

import {
    Stack,
    TableRow,
    TableCell,
    Typography,
    IconButton,
    Paper,
    CircularProgress
} from "@mui/material";
import {
    get_thumbnail,
    matchYoutubeUrl,
} from "../../../utils/helpers/functions";

import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";

const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric" };

function PodcastItems(props) {
    const { loading, handlePublished, handleOpenMenu, errorMessage, newsList, searchTextDebounce, isDesktop, navWidth, srNo } = props;

    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (newsList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                    {
                        searchTextDebounce &&
                        <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{searchTextDebounce}&quot;</strong>.
                        </Typography>
                    }
                </Paper>
            </TableCell>
        </TableRow>
    }

    return <>
        {
            newsList.map((news, index) => {
                const { id, title, podcastLink, image_high, createdAt, updatedAt, published_date, publish_status } = news;
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell className="word-wrap" component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <img
                                    src={`${image_high
                                            ? `${image}/${image_high}`
                                            : matchYoutubeUrl(podcastLink)
                                                ? get_thumbnail(podcastLink)
                                                : "./assets/images/web-logo.png"
                                        }`}
                                    alt="thumbnail"
                                    style={{ width: "150px" }}
                                />
                                <Typography sx={{ maxWidth: 360, minWidth: 300 }}>
                                    {title.slice(0, 130)}{" "}
                                    {title.length > 130 && "..."}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell align="left">
                            Uploaded:{" "}
                            {new Date(createdAt).toLocaleDateString(
                                "en-US",
                                options
                            )}{" "}
                            <br />
                            Published:{" "}
                            {new Date(published_date).toLocaleDateString(
                                "en-US",
                                options
                            )}{" "}
                            <br />
                            Updated:{" "}
                            {new Date(updatedAt).toLocaleDateString(
                                "en-US",
                                options
                            )}
                        </TableCell>
                        <TableCell align="left">
                            <SwitchComponent
                                data={publish_status}
                                handlePublished={handlePublished}
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </>
}

export default PodcastItems;