// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import "./default.css";
import { useEffect } from "react";
import { getUser } from "./api/api";
import { setUser } from "./redux/Slices/UserSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkTokenExpiry } from "./utils/helpers/functions";

// ----------------------------------------------------------------------

export default function App() {
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const decodedJwt = parseJwt(user.token);
      setInterval(() => {
        if (decodedJwt.exp * 1000 < Date.now()) {
          localStorage.removeItem("user");
          window.location.reload();
        }
      }, 86400000);
    }
  }, []);

  const pathName = useLocation();
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem("user"));

  const fetchSingleUser = async () => {
    if (data) {
      try {
        const user = await getUser(data.id);
        dispatch(setUser(user.data.data));
      } catch (error) {
        checkTokenExpiry(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    fetchSingleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
