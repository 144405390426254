import axios from 'axios';
import { getToken } from '../Auth';

export function getRequest(URL) {
  let payload = {}, headers={}
  return apiRequest(URL, payload, "get", headers);
}

export function posttRequest(URL, payload) {
  // return axiosClient.post(`${URL}`, payload).then(response => response);
}

export function postRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "post", headers);
}

export function putRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "put", headers);
}
export function deleteRequest(URL, payload, headers = {}) {
  return apiRequest(URL, payload, "delete", headers);
}

export async function apiRequest(endPoint, data, method, headers, requestOptions = {}) {
  return new Promise(async (resolve, reject) => {
    const token = JSON.parse(localStorage.getItem("user")).token;
    const headers = {
      Accept: 'application/json',
    };
    
    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
    }

    if (token) {
      headers['Authorization'] = token;
    }

    axios({
      method: method,
      url: endPoint,
      headers:headers,
      data: data,
    }).then((result) => { return resolve(result); }).catch((error) => {return reject(error);});
    return false;
  });
}