import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  news: {},
  multipleNews: [],
  newsBulletin: [],
  archievedNews: [],
  podcast: [],
  NAV_WIDTH: 280,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    getNews: (state, action) => {
      state.news = action.payload;
    },
    removeNews: (state, action) => {
      state.multipleNews = state.multipleNews.filter(
        (item) => item.id !== action.payload
      );
    },
    updateNews: (state, { payload }) => {
      const index = state.multipleNews.findIndex((e) => e.id === state.news.id);

      state.multipleNews[index] = payload;
    },
    addNews: (state, { payload }) => {
      state.multipleNews = [payload, ...state.multipleNews];
    },
    setNewsBulletin: (state, { payload }) => {
      state.newsBulletin = payload;
    },
    addNewsBulletin: (state, { payload }) => {
      state.newsBulletin = [payload, ...state.newsBulletin];
    },
    removeNewsBulletin: (state, { payload }) => {
      state.newsBulletin = state.newsBulletin.filter(
        (item) => item.id !== payload
      );
    },
    updateNewsBulletin: (state, { payload }) => {
      // eslint-disable-next-line eqeqeq
      const index = state.newsBulletin.findIndex((e) => e.id == payload.id);
      state.newsBulletin[index] = payload;
    },
    setMultipleNews: (state, action) => {
      const news = action.payload.filter((e) => e.archived == 0);
      state.multipleNews = news;
    },
    setPodcast: (state, action) => {
      state.podcast = action.payload;
    },

    createMultipleNews: (state, action) => {
      state.multipleNews = [action.payload, ...state.multipleNews];
    },
    setarchievedNews: (state, action) => {
      state.archievedNews = action.payload;
    },
    setNAV_WIDTH: (state, action) => {
      state.NAV_WIDTH = action.payload;
    },
  },
});

export const {
  getNews,
  removeNews,
  updateNews,
  addNews,
  addNewsBulletin,
  removeNewsBulletin,
  updateNewsBulletin,
  setNewsBulletin,
  setMultipleNews,
  setPodcast,
  createMultipleNews,
  setarchievedNews,
  setNAV_WIDTH,
} = newsSlice.actions;
export default newsSlice.reducer;
