import { image } from "src/constants/api";
import { Stack, TableRow, TableCell, Paper, CircularProgress, Typography, IconButton, Tooltip, Box, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { SwitchComponent } from "../../../components/Switch";
import Iconify from "../../../components/iconify";
import { ASSETS_URL } from "src/app/config/endpoints";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { createNotification } from "src/common/createNotification";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { postRequest } from "src/app/httpClient/axiosClient";
import './Comment.css'
import { FilterDate } from "src/utils/helpers/functions";

const _key = (index) => {
    return `key-${index}-${Math.floor(Math.random() * 99999) + 1}`;
}

var options = { year: "numeric", month: "long", day: "numeric", hour: '2-digit',minute: '2-digit',hour12: true};


function CommentItem(props) {
    const { setloading } = props;

    const dispatch = useDispatch();
    const { loading, handlePublished, handleFeatured, handleOpenMenu, errorMessage, commentList, searchTextDebounce, isDesktop, navWidth, srNo, setCommentList } = props;
    const [editableCommentId, setEditableCommentId] = useState(null);
    const [editedComment, setEditedComment] = useState('');
    const [expandedIndex, setExpandedIndex] = useState(null);

    const handleEditClick = (id, comment) => {
        setEditableCommentId(id);
        setEditedComment(comment);
    };

    const handleSubmit = async (id) => {
        //console.log(`Updated Comment for ID ${id}: ${editedComment}`);

        const _ENDPONIT = `${GENERAL_SETTINGS.UPDATE_COMMENT.replace('{ID}', id)}`;
        try {
            dispatch(setRequestInProgress());
            const postData = { "content": editedComment };
            const { status, data: { data } } = await postRequest(_ENDPONIT, postData);
            if (status === 200) {
                setEditableCommentId(null);
                createNotification("success", "Success", `Comment was updated successfully deleted.`);
                dispatch(setRequestInit());


                setCommentList((commentList) =>
                    commentList.map((comment) =>
                        comment.id === id ? { ...comment, content: editedComment } : comment
                    )
                );

            } else { }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    };

    const handleCancel = () => {
        setEditableCommentId(null);
        setEditedComment('');
    };

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const MAX_LENGTH = 100;

    const textStyle = (isExpanded) => ({
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        padding: 0,
        margin: 0,
        overflow: 'hidden',
        WebkitLineClamp: isExpanded ? 'unset' : 2,
        maxHeight: isExpanded ? 'none' : '3em', // Adjust based on line height
        transition: 'max-height 0.3s ease',
    });

    if (loading) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <CircularProgress />
                <Typography variant="subtitle2" className="user-name" noWrap>
                    Please wait...
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (errorMessage) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Typography
                    sx={{ color: 'red' }}
                    variant="subtitle2" className="user-name" noWrap>
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    }

    if (commentList.length === 0) {
        return <TableRow hover key={_key(0)}>
            <TableCell align="center" variant="body1" colSpan={6}>
                <Paper sx={{ textAlign: "center", }}>
                    <Typography variant="h6" paragraph>
                        Data Not found
                    </Typography>
                </Paper>
            </TableCell>
        </TableRow>
    }
    return <>
        {
            commentList.map((comment, index) => {
                const { id, content, is_approved, post, user, parent_id, createdAt } = comment;
                const value = !parent_id ? 'C' : 'R';
                const tooltipTitle = !parent_id ? '' : comment.parent.content;
                const backgroundColor = value === 'C' ? 'primary.main' : '#747c47';
                return (
                    <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell component="th" scope="row" style={{ width: '35%' }}>
                            <Stack spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {editableCommentId === id ? (
                                        <>
                                            <TextField
                                                value={editedComment}
                                                onChange={(e) => setEditedComment(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                rows={2}
                                                multiline
                                                fullWidth
                                                style={{ marginBottom: '8px' }}
                                            />
                                            <Button variant="contained" color="primary" size="small" onClick={() => handleSubmit(id)} style={{ marginLeft: '8px' }}>
                                                Submit
                                            </Button>

                                            <IconButton size="small" color="secondary" onClick={handleCancel} style={{ marginLeft: '8px' }}>
                                                <Iconify icon={'lineicons:cross-circle'} />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <div className="divReadMore">
                                                <p style={textStyle(expandedIndex === index)}>
                                                    {content}
                                                </p>
                                                <IconButton size="small" color="primary" onClick={(event) => handleEditClick(id, content)}>
                                                    <Iconify icon={'akar-icons:pencil'} />
                                                </IconButton>

                                            </div>
                                            {(content.length > MAX_LENGTH) && (
                                                <span onClick={() => toggleExpand(index)} style={{ color: '#dd4b39', fontWeight: '600' }}>
                                                    {expandedIndex === index ? 'Read Less' : 'Read More'}
                                                </span>
                                            )}

                                        </>
                                    )}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {user.name}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {user.email}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {post.title}
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    <Tooltip title={value === 'R' ? tooltipTitle : ''} arrow placement="top">
                                        <Box
                                            sx={{
                                                backgroundColor: backgroundColor,
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: value === 'R' ? 'pointer' : 'default',
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                sx={{ color: 'white' }}
                                            >
                                                {value}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Typography>
                            </Stack>
                        </TableCell>

                        <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography className="attribute word-wrap">
                                    {new Date(FilterDate(createdAt)).toLocaleDateString(
                                        "en-US",
                                        options
                                    )}
                                </Typography>
                            </Stack>
                        </TableCell>



                        <TableCell align="left">
                            <SwitchComponent
                                data={is_approved}
                                handlePublished={handlePublished}
                                id={id}
                                isOnClickTrigger={true}
                            />
                        </TableCell>

                        <TableCell align="right">
                            <IconButton size="small" color="inherit" onClick={(event) => handleOpenMenu(event, id)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    </>
}

export default CommentItem;