
import SubjectIcon from "@mui/icons-material/Subject";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArchiveIcon from "@mui/icons-material/Archive";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import TableChartIcon from "@mui/icons-material/TableChart";
import ContactsIcon from "@mui/icons-material/Contacts";
import GroupIcon from "@mui/icons-material/Group";
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CommentIcon from '@mui/icons-material/Comment';
import DiscountIcon from '@mui/icons-material/Discount';
// ----------------------------------------------------------------------

const subMenuSx = { bg: "#637381", height: "17px" };
const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: <TableChartIcon />,
  },

  
  {
    title: "Pages",
    path: "/dashboard/static-pages",
    icon: <AutoStoriesIcon />,
  },


  {
    title: "E-commerce",
    icon: <AddShoppingCartIcon />,
    hidden: true,
    path: "/e-commerce/category",
    items: [
      {
        title: "Category",
        path: "/e-commerce/category",
        icon: <WidgetsIcon sx={subMenuSx} />,
      },
      // {
      //   title: "Discount",
      //   path: "/e-commerce/discount",
      //   icon: <DiscountIcon sx={subMenuSx} />,
      // },
      {
        title: "Attributes",
        path: "/e-commerce/attribute",
        icon: <AutoFixHighIcon sx={subMenuSx} />,
      },
      {
        title: "Add Product",
        path: "/e-commerce/product/add_parent",
        icon: <ControlPointDuplicateIcon sx={subMenuSx} />,
      },
      {
        title: "Product Listing",
        path: "/e-commerce/product/listing",
        icon: <ChecklistIcon sx={subMenuSx} />,
      },
      {
        title: "Orders",
        path: "/e-commerce/orders",
        icon: <AnalyticsIcon sx={subMenuSx} />,
      }

    ],
  },





  {
    title: "News",
    icon: <NewspaperIcon />,
    hidden: true,
    path: "/dashboard/news",
    items: [
      {
        title: "All News",
        path: "/dashboard/news",
        icon: <SubjectIcon sx={subMenuSx} />,
      },
      {
        title: "News Bulletin",
        path: "/dashboard/news-bulletin",
        icon: <FormatListBulletedIcon sx={subMenuSx} />,
      },
      {
        title: "Archived News",
        path: "/dashboard/archieved-news",
        icon: <ArchiveIcon sx={subMenuSx} />,
      },
      {
        title: "Comments",
        path: "/dashboard/news-comments",
        icon: <CommentIcon sx={subMenuSx} />,
      },
      {
        title: "Podcast",
        path: "/dashboard/news-podcast",
        icon: <PodcastsIcon sx={subMenuSx} />,
      },
      
    ],
  },


  {
    title: "Contacts us",
    path: "/dashboard/contacts",
    icon: <ContactsIcon />,
  },
  {
    title: "Subscribers",
    path: "/dashboard/subscribers",
    icon: <GroupIcon />,
  },
  {
    title: "General settings",
    path: "/dashboard/general-settings",
    icon: <SettingsRoundedIcon />,
  },
  {
    title: "Sponsors",
    path: "/dashboard/sponsors",
    icon: <CorporateFareRoundedIcon />,
  },
  {
    title: "Authors",
    path: "/dashboard/author",
    icon: <CorporateFareRoundedIcon />,
  },
  {
    title: "Users",
    path: "/dashboard/users",
    icon: <CorporateFareRoundedIcon />,
  },
  /*
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  },
  */
];

export default navConfig;
