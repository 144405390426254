import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";

import {
    Card, Table, Stack, Button, Popover, MenuItem, TableBody, TableContainer, TablePagination, OutlinedInput, InputAdornment, Tooltip, FormControl
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import { NewsListHead } from "../../../sections/@dashboard/news";
import { GENERAL_SETTINGS } from 'src/app/config/endpoints';
import { getRequest } from "src/app/httpClient/axiosClient";
import { useDispatch, useSelector } from "react-redux";

import OrderItem from "./OrderItem"

function OrderListing() {

    const [orderList, setOrderList] = useState([]);
    const [totalNewsCount, setTotalNewsCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [pageNo, setPageNo] = useState(0);
    const dispatch = useDispatch();



    const handleChangeRowsPerPage = (event) => {
        setPageNo(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPageNo(newPage);
        
    };


    

    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    async function getOrderList() {
        const _pageNo = pageNo + 1;

        const _ENDPONIT = `${GENERAL_SETTINGS.GET_ORDERS_LIST}?page=${_pageNo}&limit=${rowsPerPage}`;

        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(_ENDPONIT);
        
            const { status, data: { data, metaData } } = response;
            if (status === 200) {
                setOrderList(data.rows);
                // console.log("responsemeta ==> ", metaData.count);
                setTotalNewsCount(metaData.count)
                dispatch(setRequestInit());
            } else { }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }



    useEffect(() => {
        getOrderList();
    }, [pageNo, rowsPerPage]);


    return (
        <>
            <Card>
                <Scrollbar>
                    <TableContainer
                        component={Paper}
                        sx={{ minWidth: 800, borderRadius: "0px" }}
                    >
                        <Table>
                            <NewsListHead

                                headLabel={TABLE_HEAD}
                                rowCount={10}
                                numSelected={1}
                            />
                            <TableBody>

                                <OrderItem
                                    loading={loader}
                                    errorMessage={errorMessage}
                                    successMessage={successMessage}
                                    orderList={orderList}
                                // srNo={srNo}
                                // handleRouteTo={handleRouteTo}
                                // handlePublished={handlePublished}
                                // handleFeatured={handleFeatured}
                                // handleOpenMenu={handleOpenMenu}
                                // searchTextDebounce={searchTextDebounce}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {!errorMessage && orderList.length > 0 &&
                        <TablePagination
                            rowsPerPageOptions={orderList.length > 0 ? [5, 10, 25, 50, 100] : false}
                            component="div"
                            count={totalNewsCount}
                            rowsPerPage={rowsPerPage}
                            page={pageNo}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        // progressLoading={progressLoading}
                        />
                    }
                </Scrollbar>
            </Card>



        </>
    )
}
export default OrderListing

const TABLE_HEAD = [
    { id: "Sr.No.", className: '', label: "Sr.No.", align: '' },
    { id: "User", className: '', label: "User", align: '' },
    { id: "Invoice No.", className: '', label: "Invoice No.", align: '' },
    { id: "Shipping To", className: '', label: "Shipping To", align: '' },
    { id: "Product Total", className: '', label: "Product Total", align: '' },
    { id: "Shipping Amount", className: '', label: "Shipping Amount", align: '' },
    { id: "Order Total Amount", className: '', label: "Order Total Amount", align: '' },
    // { id: "Payment Method", className: '', label: "Payment Method", align: '' },
    { id: "Payment Status", className: '', label: "Payment Status", align: '' },
    { id: "Status", className: '', label: "Status", align: '' },
    { id: "Action", className: '', label: "Action", align: '' },
    { id: "view", className: '', label: "view", align: '' },
];