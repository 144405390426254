import { Helmet } from "react-helmet-async";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  Grid,
  Container,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Iconify from "../../components/iconify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef, useState } from "react";

function CreateUser() {
  const [roles] = useState([
    { id: 1, name: "Reporter" },
    { id: 2, name: "Editor" },
  ]);

  const [itemData, setitemData] = useState("");

  const formTop = useRef();

  const [loading, setloading] = useState(false);

  const handleFile = (e) => {
    const image = e.target.files[0];
    if (!image) {
      return false;
    }
    register("picture");
    if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|avif)$/)) {
      return false;
    }
    setitemData(URL.createObjectURL(e.target.files[0]));
    return e.target.files[0];
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmitForm = async (data) => {
    setloading(true);
  };

  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",

            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Container ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/dashboard/user/"
          >
            Users
          </Link>
          <Typography color="text.primary">Create New User</Typography>
        </Breadcrumbs>

        <Card>
          <CardHeader title="Create New User" />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <Grid item xs={12} md={8} sx={{ pt: 5, pl: 10, pr: 10, pb: 20 }}>
              <Stack pb={4}>
                <div>
                  <TextField
                    {...register("name", { required: true })}
                    error={errors.name}
                    helperText={errors.name ? errors.name.message : null}
                    id="name"
                    label="Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </Stack>
              <Stack pb={4}>
                <div>
                  <FormControl
                    error={errors.role?.message}
                    name="bulletin"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Role
                    </InputLabel>
                    <Select
                      id="role"
                      name="role"
                      {...register("role", {
                        required: true,
                      })}
                      label="Roles"
                    >
                      {roles.map((index, i) => {
                        const { id, name } = index;
                        return (
                          <MenuItem key={id} value={i}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {errors.role?.message ? errors.role?.message : false}
                    </FormHelperText>
                  </FormControl>
                </div>
              </Stack>

              <Stack pb={4} style={{ width: "30%" }}>
                <div
                  style={{
                    border: "1px solid #c1c8d0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    postition: "relative",
                  }}
                >
                  {itemData ? (
                    <>
                     
                      <img
                        src={itemData}
                        style={{
                          height: "190px",
                          marginBottom: "10px",
                          width: "190px",
                        }}
                        alt="something"
                        loading="lazy"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src="http://demo.activeitzone.com/newspaper/uploads/others/default_image.png"
                        style={{
                          height: "190px",
                          marginBottom: "10px",
                          width: "190px",
                          border: "5px solid #e5e5e5",
                        }}
                        alt=""
                      />
                      <span style={{ color: "#8d9299", fontSize: "12px" }}>
                        Only jpg, jpeg, gif and png files are supported
                      </span>
                    </>
                  )}

                  <Button
                    variant="contained"
                    component="label"
                    style={{ width: "100%" }}
                  >
                    Upload image
                    <input
                      id="picture"
                      {...register("picture", {
                        onChange: handleFile,
                      })}
                      type="file"
                      error={errors.picture}
                      hidden
                    />
                  </Button>
                </div>
                {errors.picture && (
                  <span style={{ fontSize: "12px", color: "#D61E27" }}>
                    {errors.picture?.message}
                  </span>
                )}
              </Stack>
              <Stack pb={4}>
                <FormControlLabel
                  control={
                    <Switch
                      name="verified"
                      {...register("verified")}
                      color="success"
                    />
                  }
                  label="Verified"
                />
              </Stack>
              <Stack pb={4}>
                <FormControlLabel
                  control={
                    <Switch
                      name="status"
                      {...register("status")}
                      color="success"
                    />
                  }
                  label="Status"
                />
              </Stack>

              <Stack pb={4}>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Iconify icon="iconoir:submit-document" />}
                >
                  SUBMIT
                </Button>
              </Stack>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default CreateUser;
