import axios from "axios";
import {port} from '../../constants/api'


const user = JSON.parse(localStorage.getItem("user"));

export const getMainCategory = async (id=null) => {
  return await axios.get(`${port}/getMainCategory?id=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
