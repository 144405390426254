import { useState } from 'react';
import { Grid, Box, Card, Stack, InputAdornment, IconButton, Typography, Avatar, CircularProgress, TextField, FormHelperText } from '@mui/material';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';

import AntSwitch from "src/components/switch/antSwitch";

// react hook forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";


//------- validation schema ------
const validationSchema = yup.object().shape({
    facebook: yup.string().required("This field is required."),
    instagram: yup.string().required("This field is required."),
    twitter: yup.string().required("This field is required."),
    youtube: yup.string().required("This field is required."),
    // linkedin: yup.string().required("This field is required."),
});

function SocialsLinks(props) {
    const dispatch = useDispatch();
    // const [loader, setLoader] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    const { item } = props;
    const [socialLink, setSocialLink] = useState(item)
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            facebook: item.facebook.url,
            instagram: item.instagram.url,
            twitter: item.twitter.url,
            youtube: item.youtube.url,
            // linkedin:item?.linkedin?.url || '',
        }
    });

    const submiSocialLinks = async (data) => {
        dispatch(setRequestInProgress());
        const payloadData = {
            key: GENERAL_SETTINGS_KEYS.SOCIAL_LINKS,
            value: JSON.stringify(socialLink),
            status: 1,
        }

        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.SET_GENERAL_SETTINGS, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Social links updated successfully.");
            } else {
                dispatch(setRequestFailure("Getting error while sending the your request. Please try  again."));
                createNotification("error", "", "Unable to process your request. Please try again.");
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while senidng the your request. Please try  again."));
            createNotification("error", "", "Unable to process your request. Please try again.");

        }

        return false;
    }

    const handleLinkStatus = (event) => {
        const { name, checked } = event.target;
        const existingState = { ...socialLink };

        const updatedState = {
            ...existingState,
            [name]: {
                ...existingState[name],
                status: checked ? 1 : 0,
            },
        }
        setSocialLink(updatedState);
    }

    const handleChangeInput = (event) => {
        const { name, value } = event.target;
        const existingState = { ...socialLink };

        const updatedState = {
            ...existingState,
            [name]: {
                ...existingState[name],
                url: value
            },
        }
        setSocialLink(updatedState);
    }

    return <>
        <Card className='profile-left-section' sx={{ px: 2, py: 3 }}>
            <form onSubmit={handleSubmit(submiSocialLinks)} noValidate>
                <Grid
                    className="user-input-section"
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                    // p={2}
                >
                    <Grid item md={7}>
                        <Grid item mb={4}>
                            <TextField fullWidth name="facebookLink" disabled={loader}
                                {...register('facebook')}
                                error={!!errors.facebook}
                                helperText={errors.facebook ? errors.facebook.message : ''}
                                placeholder="https://www.facebook.com/xxxx/xxxx"
                                onChange={(event) => handleChangeInput(event)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FacebookRoundedIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                                
                            {/* <AntSwitch
                                disabled={loader}
                                onChange={(event) => handleLinkStatus(event)}
                                checked={socialLink.facebook.status === 1 ? true : false}
                                name="facebook"
                                data-value={socialLink.facebook.status}
                                sx={{ variant: 'error' }}
                            /> */}
                        </Grid>
                        <Grid item mb={4}>
                            <TextField fullWidth name="instagramLink" disabled={loader}
                                {...register('instagram')}
                                error={!!errors.instagram}
                                helperText={errors.instagram ? errors.instagram.message : ''}
                                placeholder="https://www.instagram.com/xxxx.xxxx"
                                onChange={(event) => handleChangeInput(event)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <InstagramIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            {/* <AntSwitch
                                disabled={loader}
                                onChange={(event) => handleLinkStatus(event)}
                                checked={socialLink.instagram.status === 1 ? true : false}
                                name="instagram"
                                sx={{ variant: 'error' }}
                            /> */}
                        </Grid>
                        <Grid item mb={4}>
                            <TextField fullWidth name="youtubeLink" disabled={loader}
                                {...register('youtube')}
                                error={!!errors.youtube}
                                helperText={errors.youtube ? errors.youtube.message : ''}
                                placeholder="https://www.youtube.com/xxxx.xxxx"
                                onChange={(event) => handleChangeInput(event)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <YouTubeIcon />
                                        </InputAdornment>
                                    ),
                                }} />
                            {/* <AntSwitch
                                disabled={loader}
                                onChange={(event) => handleLinkStatus(event)}
                                checked={socialLink.youtube.status === 1 ? true : false}
                                name="youtube"
                                sx={{ variant: 'error' }}
                            /> */}
                        </Grid>
                        <Grid item mb={4}>
                            <TextField fullWidth name="twitterLink" disabled={loader}
                            {...register('twitter')}
                            error={!!errors.twitter}
                            helperText={errors.twitter ? errors.twitter.message : ''}
                            placeholder="https://www.twitter.com/xxxx.xxxx"
                            onChange={(event) => handleChangeInput(event)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TwitterIcon />
                                    </InputAdornment>
                                ),
                            }} />
                            {/* <AntSwitch
                                disabled={loader}
                                onChange={(event) => handleLinkStatus(event)}
                                checked={socialLink.twitter.status === 1 ? true : false}
                                name="twitter"
                                sx={{ variant: 'error' }}
                            /> */}
                        </Grid>
                        <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    my={2}
                >
                    <LoadingButton
                        loading={loader ? true : false}
                        size="medium"
                        type="submit"
                        loadingPosition="end"
                        endIcon={<SendIcon />}
                        variant="contained"
                    >
                        {loader ? 'Please Wait...' : 'save changes'}
                    </LoadingButton>
                </Stack>
                    </Grid>

                    {/* <TextField fullWidth name="linkedIn" 
                        {...register('linkedin')}
                        error={!!errors.linkedin}
                        helperText={errors.linkedin ? errors.linkedin.message : ''}
                        placeholder="https://www.linkedin.com/in/xxxx.xxxx"
                        InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkedInIcon />
                                    </InputAdornment>
                                ),
                        }} /> */}
            
                </Grid>
            </form>
        </Card>
    </>
}

export default SocialsLinks;