import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GENERAL_SETTINGS } from "src/app/config/endpoints";
import { getRequest } from "src/app/httpClient/axiosClient";
import { createNotification } from "src/common/createNotification";
import { formatDate, formatNumber } from "src/utils/helpers/functions";

const typeOptions = [
  { id: 1, name: "Active Users", value: "activeUsers" },
  // {
  //   id: 2,
  //   name: "Average Engagement Time per Active User",
  //   value: "averagePurchaseRevenuePerUser",
  // },
  //   {
  //     id: 3,
  //     name: "Average Engagement Time per Session",
  //     value: "avgEngagementTimePerSession",
  //   },
  { id: 4, name: "Event Count", value: "eventCount" },
  { id: 5, name: "Key Events", value: "keyEvents" },
  { id: 6, name: "New Users", value: "newUsers" },
  { id: 7, name: "Sessions", value: "sessions" },
  { id: 8, name: "Total Users", value: "totalUsers" },
  { id: 9, name: "Views", value: "screenPageViews" },
  { id: 10, name: "Views per Active User", value: "viewsPerActiveUser" },
];

const timeOptions = [
  "Today",
  "Yesterday",
  "This week",
  "Last week",
  "Last 7 days",
  "Last 14 days",
  "Last 28 days",
  "Last 30 days",
  "Last 60 days",
  "Last 90 days",
  "Last 6 months",
  "Last 1 year",
];

const AnalyticsChartA = () => {
  const [options, setOptions] = useState({
    option_1: { name: "Active Users", value: "activeUsers" },
    option_2: { name: "New Users", value: "newUsers" },
    option_3: { name: "Sessions", value: "sessions" },
    option_4: { name: "Total Users", value: "totalUsers" },
  });
  const [optCount, setOptCount] = useState({
    option_1: { count: 0 },
    option_2: { count: 0 },
    option_3: { count: 0 },
    option_4: { count: 0 },
  });
  const [selectedTime, setSelectedTime] = useState("Last 28 days");
  const [analyticsData, setAnalyticsData] = useState({
    selectedOpt: "option_1",
    data: [],
  });
  const [loading, setLoading] = useState(false); // Loader state

  const handleOption = (e) => {
    const selectedValue = e.target.value;
    const optionName = e.target.name;

    // Check if the selected value is already chosen in any other option
    const isDuplicate = Object.keys(options).some(
      (key) => key !== optionName && options[key].value === selectedValue
    );

    if (isDuplicate) {
      // If duplicate, prevent updating the state
      createNotification(
        "error",
        "",
        "This option has already been selected elsewhere. Please choose a different option."
      );
      return;
    }

    // Otherwise, update the selected option in state
    const selectedData = typeOptions.find(
      (type) => type.value === selectedValue
    );
    setOptions((prev) => ({
      ...prev,
      [optionName]: selectedData,
    }));
  };

  const handleSelectTime = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleSelectOption = (opt) => {
    setAnalyticsData((prev) => ({ ...prev, selectedOpt: opt }));
  };

  const getFormatedData = (data = []) => {
    const formatedData = data
      ?.map((item) => {
        return { ...item, name: formatDate(item?.name) };
      })
      .sort(
        (a, b) =>
          new Date(a.name.split("-").reverse().join("-")) -
          new Date(b.name.split("-").reverse().join("-"))
      );

    return formatedData;
  };

  const getTotalCounts = async () => {
    const url = `${GENERAL_SETTINGS.GET_ANALYTICS_A}?metrics=${options?.option_1?.value},${options?.option_2?.value},${options?.option_3?.value},${options?.option_4?.value}&duration=${selectedTime}`;
    try {
      setLoading(true); // Start loading
      const response = await getRequest(url);
      if (response.data.data.rows && response.data.data_total.rows) {
        setOptCount({
          option_1: { count: response.data.data_total.rows[0]?.option_1 },
          option_2: { count: response.data.data_total.rows[0]?.option_2 },
          option_3: { count: response.data.data_total.rows[0]?.option_3 },
          option_4: { count: response.data.data_total.rows[0]?.option_4 },
        });
        setAnalyticsData({
          selectedOpt: "option_1",
          data: getFormatedData(response.data.data.rows),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getTotalCounts();
  }, [selectedTime, options]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <div>{payload[0]?.payload?.name}</div>
          <div>
            {options[analyticsData?.selectedOpt]?.name}: {payload[0].value}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        width: "100%",
        height: "100%"
      }}
    >
      <Grid container direction="row" spacing={2} marginBottom={"10px"}>
        <Grid item xs={2}>
          <FormControl fullWidth variant="standard">
            <Select
              value={options?.option_1?.value}
              onChange={handleOption}
              name="option_1"
            >
              {typeOptions.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="h5"
            onClick={() => handleSelectOption("option_1")}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              cursor: "pointer",
              backgroundColor:
                analyticsData?.selectedOpt === "option_1" ? "#f0f0f0" : "",
            }}
          >
            {formatNumber(optCount?.option_1?.count)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="standard">
            <Select
              value={options?.option_2?.value}
              onChange={handleOption}
              name="option_2"
            >
              {typeOptions.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="h5"
            onClick={() => handleSelectOption("option_2")}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              cursor: "pointer",
              backgroundColor:
                analyticsData?.selectedOpt === "option_2" ? "#f0f0f0" : "",
            }}
          >
            {formatNumber(optCount?.option_2?.count)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="standard">
            <Select
              value={options?.option_3?.value}
              onChange={handleOption}
              name="option_3"
            >
              {typeOptions.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="h5"
            onClick={() => handleSelectOption("option_3")}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              cursor: "pointer",
              backgroundColor:
                analyticsData?.selectedOpt === "option_3" ? "#f0f0f0" : "",
            }}
          >
            {formatNumber(optCount?.option_3?.count)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth variant="standard">
            <Select
              value={options?.option_4?.value}
              onChange={handleOption}
              name="option_4"
            >
              {typeOptions.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            variant="h5"
            onClick={() => handleSelectOption("option_4")}
            sx={{
              marginBottom: "10px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              cursor: "pointer",
              backgroundColor:
                analyticsData?.selectedOpt === "option_4" ? "#f0f0f0" : "",
            }}
          >
            {formatNumber(optCount?.option_4?.count)}
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
        <Box
          sx={{
            textAlign: "center",
            height: "400px",
            display: "flex", // Enable Flexbox layout
            justifyContent: "center", // Center horizontally
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={500}
            height={200}
            data={analyticsData?.data}
            margin={{
              top: 5,
              right: 5,
              left: 0,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {/* <Tooltip /> */}
            <Tooltip content={<CustomTooltip />} />
            <Line
              connectNulls
              type="monotone"
              dataKey={analyticsData?.selectedOpt}
              stroke="#8884d8"
              fill="#8884d8"
            />
          </LineChart>
        </ResponsiveContainer>
      )}

      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <FormControl fullWidth variant="standard">
            <Select value={selectedTime} onChange={handleSelectTime}>
              {timeOptions.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsChartA;
