import { faker } from "@faker-js/faker";
import { sample } from "lodash";

const NewsList = [...Array(24)].map((_, index) => ({
  id: faker.datatype.uuid(),
  // avatarUrl: `./assets/images/avatars/avatar_${index + 1}.jpg`,
  // title: faker.name.fullName(),
  title: sample([
    "New Bill to offer reimbursement for costs of relocating pet",
    "“One in a million” moment when spent casing ignites primer on table",
    "Top 5 stories Veterans want Hollywood to make into a movie",
    "American hurdler awarded Olympic gold after 10 years",
    "The internet thinks a Chiefs superfan robbed banks to fund lifestyle",
    "Survivor winner says he’ll donate $1M prize money to Veterans",
    "Canadian VA to struggling vets: Wanna kill yourself?",
    "Three stowaways hospitalized after surviving 11 days on ship’s rudder",
    "Pakistan’s former prime minister survives ‘assassination attempt’",
    "The internet thinks a Chiefs superfan robbed banks to fund lifestyle’",
  ]),
  summary: faker.name.fullName(),
  image: sample([
    "https://images.unsplash.com/photo-1673205194019-55ac9f0a0899?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1673232141234-889ed2cf341c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80",
    "https://images.unsplash.com/photo-1671726805766-de50e4327182?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1673247248053-45d9398b5853?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1661961110671-77b71b929d52?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    "https://images.unsplash.com/photo-1673248738655-289ca8cb3a6f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  ]),
  video: sample([
    "https://player.vimeo.com/external/284652268.sd.mp4?s=0bf23f0d69280b20e39fb2dd5501a5b0d4612a77&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/269971860.sd.mp4?s=a3036bd1a9f15c1b31daedad98c06a3b24cdd747&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/371745222.sd.mp4?s=17a4f2bb178b42f6a7b7dfeeeb1a131227ee32b3&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/333757267.sd.mp4?s=8dc7f65de267d6d903d28709f8c8b5e52abc84c5&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/368529726.sd.mp4?s=0a32b8e4da66c970c41adc3c43e233449f70aed2&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/366682092.sd.mp4?s=322ce5312567d296b0887f763966993206104ee9&profile_id=164&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/357941044.sd.mp4?s=4b58cdf2377615be10826c0e4f722c1990d8ac44&profile_id=164&oauth2_token_id=57447761",
  ]),
  description: sample ([
     faker.lorem.paragraph()
  ]),
  category: sample([
    "Trending News",
    "Breaking News",
    "Top",
    "Crime",
    "Army",
    "Tech",
    "Politics",
    "Recent News",
    "World News",
  ]),
  // isVerified: faker.datatype.boolean(),
  // status: sample(['Active', 'Inactive']),
  published: sample([true, false]),
  // inTrending: sample(['Yes', 'No']),
  // breakingNews: sample(['Yes', 'No']),
  date: "12/10/2022",
  // newsTagsType: sample(['Featured', 'Drama', 'Crime']),
  // addedBy: sample([
  //   'Admin',
  //   'Reporter',
  //   'Editor',
  //   'Staff',
  //   'Sources',
  // ]),
  visitors: sample(["-", "10", "20", "54", "16", "26", "85"]),
}));

// const NewsList = [
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title:  "One in a million moment when spent casing ignites primer on table",
//     summary:
//     'Top 5 stories Veterans want Hollywood to make into a movie'  ,
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title:  'Top 5 stories Veterans want Hollywood to make into a movie' ,
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title:'American hurdler awarded Olympic gold after 10 years',
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "No",
//     breakingNews: "No",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
//   {
//     id: faker.datatype.uuid(),
//     avatarUrl: "./assets/images/avatars/avatar_1.jpg",
//     title: "New Bill to offer reimbursement for costs of relocating pet",
//     summary:
//       "“One in a million” moment when spent casing ignites primer on table",
//     category: "Trending News",
//     isVerified: true,
//     status: "Active",
//     published: "published",
//     inTrending: "Yes",
//     breakingNews: "Yes",
//     date: "12/10/2022",
//     newsTagsType: "Featured",
//     addedBy: "Admin",
//     visitors: "-",
//   },
// ];

export default NewsList;
export const NEWSBULLETINLIST = [
  { id: 1, label: "Trending News" },
  { id: 2, label: "Breaking News" },
  { id: 3, label: "Top" },
  { id: 4, label: "Crime" },
  { id: 5, label: "Army" },
  { id: 6, label: "Tech" },
  { id: 7, label: "Politics" },
  { id: 8, label: "Recent News" },
  { id: 9, label: "World News" },
];
