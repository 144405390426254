import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';

function AttributeValue(props) {
    const { loading, attributeList, handleValueChange, checkedArray } = props;

    return (
        <Box
            sx={{
                 display: 'flex',            // Make Box a flex container
                 flexDirection: 'row',       // Align items horizontally (change to 'column' for vertical)
                 flexWrap: 'wrap',           // Allow wrapping if there are too many items
                 gap: 2,                     // Add space between each child (AttributeValue component)
            }}
        >
            {attributeList.map((attribute) => {
                const { id, name, attribute_values } = attribute;
                return (
                    <Box
                        key={id}
                        sx={{
                            display: 'flex',           
                            flexDirection: 'column',   
                            alignItems: 'flex-start', 
                            mb: 3,                     
                            width: 'auto',             
                            flex: '200px',         
                            padding: 2,                
                            borderRadius: 2,         
                        }}
                    >
                        <List
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                            }}
                            component="nav"
                            aria-hidden="false"
                        >
                            {/* Render the name of the attribute (e.g., 'Color', 'Size') */}
                            <ListItemButton>
                                <ListItemText primary={name} />
                            </ListItemButton>
                            {/* Collapse for attribute values (checkboxes) */}
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {/* Render each checkbox value horizontally */}
                                    {attribute_values.map((attributeValue) => {
                                        const { id: valueId, value } = attributeValue;
                                        const checkedItem = checkedArray.includes(valueId.toString());
                                        return (
                                            <ListItemButton
                                                key={valueId}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',   // Align checkbox and label in a row
                                                    alignItems: 'center',   // Vertically center checkbox and label
                                                    marginRight: 2,         // Space between checkboxes
                                                    marginBottom: 1,        // Space between checkboxes if they wrap
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={checkedItem}
                                                        name={`${id}-${valueId}`}
                                                        onChange={handleValueChange}
                                                        value={`${id}-${valueId}`}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={value} />
                                            </ListItemButton>
                                        );
                                    })}
                                </List>
                            </Collapse>
                        </List>
                    </Box>
                );
            })}
        </Box>
    );
}

export default AttributeValue;
