import axios from "axios";
import { port } from "../constants/api";
import { user } from "../constants/api";

export const getArchievedNews = async (page=1, query='', no_of_rows=10) => {
  return await axios.get(`${port}/listAndSearchArchivedNews?page=${page}&no_of_rows=${no_of_rows}&query=${query}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const getArchievedList = async () => {
  return await axios.get(`${port}/fetchArchivedNewsList`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const updateArchievedStatus = async (data) => {
  return await axios.post(`${port}/archivedNewsById`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
