import { Helmet } from "react-helmet-async";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from '@mui/lab';

import $ from "jquery";
import { checkTokenExpiry, matchYoutubeUrl } from "../../utils/helpers/functions";

// @mui
import {
  Card,
  CardHeader,
  Stack,
  Button,
  MenuItem,
  Grid,
  Container,
  Typography,
  CircularProgress,
  OutlinedInput,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewsBulletin } from "src/redux/Slices/NewsSlice";
import { useNavigate } from "react-router-dom";
import {
  getNewsBulletin,
  getNewsBulletinSubCategory,
} from "src/api/newsBulletinApi";
import { createNews, getUsers } from "src/api/newsList";
import { createNotification } from "../../common/createNotification";
import { port } from "src/constants/api";
import { Icon } from "@iconify/react";
import { createPodcast } from "src/api/podcast";
import { useTheme } from "@emotion/react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function CreateNews() {
  //STATES
  const [itemData, setitemData] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setloading] = useState(false);
  const [videoLink, setvideoLink] = useState("");
  const [inputLink, setinputLink] = useState("");
  const [videoData, setvideoData] = useState("");
  const [bulletin, setnewsBulletin] = useState([]);
  const [subbulletin, setSubnewsBulletin] = useState([]);
  const [mediaErrors, setmediaErrors] = useState({});
  const [dateValue, setDateValue] = useState(new Date());
  const [podcast, setpodcast] = useState(false);
  const [mainCategory, setMainCategory] = useState();
  const [selectedUser, setSelecteduser] = useState();
  const [mainCategoryData, setMainCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [mainCategoryDataList, setMainCategoryDataList] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [users, setAllusers] = useState([]);

  //CONSTANTS
  const formTop = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const pathName = window.location.pathname.split("/")[2];
  const { newsBulletin } = useSelector((state) => state.news);
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);
  const theme = useTheme();

  useEffect(() => {
    if (pathName === "news-podcast") {
      setpodcast(true);
    } else {
      setpodcast(false);
    }
  }, [pathName]);

  const getNewList = () => {
    const filteredBulletin = newsBulletin.filter(
      // eslint-disable-next-line eqeqeq
      (newsBullet) => newsBullet.status == 1
    );
    setSelectedCategory(filteredBulletin);
  };

  const SUPPORTED_FORMATS_VIDEOS = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/mpeg",
    "video/mkv",
  ];
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
    "image/avif",
  ];

  const validationSchema = Yup.object().shape({
    newsTitle: Yup.string().trim().required("Title is required."),
    date: Yup.string().required("Date is required."),
    summary: Yup.string().trim().required("Summary is required."),
    image_caption: Yup.string().trim(),
    description: Yup.string().trim().required("Description is required."),
    // bulletin: Yup.mixed().test(
    //   "length",
    //   "You need to select atleast one",
    //   (value) => {
    //     return value.length >= 1;
    //   }
    // ),
    bulletin: Yup.mixed(),
    mainCategory: Yup.string().required("News bulletin is required."),
    user: Yup.string().required("User is required."),
    picture: Yup.mixed(),
    newsImage: Yup.mixed()
      .test("required", "This field is required.", (file) => {
        if (file || file === undefined) return true;
        return false;
      })
      .test("fileSize", "Image size can not be more than 5 MB", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return file.length && file[0].size <= 5242880;
      })
      .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return (
          file.length &&
          ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp", "image/avif",].includes(file[0].type)
        );
      }),
    newsVideo: Yup.mixed()
      .test("required", "This field is required.", (file) => {
        if (file || file === undefined) return true;
        return false;
      })
      .test("fileSize", "Video size can not be more than 50 MB", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return file.length && file[0].size <= 52428800;
      })
      .test("fileType", "Only *.mp4, *.webm, *.ogg and *.mpeg files are allowed.", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return (
          file.length &&
          ["video/mp4", "video/webm", "video/ogg", "video/mpeg"].includes(file[0].type)
        );
      }),
    link:
      inputLink &&
      Yup.string().test("fileType", "Not correct URL", (value) => {
        return get_youtube_thumbnail(value, "high");
      }),
  });

  const validationSchemaPodcast = Yup.object().shape({
    summary: Yup.string().trim().required("Summary is required."),
    user: Yup.string().required("User is required."),
    picture: Yup.mixed()
    // .test("required", "Picture is required.", (value) => {
    //   return value && value.length;
    // })
    // .test("fileType", "Unsupported File Format", (value) => {
    //   return SUPPORTED_FORMATS.includes(value[0]?.type);
    // })
    ,
    newsImage: Yup.mixed()
      .test("required", "This field is required.", (file) => {
        if (file || file === undefined) return true;
        return false;
      })
      .test("fileSize", "Image size can not be more than 5 MB", (file) => {
        console.log("==> ", file);
        if (file === undefined || file.length === 0)
          return true;

        return file.length && file[0].size <= 5242880;
      })
      .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
        if (file === undefined || file.length === 0)
          return true;

        return (
          file.length &&
          ["image/jpg", "image/jpeg", "image/gif", "image/png", "image/webp", "image/avif",].includes(file[0].type)
        );
      }),
    newsTitle: Yup.string().trim().required("Title is required."),
    description: Yup.string().trim().required("Description is required."),
    // video: Yup.mixed()
    //   .test("required", "You need to provide a file", (value) => {
    //     return value && value.length;
    //   })
    //   .test("fileType", "Not Supported", (value) => {
    //     return SUPPORTED_FORMATS_VIDEOS.includes(value[0]?.type);
    //   }),
    // video_link: Yup.string().required("Video link is required."),
    link: Yup.string().trim().required("Podcast link is required."),
    // mainCategory: Yup.string().required("News bulletin is required."),
    // link:
    //   inputLink &&
    //   Yup.string().test("fileType", "Not correct URL", (value) => {
    //     return get_youtube_thumbnail(value, "high");
    //   }),
  });

  const formOptions = {
    resolver: yupResolver(
      pathName === "news-podcast" ? validationSchemaPodcast : validationSchema
    ),
  };

  const { register, handleSubmit, resetField, clearErrors, formState, setError, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const [selectedImage, setSelectedImage] = useState(null);
  const { onChange, ...params } = register("newsImage");
  const { onChange: videoOnChange, ...videoParams } = register("newsVideo");

  //USE EFFECTS--------------------------------------

  useEffect(() => {
    getBulletin();
    getMainCategory();
    getNewList();
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory]);

  useEffect(() => {
    register("ckeditorInput");
    if (errors?.description?.message) {
      $(".ck-content").removeClass("ck-focused");
      $(".ck-content").addClass("errorCkEditor");
    }
  });

  //FUNCTIONS-----------------------------------------

  const getBulletin = async () => {
    try {
      const data = await getNewsBulletin();
      // eslint-disable-next-line eqeqeq
      const filteredBulletin = data.data.filter((e) => e.status == 1);
      setMainCategoryDataList(filteredBulletin);
      const filteredBulletinSubCategory = data.data.filter(
        (e) => e.id != mainCategory
      );
      setSubCategoryData(filteredBulletinSubCategory);
      dispatch(setNewsBulletin(filteredBulletinSubCategory));
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  const getAllUsers = async () => {
    try {
      const data = await getUsers();
      setAllusers(data.data);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  const getMainCategory = async () => {
    try {
      const data = await getNewsBulletinSubCategory();
      const filteredBulletin = data.data.filter((e) => e.status == 1);
      // dispatch(setNewsBulletin(filteredBulletin));
      setMainCategoryData(data.data);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
        video_id = result.pop();
      } else if ((result = url.match(/youtu.be\/(.{11})/))) {
        video_id = result.pop();
      }
      if (video_id) {
        if (typeof quality == "undefined") {
          quality = "high";
        }

        var quality_key = "maxresdefault"; // Max quality
        // eslint-disable-next-line eqeqeq

        if (quality == "low") {
          quality_key = "sddefault";
          // eslint-disable-next-line eqeqeq
        } else if (quality == "medium") {
          quality_key = "mqdefault";
          // eslint-disable-next-line eqeqeq
        } else if (quality == "high") {
          quality_key = "hqdefault";
        }
        thumbnail =
          "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }

  const onSubmitForm = async (data) => {

    setLoader(true);
    // eslint-disable-next-line eqeqeq
    const handleRouteTo = (path) => {
      navigate(path);
    };

    const formData = new FormData();
    data?.newsImage && formData.append("files", data.newsImage[0]);
    data?.newsVideo && formData.append("files", data.newsVideo[0]);

    !podcast && formData.append("news_bulletin_names", data.bulletin);

    formData.append("title", data.newsTitle);

    // formData.append("journalist_name", data.journalist);

    formData.append("summary", data.summary);

    formData.append("description", data.description);

    formData.append("publish_status", +data?.published);

    formData.append("main_category", +data?.mainCategory);

    formData.append(
      podcast
        ? matchYoutubeUrl(data.link)
          ? "podcast_yt_video_link"
          : "podcast_video_link"
        : "news_yt_video_link",
      data.link
    );

    // podcast && formData.append("podcast_video_link", data.video_link);

    formData.append("published_date", dateValue);

    formData.append("recent", 0);
    formData.append("created_by_user_id", selectedUser);
    if (!podcast) {
      try {
        formData.append("image_caption", data.image_caption);
        await createNews(formData);
        handleRouteTo("/dashboard/news");
        createNotification("success", "Created", "News created Successfully");
      } catch (error) {
        checkTokenExpiry(error.response.data.msg);
        setLoader(false);
        setloading(false);
      }
    } else {
      try {
        await createPodcast(formData);
        handleRouteTo("/dashboard/news-podcast");
        createNotification(
          "success",
          "Created",
          "Podcast created Successfully"
        );
      } catch (error) {
        checkTokenExpiry(error.response.data.msg);
        setLoader(false);
        setloading(false);
        createNotification("error", "Error", `error while create ${pathName}`);
      }
    }
  };

  const handleBulletinChange = (event) => {
    const {
      target: { value },
    } = event;
    setnewsBulletin(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleBulletinChangeMainCategory = (e) => {
    setMainCategory(e.target.value);
  };
  const handleUserChange = (e) => {
    setSelecteduser(e.target.value);
  };

  const removeNewsImage = () => {
    setitemData("");
    resetField('newsImage');
  }

  const removeNewsVideo = () => {
    setvideoData("");
    resetField('newsVideo');
  }

  const handleFile = (e) => {
    const image = e.target.files[0];
    if (!image)
      return false;

    // register("picture");
    // if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|avif)$/)) {
    //   setitemData("");
    //   return false;
    // }

    setitemData(URL.createObjectURL(e.target.files[0]));
    return e.target.files[0];
  };

  const handleVideo = (e) => {
    // trigger("newsVideo");
    const video = e.target.files[0];
    console.log(" ==> ", video)
    if (!video)
      return false;

    // if (!video.name.match(/\.(mp4)$/)) {
    //   setError("video", {
    //     type: "custom",
    //     message: "Not supported",
    //   });
    //   return false;
    // }
    setvideoData(URL.createObjectURL(e.target.files[0]));
    return e.target.files[0];
  };

  if (!String.prototype.trimStart) {
    String.prototype.trimStart = function () {
      return this.replace(/^\s+/, "");
    }
  }

  return (
    <>
      <Helmet>
        <title> {path} </title>
      </Helmet>

      {loading && (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "75%",
            bottom: "0",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",

            zIndex: "1000",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Container  maxWidth="xl" ref={formTop}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography variant="h4" gutterBottom>
            {path}
          </Typography>
        </Stack>
        <Breadcrumbs aria-label="breadcrumb" mb={5}>
          <Link style={{ textDecoration: "none", color: "black" }} to="/">
            Dashboard
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/dashboard/${pathName}`}
          >
            {path}
          </Link>
          <Typography color="text.primary">Create {path}</Typography>
        </Breadcrumbs>

        <Card>
          <CardHeader title={`Create ${path}`} />
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
            sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
          >
            <Grid container spacing={4} alignItems="start">
              <Grid item xs={12} md={12}>
                <TextField
                  {...register("newsTitle", { required: true })}
                  error={errors.newsTitle}
                  helperText={
                    errors.newsTitle ? errors.newsTitle.message : false
                  }
                  id="news-title"
                  label="Title*"
                  name="newsTitle"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextField
                  {...register("journalist", { required: true })}
                  error={errors.journalist}
                  helperText={
                    errors.journalist ? errors.journalist.message : false
                  }
                  id="journalist-name"
                  label="Journalist Name"
                  name="journalist"
                  variant="outlined"
                  fullWidth
                  />
                  </Grid> */}
              <Grid item xs={12} md={12}>
                <TextField
                  {...register("summary", { required: true })}
                  id="news-summary"
                  label="Summary*"
                  error={errors.summary}
                  helperText={errors.summary ? errors.summary.message : false}
                  name="summary"
                  variant="outlined"
                  rows={3}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <div className={errors.description ? "errorCkEditor description_div" : "description_div"}>
                  <CKEditor
                    name="description"
                    style={{
                      position: "relative",
                      zIndex: "20",
                    }}
                    config={{
                      ckfinder: {
                        uploadUrl: `${port}/upload`,
                      },
                      placeholder: "Description*",
                    }}
                    editor={ClassicEditor}
                    data={""}
                    onChange={(event, editor) => {
                      setValue("description", editor.getData());
                      trigger("description");
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "100%",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onBlur={(event, editor) => { }}
                    onFocus={(event, editor) => {
                      if (errors.description?.message) {
                        $(".ck-content").removeClass("ck-focused");
                        $(".ck-content").addClass("errorCkEditor");
                      }
                    }}
                  />
                </div>
                {errors.description ? (
                  <p className="errorForm">{errors.description.message}</p>
                ) : (
                  ""
                )}
              </Grid>
              {/* upload image  */}
              <Grid item xs={12} md={podcast ? 12 : 6}>
                <div
                  className={
                    errors.newsImage
                      ? "errorCkEditor"
                      : "borderInput"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    postition: "relative",
                    height: itemData || videoData ? '275px' : 'auto'
                  }}
                >

                  {itemData ? (
                    <>
                      <div className="img-preview-wrap"
                      >
                        <img
                          src={itemData}
                          style={{
                            height: "190px",
                            marginBottom: "10px",
                            width: "190px",
                            objectFit: 'contain',
                            border: "1px solid rgba(0,0,0,0.1)",
                            borderRadius: "10px"
                          }}
                          alt="something"
                          loading="lazy"
                        />
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="primary"
                          className="delete-icon"
                          onClick={removeNewsImage}
                        // disabled={loader}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <Icon
                        fontSize="60px"
                        icon="material-symbols:image-rounded"
                      />
                      <span
                        style={{
                          color: errors.newsImage ? "#ff5c56" : "#8d9299",
                          fontSize: "14px",
                          marginBottom: "20px",
                          textAlign: 'center'
                        }}
                      >
                        Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB, Recomended resolution 1170 × 500.
                      </span>
                    </>
                  )}

                  <Button variant="contained" component="label">
                    Upload image
                    {/* <input
                      id="picture"
                      accept="image/*"
                      name="picture"
                      {...register("picture", {
                        onChange: handleFile,
                      })}
                      type="file"
                      error={errors.picture}
                      hidden
                    /> */}
                    <input
                      {...params}
                      type="file"
                      id="uploadFileInput"
                      accept="image/*"
                      name="newsImage"
                      onChange={(event) => {
                        setSelectedImage(event.target.files[0]);
                        onChange(event);
                        trigger('newsImage');
                        handleFile(event)
                      }}
                      style={{ display: 'none' }}
                    />
                  </Button>
                </div>
                {errors.newsImage ? (
                  <p className="errorForm">{errors.newsImage.message}</p>
                ) : (
                  ""
                )}
              </Grid>
              {/* upload video  */}
              {!podcast && (
                <Grid item xs={12} md={6}>
                  <div
                    className={errors.video ? "errorCkEditor" : "borderInput"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "20px",
                      postition: "relative",
                      height: itemData || videoData ? '275px' : 'auto',
                      minHeight: '200px'
                    }}
                  >
                    {videoData ? (
                      <div style={{ position: "relative" }}>
                        <video
                          controls
                          autoPlay
                          src={videoData}
                          style={{
                            position: 'relative',
                            marginBottom: '10px',
                            width: '100% !important',
                            maxHeight: '200px',
                            objectFit: 'cover',
                          }}
                          alt="data"
                          loading="lazy"
                        />
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="primary"
                          className="delete-icon"
                          onClick={removeNewsVideo}
                        // disabled={loader}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    ) : (
                      <>
                        <Icon
                          fontSize="60px"
                          icon="material-symbols:video-call-rounded"
                        />
                        <span
                          style={{
                            color: "#8d9299",
                            fontSize: "14px",
                            marginBottom: "20px",
                          }}
                        >
                          Only mp4, webm, ogg and mpeg files are supported. Video size can not be more than 50 MB
                        </span>
                      </>
                    )}

                    <Button variant="contained" component="label">
                      Upload Video
                      <input
                        {...videoParams}
                        type="file"
                        name="newsVideo"
                        accept="video/mp4, video/webm, video/ogg, video/mpeg,video/mkv"
                        onChange={(event) => {
                          videoOnChange(event);
                          trigger('newsVideo');
                          handleVideo(event)
                        }}
                        hidden
                      />
                    </Button>
                  </div>
                  {errors.newsVideo ? (
                    <p className="errorForm">{errors.newsVideo.message}</p>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
              {/* upload video link  */}
              {
                pathName === "news-podcast" &&
                <><Grid item xs={12} md={6}>
                  {videoLink ? (
                    <>
                      <div
                        onClick={() => {
                          setvideoLink("");
                          setinputLink("");
                        }}
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "7px",
                          cursor: "pointer",
                          fontSize: "26px",
                          color: "red",
                        }}
                      >
                        <TiDelete />
                      </div>

                      <img
                        src={videoLink}
                        style={{
                          height: "250px",
                          marginBottom: "10px",
                          width: "590px",
                        }}
                        alt="something"
                        loading="lazy" />
                    </>
                  ) : (
                    <></>
                  )}

                  <TextField
                    {...register("link", {
                      required: true,
                      onChange: (data) => {
                        // setmediaErrors({ ...mediaErrors, yt_link: "" });
                        setinputLink(data.target.value);
                      },
                    })}
                    value={inputLink}
                    error={errors.link}
                    id="link"
                    label="Podcast Link*"
                    name="link"
                    variant="outlined"
                    fullWidth />
                  {errors.link?.message && (
                    <span style={{ fontSize: "12px", color: "#D61E27" }}>
                      {errors.link?.message}
                    </span>
                  )}
                </Grid>
                </>
              }
              {pathName === "news" && (
                <>

                  <Grid item xs={12} md={12}>
                    <TextField
                      {...register("image_caption", { required: true })}
                      id="image_caption"
                      label="Image Caption"
                      error={errors.image_caption}
                      helperText={errors.image_caption ? errors.image_caption.message : false}
                      name="image_caption"
                      variant="outlined"
                      rows={3}
                      multiline
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Stack spacing={3} direction="column">
                      <FormControl
                        error={errors.bulletin?.message}
                        name="bulletin"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel
                          className={`${errors.mainCategory?.message && 'bulletin-error'}`}
                          id="demo-simple-select-standard-label">
                          News Bulletin*
                        </InputLabel>
                        <Select
                          // labelId="news-bulletin-label"
                          id="news-bulletin"
                          name="bulletin"
                          error={errors?.mainCategory}
                          value={mainCategory}
                          {...register("mainCategory", {
                            required: true,
                            onChange: handleBulletinChangeMainCategory,
                          })}
                          // label="News Bulletin*"
                          input={<OutlinedInput label="News Bulletin*" />}
                          MenuProps={MenuProps}
                        >
                          {mainCategoryDataList.map((name) => (
                            <MenuItem key={name.name} value={name.id}>
                              {name.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ fontSize: "12px", color: "#D61E27" }}>
                          {errors.mainCategory?.message
                            ? errors.mainCategory?.message
                            : false}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </Grid><Grid item xs={12} md={6}>
                    <Stack spacing={3} direction="column">
                      <FormControl
                        error={errors.bulletin?.message}
                        name="bulletin"
                        fullWidth
                        variant="outlined"
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Sub News Bulletin
                        </InputLabel>
                        <Select
                          labelId="news-bulletin-label"
                          id="news-bulletin"
                          name="bulletin"
                          value={bulletin}
                          {...register("bulletin", {
                            // required: true,
                            onChange: handleBulletinChange,
                          })}
                          label="Sub News Bulletin"
                          multiple
                          input={<OutlinedInput label="Sub News Bulletin" />}
                          MenuProps={MenuProps}
                        >
                          {subCategoryData.map((name) => (
                            <MenuItem
                              key={name.name}
                              value={name.id}
                              style={getStyles(name.id, bulletin, theme)}
                            >
                              {name.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.bulletin?.message
                            ? errors.bulletin?.message
                            : false}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  </Grid></>
              )}

              {/* {
                pathName === "news" && ( */}
              <Grid item xs={12} md={6}>
                <Stack spacing={3} direction="column">
                  <FormControl
                    error={errors.user}
                    name="selectedUser"
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Users*
                    </InputLabel>
                    <Select
                      labelId="selectedUser-label"
                      id="news-bulletin"
                      name="selectedUser"
                      value={selectedUser}
                      {...register("user", {
                        required: true,
                        onChange: handleUserChange,
                      })}
                      label="Users*"
                      input={<OutlinedInput label="Users*" />}
                      MenuProps={MenuProps}
                    >
                      {users.map((name) => (
                        <MenuItem
                          key={name.name}
                          value={name.id}
                        // style={getStyles(name , selectedUser, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {errors.user?.message ? errors.user?.message : false}
                    </FormHelperText>
                  </FormControl>
                  {/* <FormControl
                      error={errors.bulletin?.message}
                      name="bulletin"
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        News Bulletin
                      </InputLabel>
                      <Select
                        labelId="news-bulletin-label"
                        id="news-bulletin"
                        name="bulletin"
                        value={bulletin}
                        {...register("bulletin", {
                          required: true,
                          onChange: handleBulletinChange,
                        })}
                        label="News bulletin *"
                      >
                        {filteredBulletin.map((index, i) => {
                          const { id, name } = index;
                          return (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {errors.bulletin?.message
                          ? errors.bulletin?.message
                          : false}
                      </FormHelperText>
                    </FormControl> */}
                </Stack>
              </Grid>
              {/* )
              } */}

              <Grid item xs={12} md={6}>
                <Stack spacing={3} direction="column">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormControl
                      style={{ width: "100%" }}
                    >
                      <DesktopDatePicker
                        label="Date*"
                        inputFormat="DD/MM/YYYY"
                        name="date"
                        value={dateValue}
                        onChange={(newValue) => {
                          setDateValue(newValue === null ? '' : newValue);
                          setValue('date', newValue === null ? '' : newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            width={100}
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                      {dateValue === '' && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained bulletin-error">
                        Date is required.
                      </p>}
                    </FormControl>
                    <input type="hidden" {...register("date")} value={dateValue} />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <Stack spacing={3} direction="row">
                    <FormControlLabel
                      control={
                        <Switch
                          name="published"
                          {...register("published")}
                          color="success"
                        />
                      }
                      label="Published"
                    />
                    {/* {
                      !podcast &&
                      <FormControlLabel
                        control={
                          <Switch
                            name="recentNews"
                            {...register("recentNews")}
                            color="success"
                          />
                        }
                        label="Recent News"
                      />
                    } */}

                  </Stack>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className="d-flex space-between align-items-center"
              >
                {/* <Button type="submit" variant="contained">
                  SUBMIT
                </Button> */}
                <LoadingButton
                  loading={loader ? true : false}
                  size="medium"
                  type="submit"
                  loadingPosition="end"
                  variant="contained"
                >
                  {loader ? 'PLEASE WAIT...' : 'SUBMIT'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Container>
    </>
  );
}

export default CreateNews;
