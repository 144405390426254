import axios from "axios";
import { port } from '../../constants/api'


const user = JSON.parse(localStorage.getItem("user"));

export const getAttributes = async () => {

  return await axios.get(`${port}/getAttributes`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const getProductParentApi = async (id) => {

  return await axios.get(`${port}/getProductParent/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
