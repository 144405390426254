import axios from "axios";
import { port } from "../constants/api";
import { user } from "../constants/api";

export const getPodcast = async (page) => {
  return await axios.get(`${port}/getPodcastList?page=${page}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const fetchPodcast = async (page=1, query='', no_of_rows=10) => {
  return await axios.get(`${port}/getPodcastListAndSearch?page=${page}&no_of_rows=${no_of_rows}&query=${query}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const getPodcastById = async (id) => {
  return await axios.get(`${port}/getPodcastById/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const createPodcast = async (formData) => {
  return await axios.post(`${port}/createPodcast`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const deletePodcast = async (id) => {
  return await axios.delete(`${port}/deletePodcastById`, {
    data: { id: id },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const searchPodcasts = async (search) => {
  return await axios.get(`${port}/searchPodcast?query=${search}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const editPodcast = async (data) => {
  return await axios.post(`${port}/editPodcast`, data, {
    headers: {
      "Content-Type": "Multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const updatePublishPodcast = async (data) => {
  return await axios.post(`${port}/publishPodcastById`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
