import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { checkTokenExpiry } from "../../utils/helpers/functions";
import { LoadingButton } from '@mui/lab';
// @mui
import { Card, CardHeader, Stack, Grid, Container, Typography, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";

import { setRequestInProgress, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { getRequest, postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';


function EditAttribute() {

    const { id } = useParams();

    const [loading, setloading] = useState(false);
    const { loader } = useSelector(state => state.serviceRequest);
    const [attributeDetail, setAttributeDetail] = useState({});

    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const pathName = window.location.pathname.split("/")[2];
    const pathName = /[^/]*$/.exec(window.location.pathname)[0];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;


    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        console.log(data);
        dispatch(setRequestInProgress());
        const payloadData = {
            id:id,
            name: data.name,
            status: data.status ? 1 : 0,
        }
        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.UPDATE_ATTRIBUTE, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Attribute updated successfully.");
                handleRouteTo("/e-commerce/attribute");

            } else {
                dispatch(setRequestFailure("Getting error while creating Attribute."));
                createNotification("error", "", "Getting error while creating Attribute.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Attribute."));
            createNotification("error", "", "Getting error while creating Attribute.");
        }
    };

    const handleChange = (event) => {
        setAttributeDetail({ ...attributeDetail, status: event.target.checked });
    };

    async function getAttribute(id) {

        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(GENERAL_SETTINGS.GET_ATTRIBUTE_BY_ID.replace('{ID}', id));
            const { status, data: { data } } = response;
            if (status === 200 && data) {
                setAttributeDetail(data);
                setValue('name', data.name);
                setValue('status', data.status ? true : false);
                dispatch(setRequestInit());
            } else if (data === null) {
                dispatch(setRequestFailure("Attribute not found."));
                createNotification("error", "", "Attribute not found.");
                handleRouteTo('/e-commerce/attribute')
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }
    useEffect(() => {
        getAttribute(id);
    }, [id]);
    return (
        <>
            <Helmet>
                <title> {`Update`} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",

                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container maxWidth="xl" ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                        Attribute
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/e-commerce/attribute`}
                    >
                        Attribute
                    </Link>
                    <Typography color="text.primary">Update Attribute</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Update Attribute`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item md={12}>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="news-title"
                                        label="Name*"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("status")}
                                                        checked={attributeDetail.status}
                                                        name="status"
                                                        onChange={handleChange}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Status"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'UPDATE'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default EditAttribute;