export const user = JSON.parse(localStorage.getItem("user"));
// export const image = `https://indeedtraining.in/popsmoke-images/`;

// export const port = `http://indeedtraining.in:7979/admin`;
// export const image = `https://indeedtraining.in/popsmoke-api-new-setup/public/images`;
// export const video = `https://indeedtraining.in/popsmoke-api/public/videos`;

export const port = `${process.env.REACT_APP_HOST_URL}/admin`;
export const image = process.env.REACT_APP_IMAGE_URL;
export const video = process.env.REACT_APP_VIDOE_URL;

// export const port = `http://192.168.3.136:3883/admin`;
// export const image = `http://192.168.3.136:3883/images`;
// export const video = `http://192.168.3.136:3883/videos`;
