export const APP_NAME = "Popsmokemedia Admin";

export const DEFAULT_CSS={
    BG:'#e2f6fc',
    LOADER_BG_COLOR_OG:'#e2f6fc',
    LOADER_BG_COLOR:'#fff',
    DARK_BG:'#354054',
    PRIMARY_COLOR:'#1f74ca',
    SUCCESS_MSG_COLOR:'#4caf50',
    ERROR_MSG_COLOR:'#bf2b3a',
    WARNING_COLOR:'#ffab00',
    SUCCESS_COLOR:'#36b37e',
    DEFAULT:'#63738147',
}