import { useState, useRef } from 'react';
import { Grid, Box, Card, Stack, InputAdornment, IconButton, Typography, Avatar, CircularProgress, TextField, FormHelperText, Button, FormLabel } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { format } from "date-fns";

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';


import AntSwitch from "src/components/switch/antSwitch";

// react hook forms
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../../redux/Slices/RequestSlice";
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';
import { createNotification } from "src/common/createNotification";


//------- validation schema ------
const validationSchema = yup.object().shape({
    tagline: yup.string().required("This field is required."),
    // linkedin: yup.string().required("This field is required."),
});

function HeaderContent(props) {
    const dispatch = useDispatch();
    const logoImgRef = useRef();
    let date = format(new Date(), "eeee, MMMM d, yyyy");
    // const [loader, setLoader] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);

    const { item } = props;
    const [headerContent, setHeaderContent] = useState(item);
    const [logoValidation, setLogoValidation] = useState({});
    const [logo, setLogo ] = useState(`${ASSETS_URL.IMAGE}/${headerContent.logo.image_high}`);

    console.log({ headerContent });
    
    const { register, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            tagline: headerContent.text.main
            // linkedin:item?.linkedin?.url || '',
        }
    });

    const submitHeaderContent = async (data) => {
        dispatch(setRequestInProgress());
        const payloadData = {
            key: GENERAL_SETTINGS_KEYS.HEADER_CONTENT,
            value: JSON.stringify(headerContent),
            status: GENERAL_SETTINGS_KEYS.DEFAULT_STATUS,
        }

        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.SET_GENERAL_SETTINGS, payloadData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Header content updated successfully.");
                setLogoValidation({invalid:false,message:''})
            } else {
                dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
                createNotification("error", "", "Unable to process your request. Please try again.");
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while updating the your request. Please try  again."));
            createNotification("error", "", "Unable to process your request. Please try again.");

        }

        return false;
    }

    const handleTimeStatus = ( _status ) => {
        const existingState = { ...headerContent };
        
        const updatedState = {
            ...existingState,
            time: {
                ...existingState.time,
                status: _status ? 0 : 1,
            },
        }
        setHeaderContent(updatedState);
    }

    const handleChangeInput = (event) => {
        const {value} = event.target
        const existingState = { ...headerContent };

        const updatedState = { 
            ...existingState,
            text: {
                ...existingState.text,
                main: value
            },
        }
        setHeaderContent( updatedState );
    }

    const uploadImage = async(e) => {
        let formData = new FormData();
        const _FILE = e.target.files[0];

        if( !["image/jpeg", "image/png", "image/jpg"].includes(_FILE.type) ){
            console.warn("inside");
            setLogoValidation({invalid:true, message:'Only *.jpeg, *.jpg, *.png type of images are allowed'})
            return false;
        } else if( _FILE.size >= 5242880 ){
            setLogoValidation({invalid:true,message:'Image size can not be more than 5 MB'})
            return false;
        } else {
            setLogoValidation({invalid:false,message:''})
        }
        // ["image/jpeg", "image/png", "image/jpg"].includes(file[0].type)
        setLogo(URL.createObjectURL(_FILE));
        // return false
        formData.append('file', _FILE);
        try {
            // console.log(formData)
            dispatch(setRequestInProgress());
            const {status, data} = await postRequest(GENERAL_SETTINGS.UPLOAD_IMAGE, formData);
            if (status === 200 && data) {
                dispatch(setRequestInit());
                const {data:{filename}} = data;
                // console.log(filename);

                const existingState = { ...headerContent };
                const updatedState = { 
                    ...existingState,
                    logo: {
                        ...existingState.logo,
                        image_high: filename
                    },
                }
                setHeaderContent( updatedState );

                //setLogo(`${ASSETS_URL.IMAGE}/${filename}`);
            } else {
                dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
            }
        } catch (error) {
            console.log(error);
            dispatch(setRequestFailure("Getting error while uploaing file. Please try  again."));
        }
    }
    return <>
        <Card className='profile-left-section' sx={{ px: 2, py: 3 }}>
            <form onSubmit={handleSubmit(submitHeaderContent)} noValidate>
                <Grid
                    className="user-input-section"
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item md={7}>
                        <Grid item py={2}>
                                <input
                                    ref={logoImgRef}
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="raised-button-file"
                                    onChange={uploadImage}
                                    name="profile_image"
                                    label="Profile Image"
                                    type="file"
                                />
                                <div
                                    className={ logoValidation.invalid  ? "errorCkEditor": "borderInput"}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        padding: "20px",
                                        postition: "relative",
                                    }}>
                                    <img
                                        src={logo}
                                        alt={'logo'}
                                        loading="lazy"
                                        style={{
                                            width:'250px',
                                            height:'auto',
                                            objectFit:'contain'
                                        }}
                                    />
                                    <span className="mt-3" style={{color: logoValidation.invalid ? "#ff5c56" : "#8d9299",
                                                fontSize: "14px",
                                                marginBottom: "20px",
                                                textAlign:'center'
                                            }}>
                                        Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).
                                    </span>
                                    <LoadingButton 
                                        disabled={loader}
                                        loading={loader ? true : false}
                                        variant="contained"
                                        loadingPosition="end"
                                        component="label"
                                        onClick={() => loader ? false : logoImgRef.current.click()}>
                                            {loader ? 'Uploading...' :'Upload logo'}
                                    </LoadingButton>
                                </div>
                                {logoValidation.invalid && <p className="errorForm">{logoValidation.message}</p>}
                        </Grid>
                        <Grid item mt={4}>
                            <TextField fullWidth name="tagline" disabled={loader}
                                {...register('tagline')}
                                label="Tagline*"
                                variant="outlined"
                                error={!!errors.tagline}
                                helperText={errors.tagline ? errors.tagline.message : ''}
                                placeholder="Please enter tagline"
                                onChange={(event) => handleChangeInput(event)}
                            />
                        </Grid>
                        
                        {/* <Grid item mt={2}>
                            <Grid container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center">
                                <Typography variant='body1'>{date}</Typography>
                                <FormHelperText sx={{ ml:'30px'}}>
                                {headerContent.time.status === 1 ?'(Displyed in website)':'(Hidden in website)'}
                                </FormHelperText>
                                <AntSwitch
                                    onChange={(event) => handleTimeStatus(headerContent.time.status)}
                                    checked={headerContent.time.status === 1?true:false}
                                    sx={{ ml:'10px', variant: 'error' }}
                                />
                            </Grid>
                        </Grid> */}

                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            my={2}
                        >
                            <LoadingButton
                                loading={loader ? true : false}
                                size="medium"
                                type="submit"
                                loadingPosition="end"
                                endIcon={<SendIcon />}
                                variant="contained"
                            >
                                {loader ? 'Please Wait...' : 'save changes'}
                            </LoadingButton>
                        </Stack>
                    </Grid>

                </Grid>

            </form>
        </Card>
    </>
}

export default HeaderContent;