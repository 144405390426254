import { Helmet } from "react-helmet-async";
import { checkTokenExpiry } from "../utils/helpers/functions";
// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// sections
import { APP_NAME } from "src/app/constants";
import { useState } from "react";
import { Stack } from "@mui/system";
//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setPassword } from "src/api/api";
import Iconify from "src/components/iconify/Iconify";
import { createNotification } from "src/common/createNotification";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function SetPassword() {
  const [error, seterror] = useState("");
  const schema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 characters minimum")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    confirmpassword: Yup.string()
      .required("Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(schema) };
  const { handleSubmit, register, formState } = useForm(formOptions);
  const { errors } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const { otp } = useParams();
  const [modalShow, setModalShow] = useState(false);
  // const email = JSON.parse(localStorage.getItem("email"));

  const onSubmitForm = async (data) => {
    seterror("");
    const details = {
      password: data.password,
      token: otp,
    };
    setloading(true);
    try {
      await setPassword(details);
      setloading(false);
      setModalShow(true);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      setloading(false);
      createNotification("error", "Invalid token", "This link is not valid");
    }
  };
  const navigate = useNavigate();
  const handleRouteTo = (path) => {
    navigate(path);
  };
  const mdUp = useResponsive("up", "md");
  const [loading, setloading] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Helmet>
        <title> Reset Password | {APP_NAME} </title>
      </Helmet>
      <StyledRoot>
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            {mdUp && (
              <Grid item xs={5}>
                <Slider {...settings}>
                  <div>
                    {" "}
                    <img
                      src="./assets/images/slide-1.jpg"
                      alt="slide"
                      className="w-100"
                    />
                  </div>
                  <div>
                    {" "}
                    <img src="./assets/images/slide-2.jpg" alt="slide" />
                  </div>
                </Slider>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <StyledContent>
                <img
                  className="mauto"
                  src="./assets/images/web-logo.png"
                  alt="login"
                />
                <div
                  className="d-flex "
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4" mt={3} mb={2}>
                    Create Password
                  </Typography>
                  {error && (
                    <Typography
                      variant="div"
                      mt={3}
                      mb={2}
                      style={{ color: "red" }}
                    >
                      {error}!!
                    </Typography>
                  )}
                </div>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <Stack spacing={3} mb={2}>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      name="password"
                      label="Password *"
                      error={!!errors.password}
                      helperText={
                        errors.password ? errors.password.message : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      type={showPassword2 ? "text" : "password"}
                      {...register("confirmpassword")}
                      name="confirmpassword"
                      label="Confirm Password *"
                      error={!!errors.confirmpassword}
                      helperText={
                        errors.confirmpassword
                          ? errors.confirmpassword.message
                          : ""
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword2(!showPassword2)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword2
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <LoadingButton
                    className="loginBtn"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ background: "#000" }}
                  >
                    {loading === true ? <CircularProgress /> : "RESET PASSWORD"}
                  </LoadingButton>
                </form>

                <Typography
                  variant="body1"
                  onClick={() => handleRouteTo("/login")}
                  my={2}
                  style={{ cursor: "pointer" }}
                  align="center"
                >
                  Back to login
                </Typography>
              </StyledContent>
            </Grid>
          </Grid>
        </Container>
        <div
          className={
            modalShow === true
              ? "reset-link-popup active_modal"
              : "reset-link-popup"
          }
        >
          <div className="container h-100 d-flex justify-content-center align-items-center">
            <div className="popup-wrapper">
              <Typography component="h1" variant="h5" align="center">
                <Iconify
                  icon="mdi:email"
                  style={{ height: "100", width: "100" }}
                />
              </Typography>
              <p className="text-dark">
                You have successfully updated your password{" "}
              </p>
              <p className="small-text mb-4">
                Lorem Ipsum is simply dummy text of the printing and m Ipsum has
                been the industry's standard dummy text ever.
              </p>
              <div className="text-center">
                <Link
                  to="/login"
                  className="btn btn-primary-fill"
                  onClick={() => setModalShow(true)}
                >
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </StyledRoot>
    </>
  );
}
