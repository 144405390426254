import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TableContainer, TableBody, Table, Stack, TableRow, TableCell, Paper, CircularProgress, Typography, IconButton, Button } from "@mui/material";
import { NewsListHead } from "../../../sections/@dashboard/news";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import Scrollbar from "../../../components/scrollbar";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 54,
  p: 2,
};

export default function OrderListModal({ open, setOpen, childRows ,invoiceNo}) {

  const handleClose = () => {
    setOpen(false);
  };

  console.log("childRows", childRows)
  return (
    <div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >


        <Box sx={style}>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h6" gutterBottom>
             Invoice No.{invoiceNo}
            </Typography>
            <Button onClick={handleClose}> <CloseIcon/>  </Button>
          </Stack>

          <Scrollbar>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 440 }}
            >
              <Table stickyHeader aria-label="sticky table">
                <NewsListHead

                  headLabel={TABLE_HEAD}
                // rowCount={10}
                // numSelected={1}
                />
                <TableBody style={{ overflow: "scroll", maxHeight: "700px" }}>
                  {
                    childRows.map((item, index) => {

                      let orderAddressObject = JSON.parse(item.order_address);

                      return (
                        <TableRow key={index} hover tabIndex={-1} role="checkbox">

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.order_id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.quantity} items
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="left" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.user.name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.total_price}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.ship_amt}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.order_total}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.product_amt}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {orderAddressObject.shipping_first_name} {orderAddressObject.shipping_last_name}, <br /> {orderAddressObject.shipping_address_line_1} {orderAddressObject.shipping_address_line_2} ,<br /> {orderAddressObject.shipping_city}, {orderAddressObject.shipping_country} ,{orderAddressObject.shipping_zip},<br />
                                {orderAddressObject.shipping_phone}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.payment_status === 0 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>failed</span> : <span style={{ backgroundColor: '#00a65a ', padding: "4px", color: "#fff", width: 'max-content', fontSize: '8px' }}>success</span>}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap" style={{ color: 'white', fontSize: '8px', width: 'max-content' }}  >
                                {item.status === 0 ? <span style={{ backgroundColor: '#ff851b', padding: "4px" }}>Pending</span> :
                                  item.status === -1 ? <span style={{ backgroundColor: '#dd4b39', padding: "4px" }} > Cancelled</span> :
                                    item.status === 1 ? <span style={{ backgroundColor: '#d9edf7', padding: "4px" }}> Processing</span> :
                                      item.status === 2 ? <span style={{ backgroundColor: '#00a65a ', padding: "4px" }}> Dispatched</span> :
                                        <span className="green">Completed</span>}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                                {item.createdAt}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {/* <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography className="category word-wrap">
                               <DeleteForeverIcon/>
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                            <button>cancel</button>
                            </Stack>
                          </TableCell> */}


                        </TableRow>
                      )
                    })
                  }

                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Box>
      </Modal>
    </div>
  );
}


const TABLE_HEAD = [
  { id: "Order ID", className: '', label: "Order ID", align: '' },
  { id: "Purchased", className: '', label: "Purchased", align: '' },
  { id: "Customer", className: '', label: "Customer", align: '' },
  { id: "Order Amount", className: '', label: "Order Amount", align: '' },
  { id: "Ship Amount", className: '', label: "Ship Amount", align: '' },
  { id: "Order Total Amount", className: '', label: "Order Total Amount", align: '' },
  { id: "Product Amount", className: '', label: "Product Amount", align: '' },
  // { id: "Payment Method", className: '', label: "Payment Method", align: '' },
  { id: "Ship To", className: '', label: "Ship To", align: '' },
  { id: "Payment Status", className: '', label: "Payment Status", align: '' },
  { id: "Order Status", className: '', label: "Order Status", align: '' },
  { id: "Order Date", className: '', label: "Order Date", align: '' },
  { id: "delete", className: '', label: "", align: '' },
  { id: "cancel", className: '', label: "", align: '' },
];