import { Helmet } from "react-helmet-async";
import { checkTokenExpiry } from "../utils/helpers/functions";

// @mui
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// sections
import { APP_NAME } from "src/app/constants";
import { useState } from "react";
import { Stack } from "@mui/system";
//form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { forgetPassword } from "src/api/api";
import Iconify from "src/components/iconify/Iconify";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function ForgetPassword() {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .email()
      .required("Email is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, register, formState } = useForm(formOptions);
  const { errors } = formState;

  const [modalShow, setModalShow] = useState(false);

  const onSubmitForm = async (data) => {
    setloading(true);
    try {
      await forgetPassword(data);
      setModalShow(true);
      setloading(false);
      localStorage.setItem("email", JSON.stringify(data.email));
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      setModalShow(false);
      setloading(false);
    }
  };
  const navigate = useNavigate();
  const handleRouteTo = (path) => {
    navigate(path);
  };
  const mdUp = useResponsive("up", "md");
  const [loading, setloading] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    fade: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Helmet>
        <title> Forget Password | {APP_NAME} </title>
      </Helmet>

      <StyledRoot>
        <Container fixed>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            {mdUp && (
              <Grid item xs={5}>
                <Slider {...settings}>
                  <div>
                    {" "}
                    <img
                      src="./assets/images/slide-1.jpg"
                      alt="slide"
                      className="w-100"
                    />
                  </div>
                  <div>
                    {" "}
                    <img src="./assets/images/slide-2.jpg" alt="slide" />
                  </div>
                </Slider>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <StyledContent>
                <img
                  className="mauto"
                  src="./assets/images/web-logo.png"
                  alt="login"
                />
                <Typography variant="h4" mt={3} mb={2}>
                  Forget Password ?
                </Typography>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <Stack spacing={3} mb={2}>
                    <TextField
                      name="email"
                      label="Email address"
                      {...register("email")}
                      error={errors.email}
                      helperText={errors.email?.message}
                    />
                  </Stack>
                  <LoadingButton
                    className="loginBtn"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ background: "#000" }}
                  >
                    {loading === true ? (
                      <CircularProgress />
                    ) : (
                      " SEND EMAIL LINK"
                    )}
                  </LoadingButton>
                </form>

                <Typography
                  variant="body1"
                  onClick={() => handleRouteTo("/login")}
                  my={2}
                  style={{ cursor: "pointer" }}
                  align="center"
                >
                  Back to login
                </Typography>
              </StyledContent>
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
      <div
        className={
          modalShow === true ? "reset-link-popup active" : "reset-link-popup"
        }
      >
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <div className="popup-wrapper">
            <Iconify icon="mdi:email" style={{ height: "100", width: "100" }} />

            <p className="text-dark">
              The password reset link has been sent to your registered email
              address.
            </p>
            <p className="small-text mb-4"></p>
            <div className="text-center">
              <Link to="#" className="btn" onClick={() => setModalShow(false)}>
                Close
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
