import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import { checkTokenExpiry } from "../../utils/helpers/functions";
import NewsListing from "./newslists";
// @mui
import {
  Stack,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

import Iconify from "../../components/iconify";

import { setNewsBulletin } from "src/redux/Slices/NewsSlice";

import { useDispatch, useSelector } from "react-redux";

import { getNewsBulletin } from "src/api/newsBulletinApi";

function ArchievedNews() {
  const [loading, setloading] = useState(false);

  //CONSTANTS------------------------------------------------------------
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "lg");
  const pathName = window.location.pathname.split("/")[2];
  const { NAV_WIDTH } = useSelector((state) => state.news);
  const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

  //get data from api
  const getBulletin = async () => {
    try {
      const data = await getNewsBulletin();
      dispatch(setNewsBulletin(data.data));
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
      setloading(false);
    }
  };

  const handleRouteTo = (path) => {
    navigate(path);
  };


  useEffect(() => {
    // getBulletin();
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="assets/icons/sas.png"
        />
        <title>Archived News</title>
      </Helmet>

      <Container>
        {loading && (
          <Box
            onClick={() => setloading(false)}
            sx={{
              display: "flex",
              position: "fixed",
              height: "100%",
              width: isDesktop ? `calc(100% - ${NAV_WIDTH + 1}px)` : "100%",
              bottom: "0",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",

              zIndex: "1000",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>Archived News</Typography>
        </Stack>

        <NewsListing
          setloading={setloading}
        />
      </Container>
    </>
  );
}

export default ArchievedNews;
