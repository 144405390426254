import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  FormControl,
} from "@mui/material";
import { getRequest } from "src/app/httpClient/axiosClient";
import { GENERAL_SETTINGS } from "src/app/config/endpoints";
import useSearchDebounce from "src/utils/hooks/textDebounce";
import { convertToPercentage, formatTime } from "src/utils/helpers/functions";
import { createNotification } from "src/common/createNotification";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const dimensionOptions = [
  { id: 1, label: "Country", key: "country" },
  { id: 2, label: "Region", key: "region" },
  { id: 3, label: "City", key: "city" },
  { id: 4, label: "Language", key: "language" },
];

const durationOptions = [
  "Custom",
  "Today",
  "Yesterday",
  "This week (Sun - Today)",
  "Last week (Sun - Sat)",
  "Last 7 days",
  "Last 28 days",
  "Last 30 days",
  "Last 90 days",
  "Last 12 months",
  "Last calendar year",
  "This year (Jan - Today)",
];

const columnHeaders = [
  {
    id: 1,
    label: "location",
    align: "left",
    desc: false,
    key: "location",
  },
  {
    id: 3,
    label: "Active Users",
    align: "right",
    desc: false,
    key: "activeUsers",
  },
  {
    id: 2,
    label: "New Users",
    align: "right",
    desc: true,
    key: "newUsers",
  },
  {
    id: 4,
    label: "Engaged sessions",
    align: "right",
    desc: false,
    key: "engagedSessions",
  },
  {
    id: 5,
    label: "Engagement rate",
    align: "right",
    desc: false,
    key: "engagementRate",
  },
  {
    id: 6,
    label: "Engaged sessions per active user",
    align: "right",
    desc: false,
    key: "NA",
  },
  {
    id: 7,
    label: "Average engagement time per user",
    align: "right",
    desc: false,
    key: "NA",
  },

  {
    id: 8,
    label: "Event count",
    align: "right",
    desc: false,
    key: "eventCount",
  },
];

const LocationAnalytics = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //   const [eventFilter, setEventFilter] = useState("all");
  const [duration, setDuration] = useState("Last 28 days");
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [searchTextDebounce, setSearchTextDebounce] = useSearchDebounce();
  const [orderKey, setOrderKey] = useState({
    key: "screenPageViews",
    desc: true,
  });
  const [metricTotals, setMetricTotal] = useState({
    activeUsersTotal: 0,
    newUsersTotal: 0,
    engagedSessionTotal: 0,
    engagementRateTotal: 0,
    engagedSessionPerActiveUserTotal: 0,
    averageEngagementTimeTotal: 0,
    eventCountTotal: 0,
  });
  const [selectedDimension, setSelectedDimention] = useState({
    lable: "Country",
    key: "country",
  });

  const handleSelectDimension = (e) => {
    const selectedDm = dimensionOptions?.find(
      (dm) => dm?.key === e.target.value
    ) || {
      lable: "Country",
      key: "country",
    };
    setSelectedDimention(selectedDm);
    const isDimension = dimensionOptions?.findIndex(
      (dm) => dm?.key === orderKey?.key
    );
    if (isDimension > -1) {
      setOrderKey((prev) => ({ ...prev, key: selectedDm?.key }));
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setSearchTextDebounce(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //   const handleEventFilterChange = (event) => {
  //     setEventFilter(event.target.value);
  //   };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
    if (event.target.value === "Custom") {
      setDateRange({ startDate: "", endDate: "" });
    }
  };

  const handleCustomDateRange = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    setDateRange((prev) => ({ ...prev, [fieldName]: value }));
  };

  const handleOrderBy = (key) => {
    if (key === "location") {
      setOrderKey((prev) => {
        if (prev?.key === selectedDimension?.key) {
          return { ...prev, desc: !prev?.desc };
        } else {
          return { key: selectedDimension?.key, desc: true };
        }
      });
    } else {
      setOrderKey((prev) => {
        if (prev?.key === key) {
          return { ...prev, desc: !prev?.desc };
        } else {
          return { key: key, desc: true };
        }
      });
    }
  };

  const getPageData = async () => {
    if (duration === "Custom") {
      if (!dateRange?.startDate || !dateRange?.endDate) {
        createNotification("error", "", "Please select valid date range");
        return;
      }

      const start_date = new Date(dateRange?.startDate);
      const end_date = new Date(dateRange?.endDate);
      if (end_date < start_date) {
        createNotification("error", "", "Please select valid date range");
        return;
      }
    }

    const offset = page * rowsPerPage;

    const url = `${
      GENERAL_SETTINGS.GET_LOCATION_ANALYTICS
    }?duration=${duration}&offset=${offset}&limit=${rowsPerPage}${
      search?.length > 0 ? `&search=${search}` : ""
    }${
      duration === "Custom"
        ? `&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`
        : ""
    }&orderBys=${orderKey?.key}&desc=${orderKey?.desc}&dimensions=${
      selectedDimension?.key
    }`;

    try {
      setLoading(true);
      const response = await getRequest(url);
      // && response.data.total_count !== undefined
      if (response.data.data) {
        setPageData(response.data.data);
        setTotalCount(response.data.total_count);
        setDateRange({
          startDate: response?.data?.startDate,
          endDate: response?.data?.endDate,
        });
        setMetricTotal({
          activeUsersTotal: response?.data?.activeUsersTotal || 0,
          newUsersTotal: response?.data?.newUserCount || 0,
          engagedSessionTotal: response?.data?.engagedSessionsTotal || 0,
          engagementRateTotal: response?.data?.engagementRateTotal || 0,
          engagedSessionPerActiveUserTotal:
            response?.data?.averageEngagementSession || 0,
          averageEngagementTimeTotal:
            response?.data?.averageEngagementTimeTotal || 0,
          eventCountTotal: response?.data?.eventCountTotal || 0,
        });
      }
    } catch (error) {
      createNotification("error", "", "Getting error while fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (duration !== "Custom") {
    //   getPageData();
    // }
    if (duration === "Custom") {
      if (dateRange?.startDate && dateRange?.endDate) {
        getPageData();
      }
    } else {
      getPageData();
    }
  }, [
    duration,
    page,
    rowsPerPage,
    searchTextDebounce,
    orderKey,
    selectedDimension,
  ]);

  return (
    <Box
      sx={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Demographic Analytics
      </Typography>
      {/* Search and Duration Filter */}
      <Box display="flex" gap={2} alignItems="center" mb={2}>
        <TextField
          label="Search by country name"
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
        <Select
          value={duration}
          onChange={handleDurationChange}
          displayEmpty
          size="small"
          sx={{ minWidth: 180 }}
        >
          {durationOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <>
          <TextField
            label="Start Date"
            type="date"
            value={dateRange?.startDate}
            onChange={handleCustomDateRange}
            name="startDate"
            disabled={duration !== "Custom"}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ minWidth: 150 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={dateRange?.endDate}
            disabled={duration !== "Custom"}
            name="endDate"
            onChange={handleCustomDateRange}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ minWidth: 150 }}
          />
          {duration === "Custom" && (
            <Button variant="outlined" disabled={loading} onClick={getPageData}>
              {loading ? "PLEASE WAIT..." : "Apply"}
            </Button>
          )}
        </>
      </Box>

      {loading ? (
        <Box
          sx={{
            // height: "400px",
            display: "flex", // Enable Flexbox layout
            justifyContent: "center", // Center horizontally
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : pageData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="analytics table">
            <TableHead>
              <TableRow>
                {columnHeaders.map((column, index) => {
                  if (column?.label === "location") {
                    return (
                      <TableCell
                        key={index}
                        align={column.align}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{
                            cursor: "pointer",
                            color:
                              selectedDimension?.key === orderKey?.key &&
                              orderKey?.desc
                                ? "black" // Dark gray if condition matches
                                : "darkgray", // Light gray otherwise
                          }}
                          onClick={() => handleOrderBy(column?.key)}
                        />
                        <FormControl variant="standard">
                          <Select
                            value={selectedDimension?.key}
                            onChange={handleSelectDimension}
                          >
                            {dimensionOptions?.map((dm) => (
                              <MenuItem value={dm?.key} key={dm?.key}>
                                {dm?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    );
                  }

                  return (
                    <TableCell key={index} align={column.align}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {column?.key !== "NA" && (
                          <span>
                            <ArrowDownwardIcon
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                color:
                                  column?.key === orderKey?.key &&
                                  orderKey?.desc
                                    ? "black" // Dark gray if condition matches
                                    : "darkgray", // Light gray otherwise
                              }}
                              onClick={() => handleOrderBy(column?.key)}
                            />
                          </span>
                        )}
                        <span>{column.label}</span>
                      </div>
                    </TableCell>
                  );
                  // if (column?.label === "Event count") {
                  //   return (
                  //     <TableCell align="right" key={index}>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           alignItems: "center",
                  //           gap: "5px",
                  //         }}
                  //       >
                  //         <span>
                  //           <ArrowDownwardIcon
                  //             fontSize="small"
                  //             sx={{
                  //               cursor: "pointer",
                  //               color:
                  //                 column?.key === orderKey?.key &&
                  //                 orderKey?.desc
                  //                   ? "black" // Dark gray if condition matches
                  //                   : "darkgray", // Light gray otherwise
                  //             }}
                  //             onClick={() => handleOrderBy(column?.key)}
                  //           />
                  //         </span>
                  //         <span>{column.label}</span>
                  //       </div>
                  //     </TableCell>
                  //   );
                  // } else {
                  //   return (
                  //     <TableCell key={index} align={column.align}>
                  //       <div
                  //         style={{
                  //           display: "flex",
                  //           alignItems: "center",
                  //           gap: "5px",
                  //         }}
                  //       >
                  //         {column?.key !== "NA" && (
                  //           <span>
                  //             <ArrowDownwardIcon
                  //               fontSize="small"
                  //               sx={{
                  //                 cursor: "pointer",
                  //                 color:
                  //                   column?.key === orderKey?.key &&
                  //                   orderKey?.desc
                  //                     ? "black" // Dark gray if condition matches
                  //                     : "darkgray", // Light gray otherwise
                  //               }}
                  //               onClick={() => handleOrderBy(column?.key)}
                  //             />
                  //           </span>
                  //         )}
                  //         <span>{column.label}</span>
                  //       </div>
                  //     </TableCell>
                  //   );
                  // }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  Total
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {metricTotals?.activeUsersTotal?.toLocaleString("en-US")}
                  </div>
                  <div style={{ fontSize: "12px" }}>100% of total</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {metricTotals?.newUsersTotal?.toLocaleString("en-US")}
                  </div>
                  <div style={{ fontSize: "12px" }}>100% of total</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {metricTotals?.engagedSessionTotal?.toLocaleString("en-US")}
                  </div>
                  <div style={{ fontSize: "12px" }}>100% of total</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {convertToPercentage(metricTotals?.engagementRateTotal)}
                  </div>
                  <div style={{ fontSize: "12px" }}>Avg 0%</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {metricTotals?.engagedSessionPerActiveUserTotal}
                  </div>
                  <div style={{ fontSize: "12px" }}>Avg 0%</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {formatTime(metricTotals?.averageEngagementTimeTotal)}
                  </div>
                  <div style={{ fontSize: "12px" }}>Avg 0%</div>
                </TableCell>
                <TableCell align="right">
                  <div style={{ fontWeight: "bold" }}>
                    {metricTotals?.eventCountTotal?.toLocaleString("en-US")}
                  </div>
                  <div style={{ fontSize: "12px" }}>100% of total</div>
                </TableCell>
              </TableRow>
              {pageData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row?.dimension}
                  </TableCell>
                  <TableCell align="right">{row?.activeUsers}</TableCell>
                  <TableCell align="right">{row?.newUser}</TableCell>
                  <TableCell align="right">{row?.engagedSessions}</TableCell>
                  <TableCell align="right">
                    {convertToPercentage(row?.engagementRate)}
                  </TableCell>
                  <TableCell align="right">
                    {row?.engagedSessionsPerActiveUser
                      ? row?.engagedSessionsPerActiveUser?.toFixed(2)
                      : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    {formatTime(row?.engagementTime) || 0}
                  </TableCell>
                  <TableCell align="right">{row?.eventCount || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 250]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <Typography variant="subtitle1" textAlign="center">
          No data found
        </Typography>
      )}
    </Box>
  );
};

export default LocationAnalytics;
