import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Container, Typography, Box, Tab } from "@mui/material";
import { AppWidgetSummary } from "../sections/@dashboard/app";
import { getDashboard } from "src/api/api";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkTokenExpiry } from "../utils/helpers/functions";
import GoogleAnalytics from "./GoogleAnalytics";
import PageAnalytics from "./PageAnalytics";
import LocationAnalytics from "./LocationAnalytics";
import { TabContext, TabList } from "@mui/lab";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [data, setdata] = useState({});
  const [countLoading, setcountLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("dashboard");

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  const getList = async () => {
    setcountLoading(true);
    try {
      const data = await getDashboard();
      setdata(data.data);
      setcountLoading(false);
    } catch (error) {
      checkTokenExpiry(error.response.data.msg);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Popsmoke media </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 1 }}>
          Hi, Welcome back
        </Typography>

        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 5 }}>
            <TabList
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { backgroundColor: "#fff", height: "3px" }, // White underline for the selected tab
              }}
            >
              <Tab
                label="Dashboard"
                value="dashboard"
                sx={{
                  backgroundColor: selectedTab === "dashboard" ? "#c92129" : "",
                  color: selectedTab === "dashboard" ? "#FFFFFF" : "black",
                }}
                className="dashboard-tab"
              />
              <Tab
                label="Google Analytics Dashboard"
                value="analytics-dashboard"
                sx={{
                  backgroundColor:
                    selectedTab === "analytics-dashboard" ? "#c92129" : "",
                  color:
                    selectedTab === "analytics-dashboard" ? "#FFFFFF" : "black",
                }}
                className="dashboard-tab"
              />
              <Tab
                label="Page Analytics"
                value="page-analytics"
                sx={{
                  backgroundColor:
                    selectedTab === "page-analytics" ? "#c92129" : "",
                  color: selectedTab === "page-analytics" ? "#FFFFFF" : "black",
                }}
                className="dashboard-tab"
              />
              <Tab
                label="Demographic Analytics"
                value="demographic-analytics"
                sx={{
                  backgroundColor:
                    selectedTab === "demographic-analytics" ? "#c92129" : "",
                  color:
                    selectedTab === "demographic-analytics"
                      ? "#FFFFFF"
                      : "black",
                }}
                className="dashboard-tab"
              />
            </TabList>
          </Box>
        </TabContext>

        {selectedTab === "dashboard" && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/dashboard/news" style={{ textDecoration: "none" }}>
                <AppWidgetSummary
                  title="Total Live News"
                  loading={countLoading}
                  total={data?.publishNewsCount}
                  color="info"
                  icon={"bxs:news"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link
                to="/dashboard/archieved-news"
                style={{ textDecoration: "none" }}
              >
                <AppWidgetSummary
                  title="Total Archived News"
                  loading={countLoading}
                  total={data?.archivedCount}
                  color="warning"
                  icon={"ic:round-archive"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link
                to="/e-commerce/product/listing"
                style={{ textDecoration: "none" }}
              >
                <AppWidgetSummary
                  title="Total Products"
                  loading={countLoading}
                  total={data?.productCount}
                  color="secondary"
                  icon={"ic:round-shopping-cart"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link
                to="/e-commerce/product/listing"
                style={{ textDecoration: "none" }}
              >
                <AppWidgetSummary
                  title="Total Orders"
                  loading={countLoading}
                  total={data?.orderCount}
                  color="error"
                  icon={"ic:round-store"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link
                to="/dashboard/subscribers"
                style={{ textDecoration: "none" }}
              >
                <AppWidgetSummary
                  title="Total Subscribers"
                  loading={countLoading}
                  total={data?.subscribeCount}
                  color="info"
                  icon={"ic:round-person"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link to="/dashboard/contacts" style={{ textDecoration: "none" }}>
                <AppWidgetSummary
                  title="Total Contact Queries"
                  loading={countLoading}
                  total={data?.contactCount}
                  color="warning"
                  icon={"ic:round-star"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Link to="/dashboard/author" style={{ textDecoration: "none" }}>
                <AppWidgetSummary
                  title="Total Authors"
                  loading={countLoading}
                  total={data?.authorCount}
                  color="secondary"
                  icon={"ic:round-assessment"}
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Media Uploads"
                loading={countLoading}
                total={data?.mediaCount}
                color="error"
                icon={"material-symbols:perm-media-rounded"}
              />
            </Grid>
          </Grid>
        )}
        {selectedTab === "page-analytics" && (
          <Grid item xs={12}>
            <PageAnalytics />
          </Grid>
        )}
        {selectedTab === "demographic-analytics" && (
          <Grid item xs={12}>
            <LocationAnalytics />
          </Grid>
        )}
        {selectedTab === "analytics-dashboard" && (
          <Grid item xs={12}>
            <GoogleAnalytics />
          </Grid>
        )}
      </Container>
    </>
  );
}

{
  /* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Advertisment"
              total={21}
              color="warning"
              icon={"ri:advertisement-fill"}
            />
          </Grid>  */
}
