import { useState, useEffect, lazy } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import ShareIcon from '@mui/icons-material/Share';
import CollectionsIcon from '@mui/icons-material/Collections';
import TabContext from '@mui/lab/TabContext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArticleIcon from '@mui/icons-material/Article';

import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


// import GeneralDetails from './generalDetails';
// const Documents = lazy(() =>  import('./documents'));
import HeaderContent from './headerContent';
import FooterContent from './footerContent';
import SocialsLinks from './socialsLinks';
// const HeaderContent = lazy(() =>  import('./headerContent'));
// const SocialsLinks = lazy(() =>  import('./socialsLinks'));
// const Notifications = lazy(() => import('./notifications'));
// const Gallery = lazy(() => import('./gallery'));


// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }


function TabSections(props) {
    const {socialLinks, headerContent, footerContent} = props;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value, setValue] = useState('headerTab');

    return <>
        <Box sx={{ width: '100%', my: 3 }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
                    <TabList className='tablist' onChange={handleChange} sx={{minHeight:'0px'}} aria-label="user details">
                        <Tab className='min-hight-0 tablist-option' icon={<RecentActorsIcon />} iconPosition="start" label="header" value="headerTab" />
                        <Tab className='min-hight-0 tablist-option' icon={<RecentActorsIcon />} iconPosition="start" label="Footer" value="footerTab" />
                        <Tab className='min-hight-0 tablist-option' icon={<ShareIcon />} iconPosition="start" label="social links" value="socialLinksTab" />
                    </TabList>
                </Box>
                    <TabPanel sx={{ px: 0, py:4}} value="headerTab">
                        <HeaderContent item={headerContent} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py:4}} value="footerTab">
                        <FooterContent item={footerContent} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py:4 }} value="socialLinksTab">
                        <SocialsLinks item={socialLinks} />
                    </TabPanel>
            </TabContext>
        </Box>
    </>
}

export default TabSections;