import axios from "axios";
import { port } from "../constants/api";
import { user } from "../constants/api";

export const getNewsList = async (page) => {
  return await axios.get(`${port}/newsList?page=${page}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const getNews = async (page=1, query='', no_of_rows=10) => {
  return await axios.get(`${port}/newsListAndSearch?page=${page}&no_of_rows=${no_of_rows}&query=${query}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const getNewsById = async (id) => {
  return await axios.get(`${port}/newsListById/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};



export const getUsers = async () => {
  return await axios.get(`${port}/userlist`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const createNews = async (formData) => {
  return await axios.post(`${port}/createNews`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const searchNews = async (search) => {
  return await axios.get(`${port}/search?query=${search}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};


export const deleteNews = async (id) => {
  return await axios.delete(`${port}/deleteNews`, {
    data: { id: id },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const updateNews = async (data) => {
  return await axios.post(`${port}/updateNews`, data, {
    headers: {
      "Content-Type": "Multipart/form-data",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
export const updatePublishedStatus = async (data) => {
  return await axios.post(`${port}/publishNewsById`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};

export const updateIsTop = async (data) => {
  return await axios.post(`${port}/updateIsTopById`, data, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${user.token}`,
    },
  });
};
