import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link, useParams } from "react-router-dom";

import { checkTokenExpiry } from "../../utils/helpers/functions";

import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';

// @mui
import {Card,CardHeader,Stack,Button,Grid,Container,Typography,CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { createNotification } from "../../common/createNotification";
import { Icon } from "@iconify/react";

import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestSlice";

import { getRequest, postRequest } from 'src/app/httpClient/axiosClient';
import { GENERAL_SETTINGS, GENERAL_SETTINGS_KEYS, ASSETS_URL } from 'src/app/config/endpoints';


function EditSponser() {
    
    const { id } = useParams();

    const [loading, setloading] = useState(false);
    const { loader, errorMessage, successMessage } = useSelector(state => state.serviceRequest);
    const [sponserDetail, setSponserDetail] = useState({});
    const [sponserImgPreview, setSponserImgPreview] = useState();

    //CONSTANTS
    const formTop = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathName = window.location.pathname.split("/")[2];
    const path = pathName.charAt(0).toUpperCase() + pathName.slice(1);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        url: Yup.string()
            .required("This field required.")
            .matches(
                '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
                'Please enter a valid url'
            ),
        sponsorImage: Yup.mixed()
            .test("required", "This field is required.", (file) => {
                if (file) return true;
                return false;
            })
            .test("fileSize", "Image size can not be more than 5 MB", (file) => {

                if( file.length === 0 )
                    return true;

                return file.length && file[0].size <= 5242880;
            })
            .test("fileType", "Only *.jpeg, *.jpg, *.png type of images are allowed.", (file) => {
                if( file.length === 0 )
                    return true;
                
                return (
                    file.length &&
                    ["image/jpeg", "image/png", "image/jpg"].includes(file[0].type)
                );
            }),
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
    };

    const { register, unregister, handleSubmit, formState, trigger, setValue } = useForm(formOptions);
    const { errors } = formState;

    const [selectedImage, setSelectedImage] = useState(null);
    const { onChange, ...params } = register("sponsorImage");


    const handleRouteTo = (path) => {
        navigate(path);
    };

    const onSubmitForm = async (data) => {
        console.log(data);
        dispatch(setRequestInProgress());
        
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", data.name);
        formData.append("url", data.url);
        formData.append("status", data.published ? 1 : 0);
        if(data.sponsorImage.length > 0){
            formData.append("file", data.sponsorImage[0]);
        }

        try {
            const { status, data } = await postRequest(GENERAL_SETTINGS.UPDATE_SPONSERS, formData);

            if (status === 200 && data) {
                dispatch(setRequestInit());
                createNotification("success", "", "Sponsor updated successfully.");
                handleRouteTo("/dashboard/sponsors");

            } else {
                dispatch(setRequestFailure("Getting error while creating Sponsors."));
                createNotification("error", "", "Getting error while creating Sponsors.");
            }
        } catch (error) {
            checkTokenExpiry(error.response.data.msg);
            setloading(false);
            dispatch(setRequestFailure("Getting error while creating Sponsors."));
            createNotification("error", "", "Getting error while creating Sponsors.");
        }
    };


    const handleFile = (e) => {

        const image = e.target.files[0];
        if (!image)
            return false;

        setSponserImgPreview(URL.createObjectURL(e.target.files[0]));
        return e.target.files[0];
    };

    const handleChange = (event) => {
        setSponserDetail( {...sponserDetail, status:event.target.checked});
      };
      
    async function getSponser(id) {
        
        try {
            dispatch(setRequestInProgress());
            const response = await getRequest(GENERAL_SETTINGS.GET_SPONSERS_BY_ID.replace('{ID}', id));
            const { status, data: { data } } = response;
            if (status === 200 && data) {
                setSponserDetail(data);
                setSponserImgPreview(`${ASSETS_URL.IMAGE}/${data?.thumb_nail}`);
                setValue('name', data.name,); 
                setValue('url', data.url,); 
                setValue('published', data.status ? true:false); 
                dispatch(setRequestInit());
                unregister('sponsorImage')
                // unregister("yourDetails")
            } else if(data === null) {
                dispatch(setRequestFailure("Sponsors not found."));
                createNotification("error", "", "Sponsors not found.");
                handleRouteTo('/dashboard/sponsors')
            }
        } catch (error) {
            dispatch(setRequestFailure("Getting error while removing profile picture. Please try  again."));
        }
    }
    useEffect(() => {
        getSponser(id);

    }, [id]);

    return (
        <>
            <Helmet>
                <title> {path} </title>
            </Helmet>

            {loading && (
                <Box
                    onClick={() => setloading(false)}
                    sx={{
                        display: "flex",
                        position: "fixed",
                        height: "100%",
                        width: "75%",
                        bottom: "0",
                        backgroundColor: "white",
                        alignItems: "center",
                        justifyContent: "center",

                        zIndex: "1000",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Container ref={formTop}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={0}
                >
                    <Typography variant="h4" gutterBottom>
                    Sponsor
                    </Typography>
                </Stack>
                <Breadcrumbs aria-label="breadcrumb" mb={5}>
                    <Link style={{ textDecoration: "none", color: "black" }} to="/">
                        Dashboard
                    </Link>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={`/dashboard/${pathName}`}
                    >
                        Sponsors
                    </Link>
                    <Typography color="text.primary">Update Sponsor</Typography>
                </Breadcrumbs>

                <Card>
                    <CardHeader title={`Update Sponsor`} />
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmitForm)}
                        sx={{ pt: 5, pl: 3, pr: 3, pb: 5 }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={4}
                        >
                            <Grid item md={6}>

                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}  
                                        {...register("name", { required: true })}
                                        error={errors.name}
                                        helperText={
                                            errors.name ? errors.name.message : false
                                        }
                                        disabled={loader}
                                        id="news-title"
                                        label="Name*"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item mb={3} xs={12} md={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}  
                                        {...register("url", { required: true })}
                                        disabled={loader}
                                        error={errors.url}
                                        helperText={
                                            errors.url ? errors.url.message : false
                                        }
                                        id="news-title"
                                        label="Url*"
                                        name="url"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <div
                                        className={
                                            errors.sponsorImage 
                                                ? "errorCkEditor"
                                                : "borderInput"
                                        }
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            padding: "20px",
                                            postition: "relative",
                                        }}
                                    >
                                        {sponserImgPreview &&
                                            <img
                                                src={sponserImgPreview}
                                                style={{
                                                    height: "190px",
                                                    marginBottom: "10px",
                                                    width: "190px",
                                                }}
                                                alt="something"
                                                loading="lazy"
                                            />
                                         }
                                         <span
                                            style={{
                                                color: errors.sponsorImage ? "#ff5c56" : "#8d9299",
                                                fontSize: "14px",
                                                marginBottom: "20px",
                                                textAlign:'center'
                                            }}
                                        >
                                            Only *.jpeg, *.jpg, *.png type of images are allowed, Image size can not be more than 5 MB ).</span>

                                        <Button 
                                        disabled={loader}
                                        variant="contained" component="label">
                                            Upload image
                                            <input
                                                {...params}
                                                type="file"
                                                id="uploadFileInput"
                                                accept="image/*"
                                                name="sponsorImage"
                                                onChange={(event) => {
                                                    setSelectedImage(event.target.files[0]);
                                                    onChange(event);
                                                    trigger('sponsorImage');
                                                    handleFile(event)
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </Button>
                                    </div>
                                    {errors.sponsorImage &&
                                        <p className="errorForm">{errors.sponsorImage.message}</p>
                                    }
                                </Grid>

                                <Grid item mb={3} xs={12} md={12}>
                                    <FormGroup>
                                        <Stack spacing={3} direction="row">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        {...register("published")}
                                                        checked={sponserDetail.status}
                                                        name="published"
                                                        onChange={handleChange}
                                                        disabled={loader}
                                                        color="success"
                                                    />
                                                }
                                                label="Published"
                                            />
                                        </Stack>
                                    </FormGroup>
                                </Grid>
                                <Grid
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    my={2}
                                >
                                    <LoadingButton
                                        loading={loader ? true : false}
                                        size="medium"
                                        type="submit"
                                        loadingPosition="end"
                                        variant="contained"
                                    >
                                        {loader ? 'PLEASE WAIT...' : 'UPDATE'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Container>
        </>
    );
}

export default EditSponser;